import React, { Component } from 'react'
import './Tabs.styles.web.scss'

import Tab from './Tab.web'

type TabsPropsType = {
  children: React.Node,
  activeTabIndex: number,
}

export default class Tabs extends Component<TabsPropsType> {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: props.activeTabIndex
        ? this.props.children[props.activeTabIndex].props.text
        : this.props.children[0].props.text,
    }
  }

  onClickTab = text => {
    this.setState({ activeTab: text })
  }

  getStyleFromProps() {
    const activeChild = this.props.children.filter(child => {
      return child.props.text === this.state.activeTab
    })
    return activeChild[0]?.props.style
  }

  render() {
    return (
      <div className="tabs">
        <ol className="tab-list">
          {this.props.children.map(child => {
            return (
              <Tab
                active={child.props.text === this.state.activeTab}
                key={child.props.text}
                icon={child.props.icon}
                text={child.props.text}
                onClick={this.onClickTab}
              />
            )
          })}
        </ol>
        <div className="tab-content" style={this.getStyleFromProps()}>
          {this.props.children.map(child => {
            return child.props.text === this.state.activeTab ? child.props.children : undefined
          })}
        </div>
      </div>
    )
  }
}
