/**
 * @Flow
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import jwtDecode from 'jwt-decode'
import AccountSettings from './AccountSettings'

type AccountSettingsPropTypes = {
  navigation: {
    navigate: () => void,
  },
  credentials: {
    access_token: string,
  },
}

// eslint-disable-next-line react/prefer-stateless-function
class AccountSettingsContainer extends Component<AccountSettingsPropTypes> {
  render() {
    return (
      <AccountSettings
        navigation={this.props.navigation}
        email={jwtDecode(this.props.credentials.access_token).emails[0]}
      />
    )
  }
}

const mapStateToProps = state => ({
  credentials: state.user.credentials,
})

export default connect(
  mapStateToProps,
  {}
)(AccountSettingsContainer)
