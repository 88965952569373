/**
 * @Flow
 */

import React from 'react'
import { withRouter } from 'react-router-dom'
import { routes } from '../../../../routes'
import LoaderWeb from '../../../../ui/loader/Loader.web'
import './News.styles.web.scss'

const News = (props: any) => {
  const newslist = () => {
    const news = props.news ? props.news : []
    return news.map(item => {
      return (
        <div className="news__item-container" key={item.id}>
          <img className="news__image" src={item.imageUrl} alt="news-img" />
          <div className="news__content-container">
            <div className="news__text-container">
              <p className="news__header">{item.header}</p>
              <p className="news__created">{item.created.substring(0, 10).replace('T', ' ')}</p>
              <p className="news__text">{item.text}</p>
            </div>
            <button
              className="news__button"
              type="button"
              onClick={() => props.history.push(`${routes.newsArticle}/${item.id}`)}
            >
              LÄS MER
            </button>
          </div>
        </div>
      )
    })
  }
  return (
    <div className="news__container">
      {props.isLoading ? <LoaderWeb /> : <div className="news__list-container">{newslist()}</div>}
    </div>
  )
}

export default withRouter(News)
