import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import DeleteIcon from '../../res/images/drop_zone_delete.svg'
import FileIcon from '../../res/images/drop_zone_file.svg'

import './DropZone.styles.web.scss'

type DropZonePropsType = {
  onFileDrop: (files: File[]) => void,
  onDelete: (file: File) => void,
  files: File[],
}

export default class DropZone extends Component<DropZonePropsType> {
  onDrop = (files: File[]) => {
    this.props.onFileDrop(files)
  }

  onDelete = (event: any, file: File) => {
    event.stopPropagation()
    this.props.onDelete(file)
  }

  render() {
    const classes =
      this.props.files && this.props.files.length > 0 ? 'drop-zone' : 'drop-zone drop-zone-empty'

    return (
      <Dropzone onDrop={this.onDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={classes}>
            <input {...getInputProps()} />
            {this.props.files && this.props.files.length > 0 ? (
              this.props.files.map(file => {
                return (
                  <div className="drop-zone-file" key={file.name}>
                    <div className="drop-zone-file-description">
                      <img className="drop-zone-file-icon" src={FileIcon} alt="file" />
                      <span className="drop-zone-file-name">{file.name}</span>
                    </div>
                    <button
                      type="button"
                      className="drop-zone-delete-icon"
                      src={DeleteIcon}
                      alt="delete"
                      onClick={e => this.onDelete(e, file)}
                    />
                  </div>
                )
              })
            ) : (
              <p>Klicka eller dra filer hit</p>
            )}
          </div>
        )}
      </Dropzone>
    )
  }
}
