import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchUser, saveCredentials } from '../../../redux/actions/UserActions'
import Root from './Root'
import strings from '../../../res/strings'
import Alert from '../../../ui/alert'

type RootPropTypes = {
  fetchUser: () => void,
  data: {
    user: {},
    unverified: boolean,
    isLoading: boolean,
    error: boolean,
  },
  error: {
    headers: {
      date: Date,
    },
  },
  navigation: {
    navigate: () => void,
  },
  saveCredentials: () => void,
}

class RootContainer extends Component<RootPropTypes> {
  componentWillMount() {
    if (this.props.data.unverified === undefined && this.props.data.user === undefined) {
      this.props.fetchUser()
    }
  }

  componentWillReceiveProps(nextProps) {
    this.handleErrors(nextProps)
  }

  saveWebCredentials(token: string) {
    const credentials = {
      access_token: token,
    }
    this.props.saveCredentials(credentials)
  }

  handleErrors(nextProps) {
    if (nextProps.error && this.props.error) {
      if (nextProps.error.headers.date === this.props.error.headers.date) {
        return
      }
    }
    if (nextProps.error) {
      switch (nextProps.data.error.status) {
        case 400:
        case 404:
        case 500:
          Alert.alert(`Error: ${nextProps.data.error.status}`, strings.errorCouldNotFetchOwner)
          break
        default:
          Alert.alert(`Error: ${nextProps.data.error.status}`, undefined)
          break
      }
    }
  }

  render() {
    return (
      <Root
        navigation={this.props.navigation}
        isLoading={this.props.data.isLoading}
        user={this.props.data.user}
        saveWebCredentials={token => this.saveWebCredentials(token)}
      />
    )
  }
}

const mapStateToProps = state => ({
  data: state.user,
})

export default connect(
  mapStateToProps,
  { fetchUser, saveCredentials }
)(RootContainer)
