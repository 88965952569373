/* eslint-disable no-console */

import axios from 'axios'
import getToken, { baseUrl } from '../../appconfig'
import { ResellerType } from '../../types/ResellerType'
import strings from '../../res/strings'
import NetworkHandler from '../../utility/networkhandler/NetworkHandler'

import {
  FETCHING_MY_RESELLER,
  FETCHING_MY_RESELLER_SUCCESS,
  FETCHING_MY_RESELLER_FAILURE,
  FETCHING_RESELLERS,
  FETCHING_RESELLERS_SUCCESS,
  FETCHING_RESELLERS_FAILURE,
  SELECT_RESELLER,
  POSTING_FAVORITE_RESELLER,
  POSTING_FAVORITE_RESELLER_SUCCESS,
  POSTING_FAVORITE_RESELLER_FAILURE,
} from '../types/ResellerTypes'

export const fetchResellers = () => dispatch => {
  dispatch(getResellers())

  NetworkHandler.get(`/api/Reseller`)
    .then(response => {
      dispatch(getResellersSuccess(response.data))
    })
    .catch(error => {
      console.log(`ResellerActions.js, Error: ${error.response?.status}`)
      dispatch(getResellersFailure(error.response))
    })
}

export const setFavoriteReseller = (id: string) => dispatch => {
  dispatch(getPostMyFavoriteReseller())

  getToken().then(token => {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }

    axios
      .post(`${baseUrl}/api/Reseller/favorite`, `"${id}"`, { headers })
      .then(response => {
        dispatch(getPostMyFavoriteResellerSuccess(response.data))
      })
      .catch(error => {
        console.log(error.response)
        dispatch(getPostMyFavoriteResellerFailure(error.response))
      })
  })
}

export const fetchMyReseller = () => dispatch => {
  dispatch(getMyReseller())

  NetworkHandler.get(`/api/Reseller/favorite`)
    .then(response => {
      dispatch(getMyResellerSuccess(response.data))
    })
    .catch(error => {
      console.log(`ResellerActions.js, Error: ${error.response?.status}`)
      dispatch(getMyResellerFailure(error.response))
    })
}

export const selectReseller = row => dispatch => {
  dispatch(getSelectReseller(row))
}

function getSelectReseller(row) {
  return {
    type: SELECT_RESELLER,
    row,
  }
}

function getResellers() {
  return {
    type: FETCHING_RESELLERS,
  }
}

function getResellersSuccess(data: [ResellerType]) {
  return {
    type: FETCHING_RESELLERS_SUCCESS,
    data,
  }
}

function getResellersFailure(error) {
  return {
    type: FETCHING_RESELLERS_FAILURE,
    payload: error,
  }
}

function getMyReseller() {
  return {
    type: FETCHING_MY_RESELLER,
  }
}

function getMyResellerSuccess(data: ResellerType) {
  return {
    type: FETCHING_MY_RESELLER_SUCCESS,
    data,
  }
}

function getMyResellerFailure(error) {
  return {
    type: FETCHING_MY_RESELLER_FAILURE,
    payload: error,
  }
}

function getPostMyFavoriteReseller() {
  return {
    type: POSTING_FAVORITE_RESELLER,
  }
}

function getPostMyFavoriteResellerSuccess(data: ResellerType) {
  return {
    type: POSTING_FAVORITE_RESELLER_SUCCESS,
    data,
  }
}

function getPostMyFavoriteResellerFailure(error) {
  return {
    type: POSTING_FAVORITE_RESELLER_FAILURE,
    payload: error,
  }
}
