import axios, { AxiosRequestConfig } from 'axios'
import getToken, { baseUrl } from '../../appconfig'

export default class NetworkHandler {
  static get(url: string, config?: AxiosRequestConfig) {
    return new Promise((resolve, reject) => {
      getToken().then(token => {
        const authHeader = {
          headers: { Authorization: `Bearer ${token}` },
        }
        const mergedConfig = { ...config, ...authHeader }
        axios
          .get(baseUrl + url, mergedConfig)
          .then(resolve)
          .catch(reject)
      })
    })
  }
}
