/**
 * @Flow
 */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import b2cauth from 'react-azure-adb2c'
import Alert from '../../../ui/alert/Alert.web'

type RootWebPropsType = {
  history: {
    push: () => void,
  },
  saveWebCredentials: () => void,
}

class RootWeb extends Component<RootWebPropsType> {
  componentWillMount() {
    this.props.saveWebCredentials(b2cauth.getAccessToken())
    this.props.history.push('/start')
  }

  render() {
    return (
      <div style={{ opacity: 0 }}>
        <Alert />
      </div>
    )
  }
}

export default withRouter(RootWeb)
