/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unused-prop-types */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import MultiSelect from '../../../../ui/multiselect/MultiSelect.web'
import {
  initMessage,
  getMessage,
  updateMessage,
  deleteMessage,
  updateJourney,
  validateMessage,
} from '../../../../redux/actions/MessageActions'
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../../../ui/modal/Modal.web'
import Dropdown from '../../../../ui/dropdown/Dropdown.web'
import Alert from '../../../../ui/alert/Alert.web'
import Checkbox from '../../../../ui/checkbox/Checkbox.web'
import InputField from '../../../../ui/inputField/InputField.web'
import Textarea from '../../../../ui/textarea/Textarea.web'
import IsDirectMessage from '../IsDirectMessage.web'
import TestMessage from '../TestMessage.web'

import './DetailMessageModal.styles.web.scss'
import ConfirmationAlert from '../../../../ui/confirmationAlert/ConfirmationAlert.web'

type DetailMessageModalPropsType = {
  isOpen: boolean,
  isLoading: boolean,
  journey: boolean,
  reseller: boolean,
  campaigns: [],
  categories: [],
  timeUnits: [],
  events: [],
  countries: [],
  brands: [],
  types: [],
  deliveryYears: [],
  id: string,
  message: undefined,
  hasBeenDeleted: false,
  hasBeenUpdated: false,
  onClose: () => void,
  onDelete: () => void,
  onUpdate: () => void,
  initMessage: () => void,
  getMessage: (id: string) => void,
  updateMessage: (data: any) => void,
  deleteMessage: (id: string) => void,
  updateJourney: (data: any) => void,
  validateMessage: (data: any) => void,
  titleError: '',
  dateError: '',
  regDateFromError: '',
  regDateToError: '',
  isDirectMessage: boolean,
  isTest: boolean,
  error: null,
}

type DetailMessageModalStateType = {
  id: string,
  sendByEmail: false,
  sendByApp: false,
  sendBySMS: false,
  subject: '',
  text: '',
  categoryId: '',
  countryId: '',
  resellerId: '',
  typeId: '',
  regDateFrom: '',
  regDateTo: '',
  hours: '',
  minutes: '',
  brandId: [],
  modelId: [],
  year: [],
  timeUnitId: '',
  eventId: '',
  yearId: '',
  value: '',
  title: '',
  description: '',
  step: number,
  newsText: '',
  linkUrl: '',
  linkText: '',
  imageUrl: '',
  files: [],
  resellers: [],
  isDirectMessage: false,
}

class DetailMessageModal extends Component<
  DetailMessageModalPropsType,
  DetailMessageModalStateType
> {
  constructor(props) {
    super(props)
    this.state = {
      id: undefined,
      sendByEmail: false,
      sendByApp: false,
      sendBySMS: false,
      text: '',
      categoryId: '',
      countryId: '',
      resellerId: '',
      typeId: '',
      regDateFrom: '',
      regDateTo: '',
      hours: '',
      minutes: '',
      brandId: [],
      modelId: [],
      year: [],
      timeUnitId: '',
      eventId: '',
      value: '',
      title: '',
      description: '',
      step: 0,
      newsText: '',
      linkUrl: '',
      linkText: '',
      imageUrl: '',
      files: [],
      isDirectMessage: false,
      isTestMessage: false,
      testRecipients: '',
    }
  }

  componentDidMount() {
    this.props.initMessage()
  }

  componentWillReceiveProps(nextProps) {
    this.handleErrors(nextProps)

    if (nextProps.id && this.props.id !== nextProps.id) {
      this.props.getMessage(nextProps.id)
    }

    if (nextProps.hasBeenDeleted) {
      this.setState({ step: 0 })
      this.props.onDelete()
    }

    if (nextProps.hasBeenUpdated) {
      this.setState({ step: 0 })
      this.props.onUpdate()
    }

    if (nextProps.message) {
      const brandId = this.props.brands.filter(brand => {
        return nextProps.message.brandId.find(o => o.value === brand.value)
      })

      const modelId = []
      brandId.forEach(brand => {
        brand.models.forEach(model => {
          if (nextProps.message.modelId.find(o => o.value === model.value)) {
            modelId.push(model)
          }
        })
      })

      if (this.state.id == null) {
        this.setState({
          id: nextProps.id,
          sendByEmail: nextProps.message.sendByEmail,
          sendByApp: nextProps.message.sendByApp,
          sendBySMS: nextProps.message.sendBySMS,
          text: nextProps.message.text,
          categoryId: nextProps.message.categoryId,
          campaignId: nextProps.message.campaignId,
          countryId: nextProps.message.countryId,
          resellerId: nextProps.message.resellerId,
          typeId: nextProps.message.typeId,
          regDateFrom: nextProps.message.regDateFrom,
          regDateTo: nextProps.message.regDateTo,
          hours: `00${nextProps.message.hours}`.slice(-2),
          minutes: `00${nextProps.message.minutes}`.slice(-2),
          modelId,
          year: nextProps.message.year,
          timeUnitId: nextProps.message.timeUnitId,
          eventId: nextProps.message.eventId,
          value: nextProps.message.value,
          title: nextProps.message.title,
          description: nextProps.message.description,
          newsText: nextProps.message.newsText,
          linkUrl: nextProps.message.linkUrl,
          linkText: nextProps.message.linkText,
          imageUrl: nextProps.message.imageUrl,
          yearId: nextProps.message.yearId,
          isDirectMessage: nextProps.message.isDirectMessage ?? false,
          isTestMessage: nextProps.message.isTest ?? false,
          testRecipients: nextProps.message.testRecipients ?? '',
        })

        this.onBrandChange(brandId)
        this.onCountryChange(nextProps.message.countryId)
      }
    }
  }

  resetAndClose = () => {
    this.onPrev()

    this.setState({ id: null })
    this.props.onClose()
  }

  validate = () => {
    if (this.state.step === 0) {
      this.props.validateMessage(this.state, this.onNext)
    } else if (this.state.step === 1) {
      this.props.validateMessage(this.state, this.onSubmit)
    }
  }

  onSubmit = () => {
    if (this.state.isDirectMessage === true && this.state.isTestMessage === false) {
      this.setState({ shouldShowDirectMessageConfirmation: true })
      return
    }

    this.updateMessage()
  }

  updateMessage = () => {
    this.setState({ shouldShowDirectMessageConfirmation: false })
    if (this.props.journey) {
      this.props.updateJourney(this.state)
    } else {
      this.props.updateMessage(this.state)
    }
  }

  onDelete = () => {
    this.props.deleteMessage(this.props.message.id)
  }

  onNext = () => {
    this.setState({ step: 1 })
  }

  onPrev = () => {
    this.setState({ step: 0 })
  }

  onIsDirectMessageChange = event => {
    this.setState({ isDirectMessage: event.target.checked })
  }

  onTestRecipientsChange = event => {
    this.setState({ testRecipients: event.target.value })
  }

  onIsTestMessageChange = event => {
    if (!event.target.checked) {
      this.setState({
        isTestMessage: event.target.checked,
        testRecipients: '',
      })

      return
    }
    this.setState({ isTestMessage: event.target.checked })
  }

  onAppChange = event => {
    this.setState({ sendByApp: event.target.checked })
  }

  onEmailChange = event => {
    this.setState({ sendByEmail: event.target.checked })
  }

  onSMSChange = event => {
    this.setState({ sendBySMS: event.target.checked })
  }

  onCampaignChange = campaignId => {
    this.setState({ campaignId })
  }

  onCategoryChange = categoryId => {
    if (categoryId) {
      const category = parseInt(categoryId, 10)
      if (category <= 5) {
        this.setState({ sendByApp: false, sendByEmail: true, sendBySMS: true })
      } else if (category === 100) {
        const timeUnit = parseInt(this.state.timeUnitId, 10)
        if (timeUnit > 100) {
          this.setState({
            sendByApp: true,
            sendByEmail: false,
            sendBySMS: false,
          })
        } else {
          this.setState({
            sendByApp: true,
            sendByEmail: false,
            sendBySMS: false,
            timeUnitId: '101',
          })
        }
      }
    }

    this.setState({ categoryId })
  }

  onDeliveryValueChange = value => {
    if (value) {
      this.setState({ value: parseInt(value, 10) })
    }
  }

  onDeliveryTimeUnitChange = timeUnitId => {
    this.setState({ timeUnitId })
  }

  onDeliveryEventChange = eventId => {
    this.setState({ eventId })
  }

  onDeliveryYearChange = yearId => {
    this.setState({ yearId })
  }

  onBrandChange = brandId => {
    const models = []
    if (brandId && brandId.length > 0) {
      brandId.forEach(o => {
        o.models.forEach(p => models.push(p))
      })
    }

    const newModels = []
    if (this.state.modelId && this.state.modelId.length > 0) {
      this.state.modelId.forEach(model => {
        if (models.find(o => o.value === model.value)) {
          newModels.push(model)
        }
      })
    }

    this.setState({ brandId, models }, () =>
      this.onModelChange(newModels.length > 0 ? newModels : undefined)
    )
  }

  onModelChange = modelId => {
    let years = []

    const set = new Set()

    if (modelId && modelId.length > 0) {
      modelId.forEach(model => {
        model.years.forEach(year => set.add(year.value))
      })
    } else if (this.state.brandId && this.state.brandId.length > 0) {
      this.state.brandId.forEach(brand => {
        brand.models.forEach(model => {
          model.years.forEach(year => set.add(year.value))
        })
      })
    } else {
      this.props.brands.forEach(brand => {
        brand.models.forEach(model => {
          model.years.forEach(year => set.add(year.value))
        })
      })
    }

    years = [...set]
      .sort((a, b) => {
        return a - b
      })
      .map(o => {
        return { value: o, label: o }
      })

    const newYears = []
    if (this.state.year && this.state.year.length > 0) {
      this.state.year.forEach(year => {
        if (years.find(o => o.value === year.value)) {
          newYears.push(year)
        }
      })
    }

    this.setState({ modelId, years }, () => this.onYearChange(newYears))
  }

  onYearChange = year => {
    this.setState({ year })
  }

  onCountryChange = countryId => {
    if (this.props.countries) {
      const country = this.props.countries.find(o => o.id === countryId)
      if (country) {
        this.setState({ countryId, resellers: country.resellers })
      } else {
        this.setState({ countryId, resellers: this.props.countries[0].resellers }, () => {
          this.onResellerChange(this.props.countries[0].resellers[0].id)
        })
      }
    } else {
      this.setState({ countryId })
    }
  }

  onResellerChange = resellerId => {
    this.setState({ resellerId })
  }

  onTypeChange = typeId => {
    this.setState({ typeId })
  }

  onMessageChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  closeConfirmationAlert = () => {
    this.setState({ shouldShowDirectMessageConfirmation: false })
  }

  step1 = () => {
    const category = this.state.categoryId ? parseInt(this.state.categoryId, 10) : undefined
    const classes =
      (this.state.timeUnitId && parseInt(this.state.timeUnitId, 10) >= 101) || category === 100
        ? 'create-message-delivery-container month'
        : 'create-message-delivery-container'
    const timeUnits =
      category === 100
        ? this.props.timeUnits.filter(timeUnit => {
            const tu = parseInt(timeUnit.id, 10)
            return tu >= 101 && tu <= 112
          })
        : this.props.timeUnits

    return (
      <React.Fragment>
        <Dropdown
          placeholder="Kategori"
          items={this.props.categories}
          onChange={this.onCategoryChange}
          value={this.state.categoryId}
        />
        {category && category <= 10 && (
          <Dropdown
            placeholder="Välj kampanj"
            items={this.props.campaigns}
            onChange={this.onCampaignChange}
            value={this.state.campaignId}
          />
        )}
        {category && category !== 100 && (
          <InputField
            name="title"
            placeholder="Titel"
            onChange={this.onMessageChange}
            value={this.state.title}
            error={this.props.titleError}
          />
        )}
        {category && category <= 6 && (
          <InputField
            name="description"
            placeholder="Beskrivning"
            onChange={this.onMessageChange}
            value={this.state.description}
          />
        )}
        {category && category === 100 && (
          <React.Fragment>
            <div className="news-image-container">
              <img src={this.state.imageUrl} alt="newsimage" className="news-image" />
            </div>
            <InputField
              name="title"
              placeholder="Titel"
              onChange={this.onMessageChange}
              value={this.state.title}
            />
            <Textarea
              name="newsText"
              placeholder="Text"
              rows="5"
              onChange={this.onMessageChange}
              value={this.state.newsText}
            />
            <InputField
              name="linkUrl"
              placeholder="Länkurl"
              onChange={this.onMessageChange}
              value={this.state.linkUrl}
            />
            <InputField
              name="linkText"
              placeholder="Länktext"
              onChange={this.onMessageChange}
              value={this.state.linkText}
            />
          </React.Fragment>
        )}
        <p className="create-message-header">Meddelandeleverans</p>
        <IsDirectMessage
          isDirectMessage={this.state.isDirectMessage}
          category={category}
          onChange={this.onIsDirectMessageChange}
          timeUnitId={this.state.timeUnitId}
        />
        {this.state.isDirectMessage === false && (
          <div className={classes}>
            <InputField
              name="value"
              onChange={this.onMessageChange}
              value={this.state.value}
              type="number"
              min="1"
            />
            <Dropdown
              items={timeUnits}
              onChange={this.onDeliveryTimeUnitChange}
              value={this.state.timeUnitId}
            />
            <span style={{ alignSelf: 'center' }}>efter</span>
            <Dropdown
              items={this.props.events}
              onChange={this.onDeliveryEventChange}
              value={this.state.eventId}
            />
            <Dropdown
              items={this.props.deliveryYears}
              onChange={this.onDeliveryYearChange}
              value={this.state.yearId}
              errorHighlight="true"
            />
            <div>
              <InputField
                name="hours"
                onChange={this.onMessageChange}
                value={this.state.hours}
                description="hh"
              />
              <span>:</span>
              <InputField
                name="minutes"
                onChange={this.onMessageChange}
                value={this.state.minutes}
                description="mm"
              />
            </div>
            <div className="error-text">{this.props.dateError}</div>
          </div>
        )}
        <p className="create-message-header">Kommunikationskanaler</p>
        <div className="checkboxes-container">
          <label className="checkbox-label">
            <Checkbox checked={this.state.sendByApp} onChange={this.onAppChange} />
            <p>App</p>
          </label>
          {this.state.categoryId !== '100' && (
            <label className="checkbox-label">
              <Checkbox checked={this.state.sendByEmail} onChange={this.onEmailChange} />
              <p>E-post</p>
            </label>
          )}
          <label className="checkbox-label">
            <Checkbox checked={this.state.sendBySMS} onChange={this.onSMSChange} />
            <p>SMS</p>
          </label>
        </div>
        {this.state.sendBySMS && (
          <Textarea
            name="text"
            placeholder="SMS-meddelande"
            onChange={this.onMessageChange}
            value={this.state.text}
          />
        )}
      </React.Fragment>
    )
  }

  step2 = () => {
    return (
      <React.Fragment>
        {this.state.shouldShowDirectMessageConfirmation ? (
          <ConfirmationAlert
            onYes={this.updateMessage}
            onNo={this.closeConfirmationAlert}
            message="Är du säker på att du vill skicka meddelandet direkt?"
          />
        ) : null}

        <TestMessage
          isTestMessage={this.state.isTestMessage}
          onChange={this.onIsTestMessageChange}
          onTestRecipientsChange={this.onTestRecipientsChange}
          testRecipients={this.state.testRecipients}
        />
        {!this.state.isTestMessage ? (
          <React.Fragment>
            <p className="create-message-header">Selektering</p>
            <Dropdown
              placeholder="Land"
              items={this.props.countries}
              onChange={this.onCountryChange}
              value={this.state.countryId}
            />
            <Dropdown
              placeholder="Återförsäljare"
              items={this.state.resellers}
              onChange={this.onResellerChange}
              value={this.state.resellerId}
            />
            <Dropdown
              placeholder="Fordonstyp"
              items={this.props.types}
              onChange={this.onTypeChange}
              value={this.state.typeId}
            />
            <div className="reg-date-container">
              <div className="reg-date">
                <InputField
                  name="regDateFrom"
                  placeholder="Reg.datum - Från"
                  onChange={this.onMessageChange}
                  value={this.state.regDateFrom}
                  description="yyyy-mm-dd"
                  error={this.props.regDateFromError}
                />
              </div>
              <div className="reg-date">
                <InputField
                  name="regDateTo"
                  placeholder="Reg.datum - Till"
                  onChange={this.onMessageChange}
                  value={this.state.regDateTo}
                  description="yyyy-mm-dd"
                  error={this.props.regDateToError}
                />
              </div>
            </div>
            <MultiSelect
              options={this.props.brands}
              onChange={this.onBrandChange}
              value={this.state.brandId}
              placeholder="Modell"
            />
            <MultiSelect
              options={this.state.years}
              onChange={this.onYearChange}
              value={this.state.year}
              placeholder="Årsmodell"
            />
          </React.Fragment>
        ) : null}
      </React.Fragment>
    )
  }

  handleErrors(nextProps) {
    if (!nextProps.error) {
      return
    }
    if (nextProps.error === this.props.error) {
      return
    }
    if (nextProps.error) {
      switch (nextProps.error.status) {
        default:
          Alert.alert(`Error: ${nextProps.error.status}`, nextProps.error.statusText)
          break
      }
    }
  }

  render() {
    const footer =
      this.state.step === 0 ? (
        <ModalFooter
          onCancel={this.onDelete}
          onCancelText="TA BORT"
          onSubmit={this.validate}
          onSubmitText="FORTSÄTT"
          isLoading={this.props.isLoading}
        />
      ) : (
        <ModalFooter
          onSubmit={this.validate}
          onSubmitText="SPARA"
          onCancel={this.onPrev}
          onCancelText="TILLBAKA"
          isLoading={this.props.isLoading}
        />
      )

    return (
      <Modal handleClose={this.props.onClose} show={this.props.isOpen}>
        <Alert />
        <ModalHeader text="SKICKA MEDDELANDE" onClose={this.resetAndClose} />
        <ModalContent>
          {this.state.step === 0 && this.step1()}
          {this.state.step === 1 && this.step2()}
        </ModalContent>
        {footer}
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  campaigns: state.message.campaigns,
  recipients: state.message.recipients,
  categories: state.message.categories,
  timeUnits: state.message.timeUnits,
  events: state.message.events,
  countries: state.message.countries,
  deliveryYears: state.message.deliveryYears,
  brands: state.message.brands,
  types: state.message.types,
  error: state.message.error,
  shouldDetailClose: state.shouldDetailClose,
  message: state.message.message,
  hasBeenDeleted: state.message.hasBeenDeleted,
  hasBeenUpdated: state.message.hasBeenUpdated,
  isLoading: state.message.isLoading,
  titleError: state.message.validationErrors.titleError,
  dateError: state.message.validationErrors.dateError,
  regDateFromError: state.message.validationErrors.regDateFromError,
  regDateToError: state.message.validationErrors.regDateToError,
  isDirectMessage: state.message.isDirectMessage,
  isTest: state.message.isTest,
})

export default connect(
  mapStateToProps,
  { initMessage, getMessage, updateMessage, deleteMessage, updateJourney, validateMessage }
)(DetailMessageModal)
