import React, { Component } from 'react'
import { connect } from 'react-redux'
import CalendarIcon from '../../../res/images/message_calendar.svg'
import EmailIcon from '../../../res/images/message_email.svg'
import SmsIcon from '../../../res/images/message_sms.svg'
import MobileIcon from '../../../res/images/mobile-alt-solid.svg'
import StandardButton from '../../../ui/standardbutton/StandardButton.web'
import BuyIcon from '../../../res/images/handshake-solid.svg'
import YearIcon from '../../../res/images/birthday-cake-solid.svg'
import DetailMessageModal from '../messages/detailmessagemodal/DetailMessageModal.web'
import {
  getJourney,
  deactivateJourneyMessage,
  openDetailDialog,
  closeDetailDialog,
} from '../../../redux/actions/MessageActions'

import './Journey.styles.web.scss'

type JourneyPropsType = {
  journey: [],
  detailDialogOpen: false,
  getJourney: () => void,
  deactivateJourneyMessage: (id: string) => void,
  openDetailDialog: () => void,
  closeDetailDialog: () => void,
}

type JourneyStateType = {
  journey: [],
  expanded: [],
  detailId: string,
}

class Journey extends Component<JourneyPropsType, JourneyStateType> {
  constructor(props) {
    super(props)

    this.state = {
      expanded: [],
      journey: [],
      detailId: undefined,
    }
  }

  componentDidMount = () => {
    this.props.getJourney()
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.journey) {
      this.setState({ journey: nextProps.journey })
    }
  }

  deactivateJourneyMessage = id => {
    this.props.deactivateJourneyMessage(id)
  }

  onDetailModalOpen = detailId => {
    this.setState({ detailId })
    this.props.openDetailDialog()
  }

  onDetailModalClose = () => {
    this.setState({ detailId: undefined })
    this.props.closeDetailDialog()
  }

  onUpdate = () => {
    this.setState({ detailId: undefined })
    this.props.closeDetailDialog()
  }

  onDelete = () => {
    this.setState({ detailId: undefined })
    this.props.closeDetailDialog()
  }

  onExpand = expandedId => {
    const { expanded } = this.state
    const index = expanded.indexOf(expandedId)
    if (index > -1) {
      expanded.splice(index, 1)
      this.setState({ expanded })
    } else {
      expanded.push(expandedId)
      this.setState({ expanded })
    }
  }

  render() {
    return (
      <div>
        <div className="journey">
          {this.state.journey &&
            this.state.journey.periods &&
            this.state.journey.periods.map(period => {
              const circleIcon =
                period === this.state.journey.periods[0] ? (
                  <img src={BuyIcon} alt="buy" className="journey-period-circle-buy-icon" />
                ) : (
                  <img src={YearIcon} alt="year" className="journey-period-circle-year-icon" />
                )

              const timeline =
                this.state.journey.periods[this.state.journey.periods.length - 1] === period &&
                period.messages.length === 0 ? (
                  undefined
                ) : (
                  <div className="journey-timeline" />
                )
              return (
                <div key={period.name} className="periods-container">
                  <div className="journey-period-title-container">
                    <div className="journey-period-circle">{circleIcon}</div>
                    <p className="journey-period-name">{period.name}</p>
                  </div>
                  {timeline}
                  <div className="journey-messages-container">
                    {period.messages.map(message => {
                      let classes = ''
                      let chevron = null
                      if (this.state.expanded.includes(message.id)) {
                        classes = 'journey-message-content journey-message-content-show'
                        chevron = (
                          <button
                            type="button"
                            className="journey-message-chevron-button journey-message-chevron-up"
                            onClick={() => this.onExpand(message.id)}
                          />
                        )
                      } else {
                        classes = 'journey-message-content journey-message-content-hide'
                        chevron = (
                          <button
                            type="button"
                            className="journey-message-chevron-button journey-message-chevron-down"
                            onClick={() => this.onExpand(message.id)}
                          />
                        )
                      }
                      const messageclasses = message.active
                        ? 'journey-message-flex'
                        : 'journey-message-flex journey-message-active'
                      return (
                        <React.Fragment key={message.id}>
                          <div className="journey-message">
                            <div className={messageclasses}>
                              <div className="journey-message-fullwidth">
                                <p className="journey-message-title">{message.title}</p>
                                <p>{message.description}</p>
                              </div>
                              <div className="journey-message-info">
                                <div>
                                  <div className="journey-message-flex-inner">
                                    <div>
                                      <img
                                        src={CalendarIcon}
                                        alt="calendar"
                                        className="journey-message-calendar-icon"
                                      />
                                      {message.time}
                                    </div>
                                    {chevron}
                                  </div>
                                  <div>
                                    {message.sendByApp && (
                                      <img
                                        src={MobileIcon}
                                        alt="app"
                                        className="journey-message-app-icon"
                                      />
                                    )}
                                    {message.sendByEmail && (
                                      <img
                                        src={EmailIcon}
                                        alt="email"
                                        className="journey-message-email-icon"
                                      />
                                    )}
                                    {message.sendBySms && (
                                      <img
                                        src={SmsIcon}
                                        alt="sms"
                                        className="journey-message-sms-icon"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={classes}>
                              {message.active && (
                                <div className="journey-message-button-container">
                                  <div className="journey-message-button">
                                    <StandardButton
                                      text="REDIGERA"
                                      onClick={() => this.onDetailModalOpen(message.id)}
                                    />
                                  </div>
                                  <div className="journey-message-button">
                                    <StandardButton
                                      text="INAKTIVERA"
                                      secondary
                                      onClick={() => this.deactivateJourneyMessage(message.id)}
                                    />
                                  </div>
                                </div>
                              )}
                              {!message.active && (
                                <div className="journey-message-button-container">
                                  <div className="journey-message-button">
                                    <StandardButton
                                      text="AKTIVERA"
                                      onClick={() => this.deactivateJourneyMessage(message.id)}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {this.state.journey.periods[this.state.journey.periods.length - 1] ===
                            period && period.messages[period.messages.length - 1] === message ? (
                            undefined
                          ) : (
                            <div className="journey-timeline" />
                          )}
                        </React.Fragment>
                      )
                    })}
                  </div>
                </div>
              )
            })}
        </div>
        <DetailMessageModal
          isOpen={this.props.detailDialogOpen}
          onClose={this.onDetailModalClose}
          id={this.state.detailId}
          onDelete={this.onDelete}
          onUpdate={this.onUpdate}
          journey
          reseller
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  journey: state.message.journey,
  detailDialogOpen: state.message.detailDialogOpen,
  isLoading: state.message.isLoading,
  error: state.message.error,
})

export default connect(
  mapStateToProps,
  { getJourney, deactivateJourneyMessage, openDetailDialog, closeDetailDialog }
)(Journey)
