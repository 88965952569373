/**
 * @Flow
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  fetchMessages,
  fetchMessageItem,
  markMessagesAsRead,
} from '../../../../../redux/actions/UserMessageActions'
import { routes } from '../../../../../routes'
import LoaderWeb from '../../../../../ui/loader/Loader.web'
import readMessageIcon from '../../../../../res/images/ic_message_read.svg'
import unreadMessageIcon from '../../../../../res/images/ic_message_unread.svg'
import './MessageView.styles.web.scss'

type MessageViewPropTypes = {
  messageItem: any,
  messages: [],
  match: any,
  messageRead: boolean,
  fetchMessages: () => void,
  fetchMessageItem: () => void,
  markMessagesAsRead: (id: string) => void,
  history: {
    push: () => void,
  },
}

class MessageView extends Component<MessageViewPropTypes> {
  constructor(props: any) {
    super(props)
    this.imageRef = React.createRef()

    const { id } = this.props.match.params
    this.props.fetchMessageItem(id, true)
    this.props.fetchMessages()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.props.fetchMessageItem(nextProps.match.params.id)
      if (this.imageRef) {
        this.imageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }

    if (nextProps.messageItem?.read === false) {
      this.markAsRead(nextProps.messageItem.id)
    }

    if (nextProps.messageRead) {
      this.props.fetchMessages()
    }
  }

  markAsRead(id) {
    this.props.markMessagesAsRead(id)
  }

  moreMessages() {
    const messagesList = this.props.messages.filter(
      messagesItem => messagesItem.id !== this.props.match.params.id
    )
    return messagesList.map(messagesItem => {
      return (
        <div className="messagesarticle__more-messages-item-container" key={messagesItem.id}>
          <div className="messagesarticle__more-messages-content-container">
            <img
              className="messagesarticle__more-messages-image"
              src={messagesItem.read ? readMessageIcon : unreadMessageIcon}
              alt="message-img"
            />
            <p className="messagesarticle__more-messages-header">{messagesItem.header}</p>
          </div>
          <button
            className="messagesarticle__more-messages-button"
            type="button"
            onClick={() => {
              this.props.history.push(`${messagesItem.id}`)
            }}
          >
            LÄS
          </button>
        </div>
      )
    })
  }

  render() {
    const { messageItem, messages } = this.props
    const html = messageItem?.html && atob(messageItem.html)

    return (
      <div className="messagesarticle__container">
        {messageItem === undefined ? (
          <LoaderWeb />
        ) : (
          <div>
            <div dangerouslySetInnerHTML={{ __html: html }} ref={this.imageRef} />
            <div className="messagesarticle__moremessages-header-container">
              <p className="messagesarticle__moremessages-header">Fler meddelanden</p>
              <div className="messagesarticle__separator" />
            </div>
            <div className="messagesarticle__moremessages-container">{this.moreMessages()}</div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  messages: state.usermessagereducers.messages,
  messageItem: state.usermessagereducers.messageItem,
})

export default connect(
  mapStateToProps,
  { fetchMessages, fetchMessageItem, markMessagesAsRead }
)(MessageView)
