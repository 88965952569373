const TimeUnit = {
  Days: 1,
  Months: 2,
  Separator: '----------',
  January: 101,
  February: 102,
  March: 103,
  April: 104,
  May: 105,
  June: 106,
  July: 107,
  August: 108,
  September: 109,
  October: 110,
  November: 111,
  December: 112,
  isMonth: timeUnit => {
    return timeUnit >= 101 && timeUnit <= 112
  },
  toTimeUnit: month => {
    return month + 101
  },
  toMonth: timeUnit => {
    return timeUnit - 101
  },
}
export default TimeUnit
