import { connect } from 'react-redux'
import React, { Component } from 'react'
import Checkbox from '../../../ui/checkbox/Checkbox.web'
import InputField from '../../../ui/inputField/InputField.web'
import './TestMessage.styles.web.scss'

type TestMessagePropsType = {
  isTestMessage: boolean,
  testRecipients: String,
  onChange: () => void,
  onTestRecipientsChange: () => void,
  error: string,
}

class TestMessage extends Component<TestMessagePropsType> {
  constructor(props: any) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="container">
        <label className="checkbox-label">
          <Checkbox checked={this.props.isTestMessage} onChange={this.props.onChange} />
          <p>Testutskick</p>
        </label>
        {this.props.isTestMessage === true && (
          <div>
            <InputField
              name="recipients"
              placeholder="Testmottagare (e-post)"
              description="testuser1@test.com, testuser2@test.com..."
              error={this.props.error}
              value={this.props.testRecipients}
              onChange={this.props.onTestRecipientsChange}
            />
            <p>* Testutskick görs omgående oavsett valt datum i föregående steg. </p>
            <p>* Mottagare måste vara tillagda i ägarregistret</p>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  error: state.message.validationErrors.testRecipientsError,
})

export default connect(mapStateToProps)(TestMessage)
