import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

import {
  FETCHING_USER,
  FETCHING_USER_SUCCESS,
  FETCHING_USER_FAILURE,
  FETCHING_USER_UNVERIFIED,
  SAVE_CREDENTIALS,
  RESET_CREDENTIALS,
} from '../types/UserTypes'
import { REMOVING_VEHICLE_SUCCESS } from '../types/VehicleTypes'

const initialState = {
  user: undefined,
  unverified: undefined,
  isLoading: true,
  error: undefined,
  credentials: '',
}

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOVING_VEHICLE_SUCCESS:
      return {
        ...state,
        user: undefined,
        unverified: true,
      }
    case FETCHING_USER:
      return {
        ...state,
        isLoading: true,
      }

    case FETCHING_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.data,
      }

    case FETCHING_USER_UNVERIFIED:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        unverified: true,
        user: undefined,
      }

    case FETCHING_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        user: undefined,
        error: action.payload,
      }

    case SAVE_CREDENTIALS:
      return {
        ...state,
        credentials: action.payload,
      }

    case RESET_CREDENTIALS:
      return {
        ...state,
        credentials: undefined,
        user: undefined,
        unverified: true,
      }

    default:
      return state
  }
}

const persistConfig = {
  key: 'user',
  storage,
  whitelist: ['credentials', 'user'],
  blacklist: ['isLoading'],
  stateReconciler: hardSet,
}

export default persistReducer(persistConfig, UserReducer)
