/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import MultiSelect from '../../../../ui/multiselect/MultiSelect.web'
import {
  initMessage,
  createMessage,
  validateMessage,
} from '../../../../redux/actions/MessageActions'
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../../../ui/modal/Modal.web'
import Dropdown from '../../../../ui/dropdown/Dropdown.web'
import Checkbox from '../../../../ui/checkbox/Checkbox.web'
import InputField from '../../../../ui/inputField/InputField.web'
import Textarea from '../../../../ui/textarea/Textarea.web'
import DropZone from '../../../../ui/dropzone/DropZone.web'
import Alert from '../../../../ui/alert/Alert.web'
import { MessageCategory } from '../../../utilities/MessageCategory.ts'
import TimeUnit from '../../../utilities/TimeUnit.web'
import IsDirectMessage from '../IsDirectMessage.web'
import TestMessage from '../TestMessage.web'

import './CreateMessageModal.styles.web.scss'
import ConfirmationAlert from '../../../../ui/confirmationAlert/ConfirmationAlert.web'

type CreateMessageModalPropsType = {
  isOpen: boolean,
  isLoading: boolean,
  campaigns: [],
  categories: [],
  timeUnits: [],
  events: [],
  countries: [],
  brands: [],
  types: [],
  deliveryYears: [],
  shouldClose: false,
  onClose: () => void,
  initMessage: () => void,
  createMessage: (data: any) => void,
  validateMessage: (data: any) => void,
  titleError: '',
  dateError: '',
  regDateFromError: '',
  regDateToError: '',
  error: null,
}

type CreateMessageModalStateType = {
  campaignId: string,
  categoryId: string,
  title: string,
  description: string,
  value: string,
  timeUnitId: string,
  eventId: string,
  yearId: string,
  sendByEmail: boolean,
  sendByApp: boolean,
  sendBySMS: boolean,
  countryId: string,
  resellerId: string,
  typeId: string,
  regDateFrom: string,
  regDateTo: string,
  models: [],
  years: [],
  brandId: [],
  modelId: [],
  year: [],
  step: Number,
  newsText: string,
  linkUrl: string,
  linkText: string,
  hours: string,
  minutes: string,
  resellers: [],
}

class CreateMessageModal extends Component<
  CreateMessageModalPropsType,
  CreateMessageModalStateType
> {
  constructor(props) {
    super(props)
    this.state = {
      sendByEmail: false,
      sendByApp: false,
      sendBySMS: false,
      text: '',
      value: '',
      title: '',
      description: '',
      step: 0,
      newsText: '',
      linkUrl: '',
      linkText: '',
      files: [],
      hours: '',
      minutes: '',
      regDateFrom: '',
      regDateTo: '',
      isDirectMessage: false,
      isTestMessage: false,
      testRecipients: '',
    }
  }

  componentDidMount() {
    this.props.initMessage()
  }

  componentWillReceiveProps(nextProps) {
    this.handleErrors(nextProps)
    if (nextProps.shouldClose) {
      this.resetAndClose()
    }

    if (this.props.countries.length === 0 && nextProps.countries.length > 0) {
      this.onCategoryChange(nextProps.categories[0].id)
      if (nextProps.campaigns) {
        this.onCampaignChange(nextProps.campaigns[0].id)
      }
      this.onTypeChange(nextProps.types[0].id)
      this.onDeliveryTimeUnitChange(nextProps.timeUnits[0].id)
      this.onDeliveryEventChange(nextProps.events[0].id)
      this.onDeliveryYearChange(new Date().getFullYear())
      this.setState(
        {
          brandId: [],
          modelId: [],
          year: [],
        },
        () => this.onBrandChange(undefined)
      )
    }
  }

  resetAndClose = () => {
    this.setState({
      sendByEmail: false,
      sendByApp: false,
      sendBySMS: false,
      subject: '',
      text: '',
      categoryId: '1',
      countryId: '',
      resellerId: '',
      typeId: '',
      brandId: [],
      modelId: [],
      year: [],
      timeUnitId: '1',
      eventId: '1',
      value: '',
      title: '',
      description: '',
      step: 0,
      newsText: '',
      linkUrl: '',
      linkText: '',
      hours: '',
      minutes: '',
      files: [],
      isDirectMessage: false,
      isTestMessage: false,
    })
    this.props.onClose()
  }

  onSubmit = () => {
    if (this.state.isDirectMessage === true && this.state.isTestMessage === false) {
      this.setState({ shouldShowDirectMessageConfirmation: true })
      return
    }
    this.createMessage()
  }

  createMessage = () => {
    this.setState({ shouldShowDirectMessageConfirmation: false })
    this.props.createMessage(this.state)
  }

  validate = () => {
    if (this.state.step === 0) {
      this.props.validateMessage(this.state, this.onNext)
    } else if (this.state.step === 1) {
      this.props.validateMessage(this.state, this.onSubmit)
    }
  }

  onNext = () => {
    this.setState({ step: 1 })
  }

  onPrev = () => {
    this.setState({ step: 0 })
  }

  onIsDirectMessageChange = event => {
    this.setState({ isDirectMessage: event.target.checked })
  }

  onTestRecipientsChange = event => {
    this.setState({ testRecipients: event.target.value })
  }

  onIsTestMessageChange = event => {
    if (!event.target.checked) {
      this.setState({
        isTestMessage: event.target.checked,
        testRecipients: '',
      })

      return
    }
    this.setState({ isTestMessage: event.target.checked })
  }

  onAppChange = event => {
    this.setState({ sendByApp: event.target.checked })
  }

  onEmailChange = event => {
    this.setState({ sendByEmail: event.target.checked })
  }

  onSMSChange = event => {
    this.setState({ sendBySMS: event.target.checked })
  }

  onCampaignChange = campaignId => {
    this.setState({ campaignId })
  }

  onCategoryChange = categoryId => {
    if (categoryId) {
      const category = parseInt(categoryId, 10)

      if (category <= 6) {
        this.setState({
          sendByApp: false,
          sendByEmail: true,
          sendBySMS: true,
          isDirectMessage: false,
        })
      } else if (category === MessageCategory.News) {
        const timeUnit = parseInt(this.state.timeUnitId, 10)
        if (timeUnit > 100) {
          this.setState({
            sendByApp: true,
            sendByEmail: false,
            sendBySMS: false,
            isDirectMessage: false,
          })
        } else {
          this.setState({
            sendByApp: true,
            sendByEmail: false,
            sendBySMS: false,
            timeUnitId: TimeUnit.January,
            isDirectMessage: false,
          })
        }
      }
    }

    this.setState({ categoryId })
  }

  onDeliveryValueChange = value => {
    if (value) {
      this.setState({ value: parseInt(value, 10) })
    }
  }

  onDeliveryTimeUnitChange = timeUnitId => {
    this.setState({ timeUnitId, isDirectMessage: false })
  }

  onDeliveryEventChange = eventId => {
    this.setState({ eventId })
  }

  onDeliveryYearChange = yearId => {
    this.setState({ yearId })
  }

  onBrandChange = brandId => {
    const models = []
    if (brandId && brandId.length > 0) {
      brandId.forEach(o => {
        o.models.forEach(p => models.push(p))
      })
    }

    const newModels = []
    if (this.state.modelId && this.state.modelId.length > 0) {
      this.state.modelId.forEach(model => {
        if (models.find(o => o.value === model.value)) {
          newModels.push(model)
        }
      })
    }

    this.setState({ brandId, models }, () =>
      this.onModelChange(newModels.length > 0 ? newModels : undefined)
    )
  }

  onModelChange = modelId => {
    let years = []

    const set = new Set()

    if (modelId && modelId.length > 0) {
      modelId.forEach(model => {
        model.years.forEach(year => set.add(year.value))
      })
    } else if (this.state.brandId && this.state.brandId.length > 0) {
      this.state.brandId.forEach(brand => {
        brand.models.forEach(model => {
          model.years.forEach(year => set.add(year.value))
        })
      })
    } else {
      this.props.brands.forEach(brand => {
        brand.models.forEach(model => {
          model.years.forEach(year => set.add(year.value))
        })
      })
    }

    years = [...set]
      .sort((a, b) => {
        return a - b
      })
      .map(o => {
        return { value: o, label: o }
      })

    const newYears = []
    if (this.state.year && this.state.year.length > 0) {
      this.state.year.forEach(year => {
        if (years.find(o => o.value === year.value)) {
          newYears.push(year)
        }
      })
    }

    this.setState({ modelId, years }, () => this.onYearChange(newYears))
  }

  onYearChange = year => {
    this.setState({ year })
  }

  onCountryChange = countryId => {
    if (this.props.countries) {
      const country = this.props.countries.find(o => o.id === countryId)
      if (country) {
        this.setState({ countryId, resellers: country.resellers })
        this.onResellerChange(country.resellers[0].id)
      }
    } else {
      this.setState({ countryId })
    }
  }

  onResellerChange = resellerId => {
    this.setState({ resellerId })
  }

  onTypeChange = typeId => {
    this.setState({ typeId })
  }

  onMessageChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  onFileDrop = (files: File[]) => {
    this.readFile(files[0])
    this.setState({ files })
  }

  onFileDelete = (file: File) => {
    this.setState({ files: [] })
  }

  readFile = (file: File) => {
    const reader = new FileReader()

    reader.onload = () => {}

    reader.readAsDataURL(file)
  }

  closeConfirmationAlert = () => {
    this.setState({ shouldShowDirectMessageConfirmation: false })
  }

  step1 = () => {
    const category = this.state.categoryId ? parseInt(this.state.categoryId, 10) : undefined
    const classes =
      (this.state.timeUnitId && parseInt(this.state.timeUnitId, 10)) >= 101 ||
      category === MessageCategory.News
        ? 'create-message-delivery-container month'
        : 'create-message-delivery-container'
    const timeUnits =
      category === MessageCategory.News
        ? this.props.timeUnits.filter(timeUnit => {
            const tu = parseInt(timeUnit.id, 10)
            return TimeUnit.isMonth(tu)
          })
        : this.props.timeUnits

    return (
      <React.Fragment>
        <Dropdown
          placeholder="Kategori"
          items={this.props.categories}
          onChange={this.onCategoryChange}
          value={this.state.categoryId}
        />
        {category && category <= 10 && (
          <Dropdown
            placeholder="Välj kampanj"
            items={this.props.campaigns}
            onChange={this.onCampaignChange}
            value={this.state.campaignId}
          />
        )}
        {category && category !== MessageCategory.News && (
          <React.Fragment>
            <InputField
              name="title"
              placeholder="Titel *"
              onChange={this.onMessageChange}
              value={this.state.title}
              error={this.props.titleError}
            />
          </React.Fragment>
        )}
        {category && category <= 6 && (
          <InputField
            name="description"
            placeholder="Beskrivning"
            onChange={this.onMessageChange}
            value={this.state.description}
          />
        )}
        {category && category === MessageCategory.News && (
          <React.Fragment>
            <DropZone
              onFileDrop={this.onFileDrop}
              onDelete={this.onFileDrop}
              files={this.state.files}
            />
            <InputField
              name="title"
              placeholder="Titel"
              onChange={this.onMessageChange}
              value={this.state.title}
              error={this.props.titleError}
            />
            <Textarea
              name="newsText"
              placeholder="Text"
              rows="5"
              onChange={this.onMessageChange}
              value={this.state.newsText}
            />
            <InputField
              name="linkUrl"
              placeholder="Länkurl"
              onChange={this.onMessageChange}
              value={this.state.linkUrl}
            />
            <InputField
              name="linkText"
              placeholder="Länktext"
              onChange={this.onMessageChange}
              value={this.state.linkText}
            />
          </React.Fragment>
        )}
        <p className="create-message-header">Meddelandeleverans</p>
        <IsDirectMessage
          isDirectMessage={this.state.isDirectMessage}
          category={category}
          onChange={this.onIsDirectMessageChange}
          timeUnitId={this.state.timeUnitId}
        />
        {(this.state.isDirectMessage === null || this.state.isDirectMessage === false) && (
          <div className={classes}>
            <InputField
              name="value"
              onChange={this.onMessageChange}
              value={this.state.value}
              type="number"
              min="1"
            />
            <Dropdown
              items={timeUnits}
              onChange={this.onDeliveryTimeUnitChange}
              value={this.state.timeUnitId}
            />
            <span style={{ alignSelf: 'center' }}>efter</span>
            <Dropdown
              items={this.props.events}
              onChange={this.onDeliveryEventChange}
              value={this.state.eventId}
            />
            <Dropdown
              items={this.props.deliveryYears}
              onChange={this.onDeliveryYearChange}
              value={this.state.yearId}
            />
            <div>
              <InputField
                name="hours"
                onChange={this.onMessageChange}
                value={this.state.hours}
                description="hh"
              />
              <span>:</span>
              <InputField
                name="minutes"
                onChange={this.onMessageChange}
                value={this.state.minutes}
                description="mm"
              />
            </div>
            <div className="error-text">{this.props.dateError}</div>
          </div>
        )}
        <p className="create-message-header">Kommunikationskanaler</p>
        <div className="checkboxes-container">
          <label className="checkbox-label">
            <Checkbox checked={this.state.sendByApp} onChange={this.onAppChange} />
            <p>App</p>
          </label>
          {this.state.categoryId !== '100' && (
            <label className="checkbox-label">
              <Checkbox checked={this.state.sendByEmail} onChange={this.onEmailChange} />
              <p>E-post</p>
            </label>
          )}
          <label className="checkbox-label">
            <Checkbox checked={this.state.sendBySMS} onChange={this.onSMSChange} />
            <p>SMS</p>
          </label>
        </div>
        {this.state.sendBySMS && (
          <Textarea
            name="text"
            placeholder="SMS-meddelande"
            onChange={this.onMessageChange}
            value={this.state.text}
          />
        )}
      </React.Fragment>
    )
  }

  onMultiChange = (value: any) => {
    this.setState({ multiselectvalue: value })
  }

  step2 = () => {
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        border: '1px solid rgba(0, 0, 0, 0.87)',
        minHeight: '56px',
        '&:hover': {
          border: '1px solid rgba(0, 0, 0, 0.87)',
        },
      }),
    }

    return (
      <React.Fragment>
        {this.state.shouldShowDirectMessageConfirmation ? (
          <ConfirmationAlert
            onYes={this.createMessage}
            onNo={this.closeConfirmationAlert}
            message="Är du säker på att du vill skicka meddelandet direkt?"
          />
        ) : null}

        <TestMessage
          isTestMessage={this.state.isTestMessage}
          onChange={this.onIsTestMessageChange}
          onTestRecipientsChange={this.onTestRecipientsChange}
          testRecipients={this.state.testRecipients}
        />
        {!this.state.isTestMessage ? (
          <React.Fragment>
            <p className="create-message-header">Selektering</p>
            <Dropdown
              placeholder="Land"
              items={this.props.countries}
              onChange={this.onCountryChange}
              value={this.state.countryId}
            />
            <Dropdown
              placeholder="Återförsäljare"
              items={this.state.resellers}
              onChange={this.onResellerChange}
              value={this.state.resellerId}
            />
            <Dropdown
              placeholder="Fordonstyp"
              items={this.props.types}
              onChange={this.onTypeChange}
              value={this.state.typeId}
            />
            <div className="reg-date-container">
              <div className="reg-date">
                <InputField
                  name="regDateFrom"
                  placeholder="Reg.datum - Från"
                  onChange={this.onMessageChange}
                  value={this.state.regDateFrom}
                  description="yyyy-mm-dd"
                  error={this.props.regDateFromError}
                />
              </div>
              <div className="reg-date">
                <InputField
                  name="regDateTo"
                  placeholder="Reg.datum - Till"
                  onChange={this.onMessageChange}
                  value={this.state.regDateTo}
                  description="yyyy-mm-dd"
                  error={this.props.regDateToError}
                />
              </div>
            </div>
            <MultiSelect
              options={this.props.brands}
              onChange={this.onBrandChange}
              value={this.state.brandId}
              placeholder="Modell"
            />
            <MultiSelect
              options={this.state.years}
              onChange={this.onYearChange}
              value={this.state.year}
              placeholder="Årsmodell"
            />
          </React.Fragment>
        ) : null}
      </React.Fragment>
    )
  }

  content = () => {
    if (this.state.step === 0) {
      return this.step1()
    }

    return this.step2()
  }

  handleErrors(nextProps) {
    if (!nextProps.error) {
      return
    }
    if (nextProps.error === this.props.error) {
      return
    }
    if (nextProps.error) {
      switch (nextProps.error.status) {
        default:
          Alert.alert(`Error: ${nextProps.error.status}`, nextProps.error.statusText)
          break
      }
    }
  }

  render() {
    const footer =
      this.state.step === 0 ? (
        <ModalFooter onSubmit={this.validate} onSubmitText="FORTSÄTT" />
      ) : (
        <ModalFooter
          onSubmit={this.validate}
          onSubmitText="SKICKA"
          onCancel={this.onPrev}
          onCancelText="TILLBAKA"
          isLoading={this.props.isLoading}
        />
      )

    return (
      <Modal handleClose={this.props.onClose} show={this.props.isOpen}>
        <Alert />
        <ModalHeader text="SKICKA MEDDELANDE" onClose={this.resetAndClose} />
        <ModalContent>{this.content()}</ModalContent>
        {footer}
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  campaigns: state.message.campaigns,
  categories: state.message.categories,
  timeUnits: state.message.timeUnits,
  events: state.message.events,
  countries: state.message.countries,
  deliveryYears: state.message.deliveryYears,
  brands: state.message.brands,
  types: state.message.types,
  error: state.message.error,
  isLoading: state.message.isLoading,
  shouldClose: state.message.shouldClose,
  titleError: state.message.validationErrors.titleError,
  dateError: state.message.validationErrors.dateError,
  regDateFromError: state.message.validationErrors.regDateFromError,
  regDateToError: state.message.validationErrors.regDateToError,
})

export default connect(
  mapStateToProps,
  { initMessage, createMessage, validateMessage }
)(CreateMessageModal)
