/**
 * @flow
 */

import React from 'react'
import { Provider } from 'react-redux'
import { Platform, View } from 'react-native'
import { PersistGate } from 'redux-persist/lib/integration/react'
import store, { persistor } from './redux/store'
import Routes from './routes/routes'
import OfflineNotice from './ui/offlinenotice/OfflineNotice'
import styles from './App.styles.native'

const web = (props: any) => {
  return (
    <div className="App">
      <Routes cancel={props.cancel} />
    </div>
  )
}

const native = () => {
  return (
    <View style={styles.app}>
      <OfflineNotice />
      <Routes />
    </View>
  )
}

export default (props: any) => (
  <PersistGate persistor={persistor}>
    <Provider store={store}>{Platform.OS === 'web' ? web(props) : native()}</Provider>
  </PersistGate>
)
