/**
 * @Flow
 */
import React, { Component } from 'react'
import { Platform } from 'react-native'
import { connect } from 'react-redux'
import Owner from './Owner'
import { fetchOwner, updateOwner } from '../../../redux/actions/OwnerActions'
import { OwnerType } from '../../../types/OwnerType'
import strings from '../../../res/strings'
import Alert from '../../../ui/alert'

type OwnerPropTypes = {
  owner: OwnerType,
  isLoading: boolean,
  error: {
    status: number,
    headers: {
      date: Date,
    },
  },
  fetchOwner: () => void,
  updateOwner: () => void,
}

type OwnerStateTypes = {
  edit: boolean,
  owner: OwnerType,
}

class OwnerContainer extends Component<OwnerPropTypes, OwnerStateTypes> {
  constructor(props: any) {
    super(props)

    this.state = {
      edit: false,
      owner: props.owner,
    }
  }

  componentWillMount() {
    this.props.fetchOwner()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.owner) {
      this.setState({ owner: nextProps.owner })
    }

    if (this.state.edit && !nextProps.isLoading) {
      this.setState({ edit: false })
    }

    this.handleErrors(nextProps)
  }

  onInputChange(e, nativeValue) {
    const value = Platform.OS === 'web' ? e.target.value : e.nativeEvent.text
    const name = Platform.OS === 'web' ? e.target.name : nativeValue
    this.setState(prevState => ({
      owner: {
        ...prevState.owner,
        [name]: value,
      },
    }))
  }

  handleErrors(nextProps) {
    if (nextProps.error && this.props.error) {
      if (nextProps.error.headers.date === this.props.error.headers.date) {
        return
      }
    }
    if (nextProps.error) {
      switch (nextProps.error.status) {
        case 400:
        case 404:
        case 500:
          Alert.alert(`Error: ${nextProps.error.status}`, strings.errorCouldNotFetchOwner)
          break
        default:
          Alert.alert(`Error: ${nextProps.error.status}`, undefined)
          break
      }
    }
  }

  saveForm() {
    const { owner } = this.state
    this.props.updateOwner(owner)
  }

  editForm() {
    this.setState({ edit: true })
  }

  render() {
    return (
      <Owner
        owner={this.state.owner}
        edit={this.state.edit}
        saveForm={() => this.saveForm()}
        editForm={() => this.editForm()}
        onInputChange={(e, nativeValue) => this.onInputChange(e, nativeValue)}
        isLoading={this.props.isLoading}
      />
    )
  }
}

const mapStateToProps = state => ({
  owner: state.owner.owner,
  isLoading: state.owner.isLoading,
  error: state.owner.error,
})

export default connect(
  mapStateToProps,
  { fetchOwner, updateOwner }
)(OwnerContainer)
