import React, { Component } from 'react'
import './InputField.styles.web.scss'

type InputFieldPropsType = {
  placeholder: string,
  description: string,
  value: string,
  name: string,
  onChange: (text: string) => void,
  error: string,
  type: string,
  max: string,
  min: string,
}

export default class InputField extends Component<InputFieldPropsType> {
  onChange = event => {
    this.props.onChange(event.target.value)
  }

  render() {
    const errorText = this.props.error ?? ''

    const type = this.props.type ?? 'text'
    const min = this.props.min ?? ''
    const max = this.props.max ?? ''

    return (
      <div className="kabe-input-container">
        <input
          name={this.props.name}
          className="kabe-input-field"
          onChange={this.props.onChange}
          value={this.props.value}
          placeholder={this.props.description}
          type={type}
          min={min}
          max={max}
        />
        <span className="kabe-input-field-placeholder">{this.props.placeholder}</span>
        <span className="kabe-input-field-error-text">{errorText}</span>
      </div>
    )
  }
}
