/* eslint-disable import/prefer-default-export */
import axios from 'axios'
import { baseUrl, getAdminToken } from '../../appconfig'
import { GET_ROLE, GET_ROLE_SUCCESS, GET_ROLE_FAILURE } from '../types/RoleTypes'

export const getRole = () => dispatch => {
  dispatch(getGetRole())

  getAdminToken().then(token => {
    const authHeader = `Bearer ${token}`

    axios
      .get(`${baseUrl}/api/role`, {
        headers: { Authorization: authHeader },
      })
      .then(response => {
        dispatch(getGetRoleSuccess(response.data))
      })
      .catch(error => {
        dispatch(getGetRoleFailure(error.response))
        console.log(`MessageActions.js, Error: ${error.response?.status}`)
      })
  })
}

function getGetRole() {
  return {
    type: GET_ROLE,
  }
}

function getGetRoleSuccess(data) {
  return {
    type: GET_ROLE_SUCCESS,
    data,
  }
}

function getGetRoleFailure(error) {
  return {
    type: GET_ROLE_FAILURE,
    payload: error,
  }
}
