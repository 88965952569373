/**
 * @Flow
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import AllResellers from './AllResellers'
import { fetchResellers, selectReseller } from '../../../../redux/actions/ResellerActions'
import { ResellerType } from '../../../../types/ResellerType'
import strings from '../../../../res/strings'
import Alert from '../../../../ui/alert'

type AllResellersContainerTypes = {
  resellers: [ResellerType],
  isLoading: boolean,
  error: {
    status: number,
    headers: {
      date: Date,
    },
  },
  fetchResellers: () => void,
  minify: boolean,
  clickEnabled: boolean,
  selectReseller: (row: ResellerType) => void,
  selectedRow: ResellerType,
}

class AllResellersContainer extends Component<AllResellersContainerTypes> {
  componentWillMount() {
    this.props.fetchResellers()
  }

  componentWillReceiveProps(nextProps) {
    this.handleErrors(nextProps)
  }

  static navigationOptions = {
    title: strings.all,
  }

  handleErrors(nextProps) {
    if (nextProps.error && this.props.error) {
      if (nextProps.error.headers.date === this.props.error.headers.date) {
        return
      }
    }
    if (nextProps.error) {
      switch (nextProps.error.status) {
        case 400:
        case 404:
          break
        case 500:
          Alert.alert(`Error: ${nextProps.error.status}`, strings.errorCouldNotFetchResellers)
          break
        default:
          Alert.alert(`Error: ${nextProps.error.status}`, undefined)
          break
      }
    }
  }

  render() {
    return (
      <AllResellers
        resellers={this.props.resellers}
        isLoading={this.props.isLoading}
        clickEnabled={this.props.clickEnabled}
        minify={this.props.minify}
        selectReseller={row => this.props.selectReseller(row)}
        selectedRow={this.props.selectedRow}
      />
    )
  }
}

const mapStateToProps = state => ({
  resellers: state.reseller.resellers,
  isLoading: state.reseller.isLoading,
  selectedRow: state.reseller.selectedRow,
  error: state.reseller.error,
})

export default connect(
  mapStateToProps,
  { fetchResellers, selectReseller }
)(AllResellersContainer)
