export const INIT_MESSAGE = 'INIT_MESSAGE'
export const INIT_MESSAGE_SUCCESS = 'INIT_MESSAGE_SUCCESS'
export const INIT_MESSAGE_FAILURE = 'INIT_MESSAGE_FAILURE'
export const INIT_EXISTING_MESSAGE = 'INIT_EXISTING_MESSAGE'
export const INIT_EXISTING_MESSAGE_SUCCESS = 'INIT_EXISTING_MESSAGE_SUCCESS'
export const INIT_EXISTING_MESSAGE_FAILURE = 'INIT_EXISTING_MESSAGE_FAILURE'
export const CREATE_MESSAGE = 'CREATE_MESSAGE'
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS'
export const CREATE_MESSAGE_FAILURE = 'CREATE_MESSAGE_FAILURE'
export const GET_MESSAGE = 'GET_MESSAGE'
export const GET_MESSAGE_SUCCESS = 'GET_MESSAGE_SUCCESS'
export const GET_MESSAGE_FAILURE = 'GET_MESSAGE_FAILURE'
export const GET_MESSAGES = 'GET_MESSAGES'
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'
export const GET_MESSAGES_FAILURE = 'GET_MESSAGES_FAILURE'
export const UPDATE_MESSAGE = 'UPDATE_MESSAGES'
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS'
export const UPDATE_MESSAGE_FAILURE = 'UPDATE_MESSAGE_FAILURE'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS'
export const DELETE_MESSAGE_FAILURE = 'DELETE_MESSAGE_FAILURE'
export const GET_JOURNEY = 'GET_JOURNEY'
export const GET_JOURNEY_SUCCESS = 'GET_JOURNEY_SUCCESS'
export const GET_JOURNEY_FAILURE = 'GET_JOURNEY_FAILURE'
export const UPDATE_JOURNEY = 'UPDATE_JOURNEY'
export const UPDATE_JOURNEY_SUCCESS = 'UPDATE_JOURNEY_SUCCESS'
export const UPDATE_JOURNEY_FAILURE = 'UPDATE_JOURNEY_FAILURE'
export const DELETE_JOURNEY_MESSAGE = 'DELETE_JOURNEY_MESSAGE'
export const DELETE_JOURNEY_MESSAGE_SUCCESS = 'DELETE_JOURNEY_MESSAGE_SUCCESS'
export const DELETE_JOURNEY_MESSAGE_FAILURE = 'DELETE_JOURNEY_MESSAGE_FAILURE'
export const OPEN_DIALOG = 'OPEN_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'
export const OPEN_DETAIL_DIALOG = 'OPEN_DETAIL_DIALOG'
export const CLOSE_DETAIL_DIALOG = 'CLOSE_DETAIL_DIALOG'
export const VALIDATE_MESSAGE_SUCCESS = 'VALIDATE_MESSAGE_SUCCESS'
export const VALIDATE_MESSAGE_FAILURE = 'VALIDATE_MESSAGE_FAILURE'
