/**
 * @Flow
 */

import React from 'react'
import Alert from '../../../ui/alert'
import MyReseller from './myreseller'
import AllResellers from './allresellers'
import Tabs from '../../../ui/tabs/Tabs.web'
import strings from '../../../res/strings'
import './Resellers.styles.web.scss'

export default () => {
  return (
    <div className="resellers__container">
      <Alert />

      <Tabs>
        <div
          text={strings.myRetailer.toUpperCase()}
          style={{
            paddingLeft: 130,
            paddingRight: 130,
            backgroundColor: 'white',
          }}
        >
          <MyReseller />
        </div>
        <div
          text={strings.allRetailers.toUpperCase()}
          style={{ paddingLeft: 50, paddingRight: 50, paddingBottom: 32, backgroundColor: 'white' }}
        >
          <AllResellers />
        </div>
      </Tabs>
    </div>
  )
}
