import React from 'react'
import { withRouter } from 'react-router-dom'
import InputWeb from '../../../../../ui/input/Input.web'
import CustomButtonWeb from '../../../../../ui/custombutton/CustomButton.web'
import strings from '../../../../../res/strings'
import Alert from '../../../../../ui/alert'
import { routes } from '../../../../../routes'
import './AccountSettings.styles.web.scss'

const AccountSettings = (props: any) => {
  return (
    <div className="accountsettings__container">
      <div className="accountsettings__content">
        <InputWeb
          label={strings.email}
          name="email"
          className="accountsettings__input"
          style={{ marginLeft: 'auto' }}
          value={props.email}
          readOnly
        />
        <div className="accountsettings__button-container">
          <CustomButtonWeb
            text={strings.changePassword}
            onClick={() => {
              props.history.push({
                pathname: routes.changepassword,
                state: { changePassword: true },
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default withRouter(AccountSettings)
