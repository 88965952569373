import React, { Component } from 'react'
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../modal/Modal.web'
import './Alert.styles.web.scss'

const config = {
  dialogOpen: false,
  header: '',
  message: undefined,
  positiveText: undefined,
}

export default class Alert extends Component {
  static alert(header: string, message: string, positiveText?: string) {
    config.dialogOpen = message !== undefined
    config.header = header
    config.message = message
    config.positiveText = positiveText
  }

  constructor(props: any) {
    super(props)
    this.state = {
      dialogOpen: false,
    }
  }

  hideAlert() {
    config.dialogOpen = false
    this.setState({ dialogOpen: false })
  }

  render() {
    return (
      <Modal
        handleClose={this.onModalClose}
        show={this.state.dialogOpen || config.dialogOpen}
        customClass="alert__modal-main"
      >
        <ModalHeader text={config.header} onClose={this.onModalClose} />
        <ModalContent>
          <p>{config.message}</p>
        </ModalContent>
        <ModalFooter onSubmit={() => this.hideAlert()} onSubmitText={config.positiveText || 'OK'} />
      </Modal>
    )
  }
}
