/**
 * @Flow
 */
export default {
  login: 'Logga in',
  logout: 'Logga ut',
  email: 'E-postadress',
  password: 'Lösenord',
  start: 'Start',
  vehicle: 'Mitt fordon',
  about: 'Om',
  manuals: 'Manualer',
  inspections: 'Kontroller',
  settings: 'Inställningar',
  myCredentials: 'Mina uppgifter',
  myRetailer: 'Min återförsäljare',
  allRetailers: 'Alla återförsäljare',
  retailers: 'Återförsäljare',
  sideMenuMessages: 'Utskicksmotor',
  messages: 'Meddelanden',
  journey: 'Kundresan',
  forgotPassword: 'Glömt lösenord?',
  noAccount: 'Har du inget konto?',
  accountSettings: 'Kontoinställningar',
  ownerData: 'Kunduppgifter',
  more: 'Mer',
  edit: 'Ändra',
  save: 'Spara',
  register: 'Registrera dig',
  myPage: 'Min sida',
  contact: 'Kontakt',
  sendCode: 'Skicka aktiveringskod',
  linkVehicle: 'Koppla fordon',
  addChassiNumber: 'Ange chassinummer',
  linkVehicleToApp: 'Koppla fordonet till appen genom att få en aktiveringskod skickad via SMS.',
  cancel: 'Avbryt',
  fillInChassiNumber: 'Var vänlig och fyll fordonets chassinummer',
  fillInActivationCode: 'Ange aktiveringskod',
  fiveDigitCodeSent: 'En femsiffrig kod skickades till:',
  canTakeSomeTime: 'det kan ta ett tag för koden att komma fram.',
  activationCode: 'Aktiveringskod',
  verify: 'Verifiera',
  ownerOfVehicleQuestion: 'Äger du ett fordon från KABE?',
  errorAlreadyLinked: 'Detta fordon är tyvärr redan kopplat med en annan användare.',
  errorNoVehicleFound: 'Kunde inte hitta något fordon, kontrollera chassinummer.',
  errorCouldNotSendCode: 'Kunde inte skicka aktiveringskod, vänligen försök igen.',
  errorCouldNotVerifyVehicle: 'Kunde inte verifiera fordon, vänligen försök igen.',
  errorCouldNotVerifyCheckCode: 'Kunde inte verifiera fordon, kontrollera aktiveringskoden.',
  errorCouldNotFetchOwner: 'Kunde inte hämta användaruppgifter, vänligen försök igen.',
  errorCouldNotFetchResllers: 'Kunde inte hämta åtförsäljare, vänligen försök igen.',
  errorCouldNotFetchVehicle: 'Kunde inte hämta fordonsuppgifter, vänligen försök igen.',
  errorCouldNotFetchManuals: 'Kunde inte hämta manualer, vänligen försök igen.',
  errorCouldNotFetchInspections: 'Kunde inte hämta kontrollinspektioner, vänligen försök igen.',
  errorCouldNotFetchNews: 'Kunde inte hämta nyheter, vänligen försök igen.',
  errorNotVerified: 'Problem vid verifiering av fordon.',
  firstname: 'Förnamn',
  lastname: 'Efternamn',
  address: 'Adress',
  nr: 'Nr',
  zipcode: 'Postnr',
  city: 'Ort/Stad',
  country: 'Land',
  language: 'Språk',
  emailaddress: 'E-mailadress',
  cellphone: 'Mobil',
  registrationNumber: 'Registreringsnr',
  chassiNumber: 'Chassinr',
  product: 'Produkt',
  smdRemoteActivatedDate: 'SMD-Remote aktiverad',
  smdRemoteActiveUntilDate: 'SMD-Remote till datum',
  smdActivationCode: 'SMD-Aktiveringskod',
  warrantyStartDate: 'Garantistartdatum',
  acquisitionDate: 'Förvärvsdatum',
  receiveMessages: 'Önskar motta servicemeddelanden och teknikinformation',
  communicateByEmail: 'E-mail',
  communicateBySMS: 'SMS',
  communicationWay: 'Kommunikationsform',
  lastTServiceDate: 'Täthetskontrollintervall fr.o.m',
  nextTServiceDate: 'Täthetskontrollintervall t.o.m',
  lastYServiceDate: 'Årskontrollsintervall fr.o.m',
  nextYServiceDate: 'Årskontrollintervall t.o.m',
  yourReseller: 'Din Återförsäljare',
  telephoneNumber: 'Telefonnummer',
  changeReseller: 'Byt återförsäljare',
  choseReseller: 'Välj återförsäljare',
  saveReseller: 'Spara återförsäljare',
  wholeEurope: 'Hela Europa',
  belgium: 'Belgien',
  denmark: 'Danmark',
  finland: 'Finland',
  netherlands: 'Nederländerna',
  norway: 'Norge',
  switzerland: 'Schweiz',
  sweden: 'Sverige',
  southkorea: 'Sydkorea',
  germany: 'Tyskland',
  austria: 'Österrike',
  search: 'Sök',
  name: 'Namn',
  phone: 'Telefon',
  webpage: 'Hemsida',
  caravanService: 'Husvagn',
  rvService: 'Husbil',
  workshop: 'Service',
  all: 'Alla',
  news: 'Nyheter',
  back: 'Tillbaka',
  changePassword: 'Ändra lösenord',
  noVehicle: 'Inget fordon',
  appVersion: 'Appversion',
  removeVehicle: 'Koppla bort fordon',
  yes: 'Ja',
  verifyRemoveVehicle: 'Är du säker på att du vill koppla bort ditt fordon?',
  passwordChange: 'Lösenordsbyte',
  passwordSuccessfullyChanged: 'Ditt lösenord har nu ändrats.',
  noInternetConnection: 'Ingen internetanslutning',
  internetConnectionRequired:
    'Denna app kräver en internetanslutning för att logga in. Efter att du har loggat in kan du använda appen offline.',
}
