/* eslint-disable import/no-mutable-exports */
import { combineReducers } from 'redux'
import userReducer from './UserReducer'
import VehicleReducer from './VehicleReducer'
import NewsReducer from './NewsReducer'
import MenuReducer from './MenuReducer'
import OwnerReducer from './OwnerReducer'
import MessageReducer from './MessageReducer'
import ResellerReducer from './ResellerReducer'
import ManualReducer from './ManualReducer'
import InspectionsReducer from './InspectionsReducer'
import UserMessageReducer from './UserMessageReducer'
import RoleReducer from './RoleReducer'

export default combineReducers({
  user: userReducer,
  vehicle: VehicleReducer,
  news: NewsReducer,
  menu: MenuReducer,
  owner: OwnerReducer,
  message: MessageReducer,
  reseller: ResellerReducer,
  manuals: ManualReducer,
  inspections: InspectionsReducer,
  usermessagereducers: UserMessageReducer,
  role: RoleReducer,
})
