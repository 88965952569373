/* eslint-disable import/no-named-default */
import { Platform } from 'react-native'
import authentication from 'react-azure-adb2c'
import authContext from '../adalConfig'

export const CLIENT_ID = '97dbddcd-6fb2-4e74-af95-817d6e944f0b'
export const CLIENT_ID_ADMIN = '693cceb0-8b31-4993-a95a-dcde1177ee51'

export const getToken = () => {
  return new Promise(resolve => {
    if (Platform.OS === 'web') {
      resolve(authentication.getAccessToken())
    }
  })
}

export const getAdminToken = () => {
  return new Promise(resolve => {
    if (Platform.OS === 'web') {
      const token = authContext.getCachedToken(CLIENT_ID_ADMIN)
      if (!token) {
        window.location.reload()
      }

      resolve(token)
    }
  })
}
