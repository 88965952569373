import {
  FETCHING_MESSAGES,
  FETCHING_MESSAGES_FAILURE,
  FETCHING_MESSAGES_SUCCESS,
  FETCHING_MESSAGE_ITEM,
  FETCHING_MESSAGE_ITEM_FAILURE,
  FETCHING_MESSAGE_ITEM_SUCCESS,
  MARK_MESSAGE_AS_READ,
  MARK_MESSAGE_AS_READ_SUCCESS,
  MARK_MESSAGE_AS_READ_FAILURE,
} from '../types/UserMessageTypes'

const initialState = {
  messageItem: {},
  messages: [],
  fetchMessagesLoading: false,
  messageRead: false,
  amountOfUnreadMessages: undefined,
  error: undefined,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case MARK_MESSAGE_AS_READ:
      return {
        ...state,
        fetchMessagesLoading: true,
        messageRead: false,
      }

    case MARK_MESSAGE_AS_READ_SUCCESS:
      return {
        ...state,
        fetchMessagesLoading: false,
        messageRead: true,
        error: undefined,
      }

    case MARK_MESSAGE_AS_READ_FAILURE:
      return {
        ...state,
        fetchMessagesLoading: false,
        fetchMessagesError: action.payload,
        messageRead: false,
        error: action.data,
      }

    case FETCHING_MESSAGE_ITEM:
      return {
        ...state,
        fetchMessagesLoading: true,
      }

    case FETCHING_MESSAGE_ITEM_SUCCESS:
      return {
        ...state,
        fetchMessagesLoading: false,
        messageItem: action.data,
        error: undefined,
      }

    case FETCHING_MESSAGE_ITEM_FAILURE:
      return {
        ...state,
        fetchMessagesLoading: false,
        fetchMessagesError: action.payload,
        error: action.data,
      }

    case FETCHING_MESSAGES:
      return {
        ...state,
        fetchMessagesLoading: true,
      }

    case FETCHING_MESSAGES_SUCCESS:
      return {
        ...state,
        fetchMessagesLoading: false,
        messages: action.data,
        amountOfUnreadMessages: action.data.filter(message => !message.read).length,
        error: undefined,
      }

    case FETCHING_MESSAGES_FAILURE:
      return {
        ...state,
        fetchMessagesLoading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
