/* eslint-disable no-console */

import NetworkHandler from '../../utility/networkhandler/NetworkHandler'
import {
  FETCHING_NEWS,
  FETCHING_NEWS_SUCCESS,
  FETCHING_NEWS_FAILURE,
  FETCHING_NEWS_ARTICLE,
  FETCHING_NEWS_ARTICLE_SUCCESS,
  FETCHING_NEWS_ARTICLE_FAILURE,
  RESET_NEWS_ARTICLE,
} from '../types/NewsTypes'

export const fetchNews = () => dispatch => {
  dispatch(getNews())

  NetworkHandler.get('/api/News')
    .then(response => {
      dispatch(getNewsSuccess(response.data))
    })
    .catch(error => {
      console.log(`NewsActions.js, Error: ${error.response?.status}`)
      dispatch(getNewsFailure(error.response))
    })
}

export const fetchNewsArticle = (id: string, resetNewsArticle?: boolean) => dispatch => {
  dispatch(getNewsArticle())

  if (resetNewsArticle) {
    dispatch(getResetNewsArticle())
  }

  NetworkHandler.get(`/api/News/${id}`)
    .then(response => {
      dispatch(getNewsArticleSuccess(response.data))
    })
    .catch(error => {
      console.log(`NewsActions.js, Error: ${error.response?.status}`)
      dispatch(getNewsArticleFailure(error.response))
    })
}

function getNews() {
  return {
    type: FETCHING_NEWS,
  }
}

function getNewsSuccess(data) {
  return {
    type: FETCHING_NEWS_SUCCESS,
    data,
  }
}

function getNewsFailure(error) {
  return {
    type: FETCHING_NEWS_FAILURE,
    payload: error,
  }
}

function getNewsArticle() {
  return {
    type: FETCHING_NEWS_ARTICLE,
  }
}

function getNewsArticleSuccess(data) {
  return {
    type: FETCHING_NEWS_ARTICLE_SUCCESS,
    data,
  }
}

function getNewsArticleFailure(error) {
  return {
    type: FETCHING_NEWS_ARTICLE_FAILURE,
    payload: error,
  }
}

function getResetNewsArticle() {
  return {
    type: RESET_NEWS_ARTICLE,
  }
}
