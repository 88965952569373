/**
 * @Flow
 */

import React from 'react'
import colors from '../../res/style/colors'
import './Badge.styles.web.scss'

export default (props: any) => (
  <div className="badge__container">
    <p className="badge__item">{props.number}</p>
  </div>
)
