import React, { Component } from 'react'
import './Tab.styles.web.scss'

type TabPropsType = {
  onClick: (tab: string) => void,
  active: boolean,
  text: string,
  icon: any,
}

export default class Tab extends Component<TabPropsType> {
  onClick = () => {
    this.props.onClick(this.props.text)
  }

  render() {
    const className = this.props.active
      ? 'tab-list-item-button tab-list-item-active'
      : 'tab-list-item-button'
    return (
      <li className="tab-list-item">
        <button onClick={this.onClick} type="button" className={className}>
          {this.props.text}
          {this.props.icon}
        </button>
      </li>
    )
  }
}
