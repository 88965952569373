/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */

import axios from 'axios'
import NetworkHandler from '../../utility/networkhandler/NetworkHandler'
import store from '../store'
import { ManualType } from '../../types/ManualType'
import { baseUrl } from '../../appconfig'
import { getAdminToken } from '../../appconfig/appconfig'
import {
  INIT_MANUAL,
  INIT_MANUAL_SUCCESS,
  INIT_MANUAL_FAILURE,
  UPLOAD_MANUAL,
  UPLOAD_MANUAL_SUCCESS,
  UPLOAD_MANUAL_FAILURE,
  GET_ALL_MANUALS,
  GET_ALL_MANUALS_SUCCESS,
  GET_ALL_MANUALS_FAILURE,
  GET_MANUAL,
  GET_MANUAL_SUCCESS,
  GET_MANUAL_FAILURE,
  UPDATE_MANUAL,
  UPDATE_MANUAL_SUCCESS,
  UPDATE_MANUAL_FAILURE,
  DELETE_MANUAL,
  DELETE_MANUAL_SUCCESS,
  DELETE_MANUAL_FAILURE,
  OPEN_DIALOG,
  CLOSE_DIALOG,
  OPEN_DETAIL_DIALOG,
  CLOSE_DETAIL_DIALOG,
  UPDATE_MANUALS,
  FETCHING_MANUALS,
  FETCHING_MANUALS_SUCCESS,
  FETCHING_MANUALS_FAILURE,
} from '../types/ManualTypes'

export const initManual = () => dispatch => {
  dispatch(getInitManual())

  getAdminToken().then(token => {
    const authHeader = `Bearer ${token}`

    axios
      .get(`${baseUrl}/api/manual/init`, {
        headers: { Authorization: authHeader },
      })
      .then(response => {
        dispatch(getInitManualSuccess(response.data))
      })
      .catch(error => {
        dispatch(getInitManualFailure(error.response))
      })
  })
}

export const uploadManual = (state: any) => dispatch => {
  dispatch(getUploadManual())

  getAdminToken().then(token => {
    const authHeader = `Bearer ${token}`

    const formData = new FormData()
    state.files.forEach(file => formData.append('files', file))
    formData.append('dto', JSON.stringify({ vehicleTypeId: state.vehicleTypeId, year: state.year }))

    axios
      .post(`${baseUrl}/api/manual`, formData, {
        headers: { Authorization: authHeader, 'content-type': 'multipart/form-data' },
      })
      .then(response => {
        dispatch(getUploadManualSuccess(response.data))
      })
      .catch(error => {
        dispatch(getUploadManualFailure(error.response))
      })
  })
}

export const getManuals = () => dispatch => {
  dispatch(getAllManuals())

  getAdminToken().then(token => {
    const authHeader = `Bearer ${token}`

    axios
      .get(`${baseUrl}/api/manual`, {
        headers: { Authorization: authHeader },
      })
      .then(response => {
        dispatch(getAllManualsSuccess(response.data))
      })
      .catch(error => {
        dispatch(getAllManualsFailure(error.response))
      })
  })
}

export const getManual = (id: string) => dispatch => {
  dispatch(getSingleManual())

  getAdminToken().then(token => {
    const authHeader = `Bearer ${token}`

    axios
      .get(`${baseUrl}/api/manual/${id}`, {
        headers: { Authorization: authHeader },
      })
      .then(response => {
        dispatch(getSingleManualSuccess(response.data))
      })
      .catch(error => {
        dispatch(getSingleManualFailure(error.response))
      })
  })
}

export const updateManual = (id: string, state: any) => dispatch => {
  dispatch(getUpdateManual())

  getAdminToken().then(token => {
    const authHeader = `Bearer ${token}`

    axios
      .put(
        `${baseUrl}/api/manual/${id}`,
        { vehicleTypeId: state.vehicleTypeId, year: state.year },
        { headers: { Authorization: authHeader, 'content-type': 'application/json' } }
      )
      .then(response => {
        dispatch(getUpdateManualSuccess(response.data))
      })
      .catch(error => {
        dispatch(getUpdateManualFailure(error.response))
      })
  })
}

export const deleteManual = (id: string) => dispatch => {
  dispatch(getDeleteManual())

  getAdminToken().then(token => {
    const authHeader = `Bearer ${token}`

    axios
      .delete(`${baseUrl}/api/manual/${id}`, {
        headers: { Authorization: authHeader },
      })
      .then(response => {
        dispatch(getDeleteManualSuccess(id))
      })
      .catch(error => {
        dispatch(getDeleteManualFailure(error.response))
      })
  })
}

export const fetchManuals = () => dispatch => {
  dispatch(getFetchManuals())
  NetworkHandler.get('/api/Manual')
    .then(response => {
      const manuals = response ? response.data : store.getState().manuals.manuals
      dispatch(getFetchManualsSuccess(manuals))
    })
    .catch(error => {
      console.log(`ManualActions.js, Error: ${error.response?.status}`)
      switch (error.response?.status) {
        default:
          dispatch(getFetchManualsFailure(error.response))
          break
      }
    })
}

export const openDialog = () => dispatch => {
  dispatch(getOpenDialog())
}

export const closeDialog = () => dispatch => {
  dispatch(getCloseDialog())
}

export const openDetailDialog = () => dispatch => {
  dispatch(getOpenDetailDialog())
}

export const closeDetailDialog = () => dispatch => {
  dispatch(getCloseDetailDialog())
}

export const updateManuals = manuals => dispatch => {
  dispatch(getUpdateManuals(manuals))
}

function getInitManual() {
  return {
    type: INIT_MANUAL,
  }
}

function getInitManualSuccess(data) {
  return {
    type: INIT_MANUAL_SUCCESS,
    data,
  }
}

function getInitManualFailure(error) {
  return {
    type: INIT_MANUAL_FAILURE,
    payload: error,
  }
}

function getUploadManual() {
  return {
    type: UPLOAD_MANUAL,
  }
}

function getUploadManualSuccess(data) {
  return {
    type: UPLOAD_MANUAL_SUCCESS,
    data,
  }
}

function getUploadManualFailure(error) {
  return {
    type: UPLOAD_MANUAL_FAILURE,
    payload: error,
  }
}

function getAllManuals() {
  return {
    type: GET_ALL_MANUALS,
  }
}

function getAllManualsSuccess(data) {
  return {
    type: GET_ALL_MANUALS_SUCCESS,
    data,
  }
}

function getFetchManuals() {
  return {
    type: FETCHING_MANUALS,
  }
}

function getFetchManualsSuccess(data: ManualType) {
  return {
    type: FETCHING_MANUALS_SUCCESS,
    payload: data,
  }
}

function getAllManualsFailure(error) {
  return {
    type: GET_ALL_MANUALS_FAILURE,
    payload: error,
  }
}

function getSingleManual() {
  return {
    type: GET_MANUAL,
  }
}

function getSingleManualSuccess(data) {
  return {
    type: GET_MANUAL_SUCCESS,
    data,
  }
}

function getSingleManualFailure(error) {
  return {
    type: GET_MANUAL_FAILURE,
    payload: error,
  }
}

function getUpdateManual() {
  return {
    type: UPDATE_MANUAL,
  }
}

function getUpdateManualSuccess(data) {
  return {
    type: UPDATE_MANUAL_SUCCESS,
    data,
  }
}

function getUpdateManualFailure(error) {
  return {
    type: UPDATE_MANUAL_FAILURE,
    payload: error,
  }
}

function getDeleteManual() {
  return {
    type: DELETE_MANUAL,
  }
}

function getDeleteManualSuccess(id) {
  return {
    type: DELETE_MANUAL_SUCCESS,
    data: id,
  }
}

function getDeleteManualFailure(error) {
  return {
    type: DELETE_MANUAL_FAILURE,
    payload: error,
  }
}

function getOpenDialog() {
  return {
    type: OPEN_DIALOG,
  }
}

function getCloseDialog() {
  return {
    type: CLOSE_DIALOG,
  }
}

function getOpenDetailDialog() {
  return {
    type: OPEN_DETAIL_DIALOG,
  }
}

function getCloseDetailDialog() {
  return {
    type: CLOSE_DETAIL_DIALOG,
  }
}

function getUpdateManuals(manuals) {
  return {
    type: UPDATE_MANUALS,
    manuals,
  }
}

function getFetchManualsFailure(error) {
  return {
    type: FETCHING_MANUALS_FAILURE,
    payload: error,
  }
}
