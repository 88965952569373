/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @Flow
 */
import React from 'react'
import strings from '../../../../res/strings'
import Alert from '../../../../ui/alert/Alert.web'
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../../../ui/modal/Modal.web'
import './VerifyVehicle.styles.web.scss'

export default (props: any) => (
  <div className="verifyvehicle__container">
    <Modal handleClose={props.onClose} show customClass="linkvehicle__modal-main">
      <React.Fragment>
        <ModalHeader>
          <p className="modal__custom-header">{strings.fillInActivationCode.toUpperCase()}</p>
        </ModalHeader>
        <ModalContent>
          <Alert />
          <div className="verifyvehicle__input-container">
            <p className="verifyvehicle__text" style={{ marginBottom: 4, marginTop: 32 }}>
              {strings.fiveDigitCodeSent}
            </p>
            <p className="verifyvehicle__email">{props.email}</p>
            <p style={{ marginBottom: 32 }}>{strings.canTakeSomeTime}</p>
            <input
              className="verifyvehicle__input"
              placeholder={strings.activationCode}
              onChange={event => props.setVerificationCode(event.target.value)}
              value={props.verificationCode}
              maxLength={5}
              onKeyPress={e => {
                if (props.verificationCode.length >= 5 && e.key === 'Enter') {
                  props.verifyVehicle()
                }
              }}
            />
          </div>
        </ModalContent>
        <ModalFooter
          isLoading={props.isLoading}
          onCancel={() => props.goBack()}
          onCancelText={strings.cancel.toUpperCase()}
          onSubmit={() => props.verifyVehicle()}
          onSubmitText={strings.verify.toUpperCase()}
          submitDisabled={props.verificationCode.length < 5}
        />
      </React.Fragment>
    </Modal>
  </div>
)
