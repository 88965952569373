/**
 * @Flow
 */

import React from 'react'
import Alert from '../../../ui/alert'
import Loader from '../../../ui/loader/Loader.web'
import OwnerData from './ownerdata/OwnerData.web'
import './Owner.styles.web.scss'

export default (props: any) => {
  const { owner, edit } = props
  return (
    <div className={props.isLoading ? 'owner__container --loading' : 'owner__container'}>
      <Alert />
      <Loader />

      <div text="KUNDUPPGIFTER">
        <OwnerData
          onInputChange={e => props.onInputChange(e)}
          editForm={() => props.editForm()}
          saveForm={() => props.saveForm()}
          edit={edit}
          owner={owner}
        />
      </div>
    </div>
  )
}
