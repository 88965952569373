export const FETCHING_MY_RESELLER = 'FETCHING_MY_RESELLER'
export const FETCHING_MY_RESELLER_SUCCESS = 'FETCHING_MY_RESELLER_SUCCESS'
export const FETCHING_MY_RESELLER_FAILURE = 'FETCHING_MY_RESELLER_FAILURE'
export const FETCHING_RESELLERS = 'FETCHING_RESELLERS'
export const FETCHING_RESELLERS_SUCCESS = 'FETCHING_RESELLERS_SUCCESS'
export const FETCHING_RESELLERS_FAILURE = 'FETCHING_RESELLERS_FAILURE'

export const SELECT_RESELLER = 'SELECT_RESELLER'

export const POSTING_FAVORITE_RESELLER = 'POSTING_FAVORITE_RESELLER'
export const POSTING_FAVORITE_RESELLER_SUCCESS = 'POSTING_FAVORITE_RESELLER_SUCCESS'
export const POSTING_FAVORITE_RESELLER_FAILURE = 'POSTING_FAVORITE_RESELLER_FAILURE'
