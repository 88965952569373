import React, { Component } from 'react'

import './Dropdown.styles.web.scss'

type DropdownPropsType = {
  placeholder: string,
  items: DropdownItem[],
  value: String,
  onChange: (value: string) => void,
}

export type DropdownItem = {
  id: string,
  name: string,
}

export default class Dropdown extends Component<DropdownPropsType> {
  onChange = event => {
    if (this.props.onChange) {
      this.props.onChange(event.target.value)
    }
  }

  render() {
    return (
      <div className="kabe-select-container">
        <select className="kabe-select" onChange={this.onChange} value={this.props.value}>
          {this.props.items
            ? this.props.items.map(item => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                )
              })
            : undefined}
        </select>
        <span className="kabe-select-placeholder">{this.props.placeholder}</span>
      </div>
    )
  }
}
