/**
 * @Flow
 */
import React from 'react'
import { withRouter } from 'react-router-dom'
import CustomButton from '../../../../ui/custombutton/CustomButton.web'
import strings from '../../../../res/strings'
import { routes } from '../../../../routes'
import './NoVehicle.styles.web.scss'

const NoVehicle = (props: any) => {
  function navigate() {
    props.history.push(routes.linkvehicle)
  }

  return (
    <div className="novehicle__container">
      <p className="novehicle__header">{strings.ownerOfVehicleQuestion}</p>
      <p className="novehicle__text">{strings.linkVehicleToApp}</p>
      <CustomButton
        text={strings.linkVehicle}
        onClick={() => navigate()}
        isLoading={props.isLoading}
      />
    </div>
  )
}
export { NoVehicle }
export default withRouter(NoVehicle)
