import React, { Component } from 'react'
import './Textarea.styles.web.scss'

type TextareaPropsType = {
  placeholder: string,
  rows: string,
  value: string,
  name: string,
  onChange: (value: string) => void,
}

export default class Textarea extends Component<TextareaPropsType> {
  onChange = event => {
    this.props.onChange(event.target.value)
  }

  render() {
    return (
      <div className="kabe-textarea-container">
        <textarea
          name={this.props.name}
          className="kabe-textarea"
          rows={this.props.rows}
          onChange={this.props.onChange}
          value={this.props.value}
        />
        <span className="kabe-textarea-placeholder">{this.props.placeholder}</span>
      </div>
    )
  }
}
