/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/**
 * @Flow
 */
import React from 'react'
import authentication from 'react-azure-adb2c'
import storage from 'redux-persist/lib/storage'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { toggleMenu } from '../../redux/actions/MenuActions'
import { routes } from '../../routes'
import StartIcon from '../../res/images/ic_home.svg'
import MyPagesIcon from '../../res/images/ic_user.svg'
import VehicleIcon from '../../res/images/ic_key.svg'
import RetailersIcon from '../../res/images/ic_retailers.svg'
import SettingsIcon from '../../res/images/ic_settings.svg'
import LogoutIcon from '../../res/images/ic_shutdown.svg'
import MessageIcon from '../../res/images/ic_message.svg'
import JourneyIcon from '../../res/images/ic_journey.svg'
import ManualIcon from '../../res/images/ic_manual.svg'
import authContext from '../../adalConfig.web'
import strings from '../../res/strings'
import './SideMenu.styles.web.scss'

// import console = require('console');

type SideMenuProps = {
  role: string,
}

type SideMenuState = {
  selectedRoute: string,
}

class SideMenu extends React.Component<SideMenuProps, SideMenuState> {
  static logout() {
    authentication.signOut()
    storage.removeItem('persist:user')
    storage.removeItem('persist:vehicle')
  }

  static logoutAdmin() {
    authContext.logOut()
  }

  constructor(props: any) {
    super(props)
    this.state = {
      selectedRoute: props.location.pathname,
    }
  }

  getNavBar(classname) {
    return (
      <nav className={this.props.menuOpen ? `${classname} --open` : classname}>
        <div className="sidemenu__top">
          <Link
            to={routes.start}
            className={
              this.state.selectedRoute === '/' ||
              this.state.selectedRoute === routes.start ||
              (this.state.selectedRoute && this.state.selectedRoute.startsWith(routes.newsArticle))
                ? 'header__link --selected'
                : 'header__link'
            }
            onClick={() => this.clickLink(routes.start)}
          >
            <img className="sidemenu__icon" src={StartIcon} alt="start-icon" />
            <p className="sidemenu__label">{strings.start.toUpperCase()}</p>
          </Link>

          {this.props.user !== undefined && (
            <Link
              to={routes.mypage}
              className={
                this.state.selectedRoute === routes.mypage
                  ? 'header__link --selected'
                  : 'header__link'
              }
              onClick={() => this.clickLink(routes.mypage)}
            >
              <img className="sidemenu__icon" src={MyPagesIcon} alt="start-icon" />
              <p className="sidemenu__label">{strings.myCredentials.toUpperCase()}</p>
            </Link>
          )}

          <Link
            to={routes.vehicle}
            className={
              this.state.selectedRoute === routes.vehicle ||
              this.state.selectedRoute === routes.linkvehicle ||
              this.state.selectedRoute === routes.verifyvehicle
                ? 'header__link --selected'
                : 'header__link'
            }
            onClick={() => this.clickLink(routes.vehicle)}
          >
            <img className="sidemenu__icon" src={VehicleIcon} alt="start-icon" />
            <p className="sidemenu__label">{strings.vehicle.toUpperCase()}</p>
          </Link>

          <Link
            to={routes.retailers}
            className={
              this.state.selectedRoute === routes.retailers
                ? 'header__link --selected'
                : 'header__link'
            }
            onClick={() => this.clickLink(routes.retailers)}
          >
            <img className="sidemenu__icon" src={RetailersIcon} alt="start-icon" />
            <p className="sidemenu__label">{strings.retailers.toUpperCase()}</p>
          </Link>
        </div>

        <div className="sidemenu__bottom">
          <Link
            to={routes.settings}
            className={
              this.state.selectedRoute === routes.settings
                ? 'header__link --selected'
                : 'header__link'
            }
            onClick={() => this.clickLink(routes.settings)}
          >
            <img className="sidemenu__icon" src={SettingsIcon} alt="start-icon" />
            <p className="sidemenu__label">{strings.settings.toUpperCase()}</p>
          </Link>

          <div className="header__link" onClick={() => SideMenu.logout()} role="button">
            <img className="sidemenu__icon" src={LogoutIcon} alt="start-icon" />
            <p className="sidemenu__label">{strings.logout.toUpperCase()}</p>
          </div>
        </div>
      </nav>
    )
  }

  getNavBarAdmin(classname) {
    return (
      <nav className={this.props.menuOpen ? `${classname} --open` : classname}>
        <div className="sidemenu__top">
          {(this.props.role === 'admin' || this.props.role === 'reseller') && (
            <React.Fragment>
              <Link
                to={routes.messages}
                className={
                  this.state.selectedRoute === routes.messages ||
                  this.state.selectedRoute === '/admin'
                    ? 'header__link --selected'
                    : 'header__link'
                }
                onClick={() => this.clickLink(routes.messages)}
              >
                <img className="sidemenu__icon" src={MessageIcon} alt="message-icon" />
                <p className="sidemenu__label">{strings.sideMenuMessages.toUpperCase()}</p>
              </Link>

              <Link
                to={routes.journey}
                className={
                  this.state.selectedRoute === routes.journey
                    ? 'header__link --selected'
                    : 'header__link'
                }
                onClick={() => this.clickLink(routes.journey)}
              >
                <img className="sidemenu__icon" src={JourneyIcon} alt="journey-icon" />
                <p className="sidemenu__label">{strings.journey.toUpperCase()}</p>
              </Link>
              {this.props.role === 'admin' && (
                <Link
                  to={routes.manuals}
                  className={
                    this.state.selectedRoute === routes.manuals
                      ? 'header__link --selected'
                      : 'header__link'
                  }
                  onClick={() => this.clickLink(routes.manuals)}
                >
                  <img className="sidemenu__icon" src={ManualIcon} alt="manual-icon" />
                  <p className="sidemenu__label">{strings.manuals.toUpperCase()}</p>
                </Link>
              )}
            </React.Fragment>
          )}
        </div>

        <div className="sidemenu__bottom">
          <div className="header__link" onClick={() => SideMenu.logoutAdmin()} role="button">
            <img className="sidemenu__icon" src={LogoutIcon} alt="start-icon" />
            <p className="sidemenu__label">{strings.logout.toUpperCase()}</p>
          </div>
        </div>
      </nav>
    )
  }

  clickLink(route) {
    this.setState({ selectedRoute: route })
    this.props.toggleMenu(false)
  }

  render() {
    return this.state.selectedRoute && this.state.selectedRoute.startsWith('/admin')
      ? this.getNavBarAdmin('header__nav')
      : this.getNavBar('header__nav')
  }
}

export const SideMenuWithRouter = withRouter(SideMenu)

const mapStateToProps = state => ({
  menuOpen: state.menu.open,
  user: state.user.user,
})

export default connect(
  mapStateToProps,
  { toggleMenu }
)(SideMenuWithRouter)
