/**
 * @Flow
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  fetchMyReseller,
  selectReseller,
  setFavoriteReseller,
} from '../../../../redux/actions/ResellerActions'
import ResellerType from '../../../../types/ResellerType'
import strings from '../../../../res/strings'
import MyReseller from './MyReseller'
import Alert from '../../../../ui/alert'

type MyResellerContainerPropsType = {
  reseller: ResellerType,
  selectedRow: ResellerType,
  isLoading: boolean,
  error: {
    status: number,
    headers: {
      date: Date,
    },
  },
  fetchMyReseller: () => void,
  selectReseller: (index: any, row: any) => void,
  setFavoriteReseller: (id: string) => void,
}

type MyResellerContainerStateType = {
  modalOpen: false,
}

class MyResellerContainer extends Component<
  MyResellerContainerPropsType,
  MyResellerContainerStateType
> {
  constructor(props: any) {
    super(props)
    this.state = {
      modalOpen: false,
    }
  }

  componentWillMount() {
    this.props.fetchMyReseller()
  }

  componentWillReceiveProps(nextProps) {
    this.handleErrors(nextProps)
  }

  static navigationOptions = {
    title: strings.myRetailer,
  }

  handleErrors(nextProps) {
    if (nextProps.error && this.props.error) {
      if (nextProps.error.headers.date === this.props.error.headers.date) {
        return
      }
    }
    if (nextProps.error) {
      switch (nextProps.error.status) {
        case 400:
        case 404:
          break
        case 500:
          Alert.alert(`Error: ${nextProps.error.status}`, strings.errorCouldNotFetchResellers)
          break
        default:
          Alert.alert(`${nextProps.error.status}`, undefined)
          break
      }
    }
  }

  submit() {
    const { id } = this.props.selectedRow
    this.props.setFavoriteReseller(id)
    this.setState({ modalOpen: false })
  }

  closeModal() {
    this.setState({
      modalOpen: false,
    })
    if (this.props.reseller === undefined) {
      this.props.selectReseller(undefined)
    } else {
      this.props.selectReseller(this.props.reseller)
    }
  }

  openModal() {
    this.setState({ loadModal: true, modalOpen: true })
  }

  render() {
    const { reseller, isLoading } = this.props
    return (
      <MyReseller
        reseller={reseller}
        isLoading={isLoading}
        loadModal={this.state.loadModal}
        modalOpen={this.state.modalOpen}
        openModal={() => this.openModal()}
        closeModal={() => this.closeModal()}
        submit={() => this.submit()}
      />
    )
  }
}

const mapStateToProps = state => ({
  reseller: state.reseller.myreseller,
  selectedRow: state.reseller.selectedRow,
  isLoading: state.reseller.isLoading,
  error: state.reseller.myresellererror,
})

export default connect(
  mapStateToProps,
  { fetchMyReseller, selectReseller, setFavoriteReseller }
)(MyResellerContainer)
