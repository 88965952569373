import React from 'react'
import { withRouter } from 'react-router-dom'
import { routes } from '../../../../../../routes'

const ChangePassword = (props: any) => {
  props.history.push(routes.settings)
  return <div style={divStyle} />
}

const divStyle = {
  flex: 1,
  display: 'flex',
  backgroundColor: 'white',
  justifyContent: 'center',
  alignItems: 'center',
}

export default withRouter(ChangePassword)
