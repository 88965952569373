/**
 * @Flow
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Alert from '../../../ui/alert'
import News from './news'
import Messages from './messages'
import Tabs from '../../../ui/tabs/Tabs.web'
import { fetchMessages } from '../../../redux/actions/UserMessageActions'
import strings from '../../../res/strings'
import BadgeWeb from '../../../ui/badge/Badge.web'
import './Feed.styles.web.scss'

export class FeedClass extends Component<any> {
  componentWillMount() {
    this.props.fetchMessages()
  }

  render() {
    const { state } = this.props.location
    const redirectToMessages = state?.state?.messages
    const showBadge =
      this.props.amountOfUnreadMessages !== undefined && this.props.amountOfUnreadMessages !== 0
    return (
      <div className="feed__container">
        <Alert />

        <Tabs activeTabIndex={redirectToMessages && 1}>
          <div
            text={strings.news.toUpperCase()}
            style={{
              paddingLeft: 130,
              paddingRight: 130,
              backgroundColor: 'white',
            }}
          >
            <News />
          </div>
          <div
            text={strings.messages.toUpperCase()}
            icon={showBadge ? <BadgeWeb number={this.props.amountOfUnreadMessages} /> : undefined}
            style={{ paddingLeft: 130, paddingRight: 130, backgroundColor: 'white' }}
          >
            <Messages />
          </div>
        </Tabs>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  amountOfUnreadMessages: state.usermessagereducers.amountOfUnreadMessages,
})

export default connect(
  mapStateToProps,
  { fetchMessages }
)(FeedClass)
