import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import {
  FETCHING_INSPECTIONS,
  FETCHING_INSPECTIONS_SUCCESS,
  FETCHING_INSPECTIONS_FAILURE,
} from '../types/InspectionsTypes'

const initialState = {
  inspections: undefined,
  isLoading: false,
  error: undefined,
}

const InspectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_INSPECTIONS:
      return {
        ...state,
        isLoading: true,
      }

    case FETCHING_INSPECTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        inspections: action.data,
      }

    case FETCHING_INSPECTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

const persistConfig = {
  key: 'inspections',
  storage,
  whitelist: ['inspections'],
}

export default persistReducer(persistConfig, InspectionsReducer)
