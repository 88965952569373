import React, { Component } from 'react'
import './Modal.styles.web.scss'
import StandardButton from '../standardbutton/StandardButton.web'

type DialogPropsType = {
  show: boolean,
  children: React.Node,
  customClass: string,
}

type HeaderPropsType = {
  text: String,
  children: React.Node,
  onClose: () => void,
}

type ContentPropsType = {
  children: React.Node,
}

type FooterPropsType = {
  onSubmit: () => void,
  onCancel: () => void,
  onSubmitText: string,
  onCancelText: string,
  submitDisabled: boolean,
  isLoading: boolean,
}

const ModalHeader = (props: HeaderPropsType) => {
  return (
    <div className="modal-header">
      {props.text}
      {props.children}
      {props.onClose && (
        <button type="button" onClick={props.onClose} className="modal-header-close">
          X
        </button>
      )}
    </div>
  )
}

const ModalContent = (props: ContentPropsType) => {
  return <div className="modal-content">{props.children}</div>
}

const ModalFooter = (props: FooterPropsType) => {
  return (
    <div className="modal-footer">
      {props.onCancel && (
        <StandardButton onClick={props.onCancel} text={props.onCancelText} secondary />
      )}
      {props.onSubmit && (
        <StandardButton
          onClick={props.onSubmit}
          text={props.onSubmitText}
          disabled={props.submitDisabled}
          isLoading={props.isLoading}
        />
      )}
    </div>
  )
}

export default class Modal extends Component<DialogPropsType> {
  componentDidMount() {
    const modal = document.getElementsByClassName('modal')[0]
    if (modal !== undefined) {
      document.body.appendChild(modal)
    }
  }

  componentWillUnmount() {
    const modal = document.getElementsByClassName('modal')[0]
    if (modal !== undefined) {
      document.body.removeChild(modal)
    }
  }

  render() {
    return (
      <div className={this.props.show ? 'modal show' : 'modal hide'}>
        <div className={`modal-main ${this.props.customClass ? this.props.customClass : ''}`}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export { ModalHeader, ModalContent, ModalFooter }
