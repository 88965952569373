import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import {
  FETCHING_MY_RESELLER,
  FETCHING_MY_RESELLER_SUCCESS,
  FETCHING_MY_RESELLER_FAILURE,
  FETCHING_RESELLERS,
  FETCHING_RESELLERS_SUCCESS,
  FETCHING_RESELLERS_FAILURE,
  SELECT_RESELLER,
  POSTING_FAVORITE_RESELLER,
  POSTING_FAVORITE_RESELLER_SUCCESS,
  POSTING_FAVORITE_RESELLER_FAILURE,
} from '../types/ResellerTypes'

const initialState = {
  myreseller: undefined,
  resellers: undefined,
  isLoading: false,
  error: undefined,
  myresellererror: undefined,
  selectedRow: undefined,
}

const ResellerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_RESELLER:
      return {
        ...state,
        selectedRow: state.selectedRow === action.row ? undefined : action.row,
      }

    case FETCHING_RESELLERS:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      }

    case FETCHING_RESELLERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        resellers: action.data,
      }

    case FETCHING_RESELLERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case FETCHING_MY_RESELLER:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      }

    case FETCHING_MY_RESELLER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        myresellererror: undefined,
        myreseller: action.data,
        selectedRow: action.data,
      }

    case FETCHING_MY_RESELLER_FAILURE:
      return {
        ...state,
        isLoading: false,
        myresellererror: action.payload,
      }

    case POSTING_FAVORITE_RESELLER:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      }

    case POSTING_FAVORITE_RESELLER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        myreseller: action.data,
      }

    case POSTING_FAVORITE_RESELLER_FAILURE:
      return {
        ...state,
        isLoading: false,
        myresellererror: action.payload,
      }

    default:
      return state
  }
}

const persistConfig = {
  key: 'reseller',
  storage,
  blacklist: ['error', 'isLoading'],
}

export default persistReducer(persistConfig, ResellerReducer)
