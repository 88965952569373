import React, { Component } from 'react'
import { Platform } from 'react-native'
import { connect } from 'react-redux'
import { fetchMessages } from '../../../../redux/actions/UserMessageActions'
import { fetchUser } from '../../../../redux/actions/UserActions'
import Messages from './Messages'
import strings from '../../../../res/strings'
import Alert from '../../../../ui/alert'
import Badge from '../../../../ui/badge/Badge.native'

type MessagePropTypes = {
  fetchMessages: () => void,
  fetchUser: () => void,
  messages: [],
  isLoading: boolean,
  amountOfUnreadMessages: number,
  navigation: {
    navigate: () => void,
    setParams: () => void,
  },
  history: {},
  error: any,
}

export class MessageContainer extends Component<MessagePropTypes> {
  static navigationOptions = ({ navigation }) => {
    const amount = navigation.state.params?.amountOfUnreadMessages
    const showBadge = amount !== undefined && amount !== 0
    const badge = showBadge ? <Badge number={amount} /> : undefined
    return {
      title: strings.messages,
      tabBarIcon: badge,
    }
  }

  componentWillMount() {
    this.props.fetchMessages()
  }

  componentWillReceiveProps(nextProps) {
    this.handleErrors(nextProps)
    if (this.props.amountOfUnreadMessages !== nextProps.amountOfUnreadMessages) {
      if (Platform.OS !== 'web' && nextProps.amountOfUnreadMessages !== undefined) {
        this.props.navigation.setParams({
          amountOfUnreadMessages: nextProps.amountOfUnreadMessages,
        })
      }
    }
  }

  onRefresh() {
    this.props.fetchUser()
    this.props.fetchMessages()
  }

  handleErrors(nextProps) {
    if (nextProps.error && this.props.error) {
      if (nextProps.error.headers.date === this.props.error.headers.date) {
        return
      }
    }
    if (nextProps.error) {
      switch (nextProps.error.status) {
        case 400:
        case 404:
        case 500:
          Alert.alert(`Error: ${nextProps.error.status}`, strings.errorCouldNotFetchNews)
          break
        default:
          Alert.alert(nextProps.error.status, undefined)
          break
      }
    }
  }

  render() {
    return (
      <Messages
        data-test="MessagesContainerComponent"
        isLoading={this.props.isLoading}
        messages={this.props.messages}
        navigation={this.props.navigation}
        onRefresh={() => this.onRefresh()}
      />
    )
  }
}

const mapStateToProps = state => ({
  messages: state.usermessagereducers.messages,
  isLoading: state.usermessagereducers.fetchMessagesLoading,
  amountOfUnreadMessages: state.usermessagereducers.amountOfUnreadMessages,
})

export default connect(
  mapStateToProps,
  { fetchMessages, fetchUser }
)(MessageContainer)
