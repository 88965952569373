export const INIT_MANUAL = 'INIT_MANUAL'
export const INIT_MANUAL_SUCCESS = 'INIT_MANUAL_SUCCESS'
export const INIT_MANUAL_FAILURE = 'INIT_MANUAL_FAILURE'
export const UPLOAD_MANUAL = 'UPLOAD_MANUAL'
export const UPLOAD_MANUAL_SUCCESS = 'UPLOAD_MANUAL_SUCCESS'
export const UPLOAD_MANUAL_FAILURE = 'UPLOAD_MANUAL_FAILURE'
export const GET_ALL_MANUALS = 'GET_ALL_MANUALS'
export const GET_ALL_MANUALS_SUCCESS = 'GET_ALL_MANUALS_SUCCESS'
export const GET_ALL_MANUALS_FAILURE = 'GET_ALL_MANUALS_FAILURE'
export const GET_MANUAL = 'GET_MANUAL'
export const GET_MANUAL_SUCCESS = 'GET_MANUAL_SUCCESS'
export const GET_MANUAL_FAILURE = 'GET_MANUAL_FAILURE'
export const UPDATE_MANUAL = 'UPDATE_MANUAL'
export const UPDATE_MANUAL_SUCCESS = 'UPDATE_MANUAL_SUCCESS'
export const UPDATE_MANUAL_FAILURE = 'UPDATE_MANUAL_FAILURE'
export const DELETE_MANUAL = 'DELETE_MANUAL'
export const DELETE_MANUAL_SUCCESS = 'DELETE_MANUAL_SUCCESS'
export const DELETE_MANUAL_FAILURE = 'DELETE_MANUAL_FAILURE'
export const OPEN_DIALOG = 'OPEN_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'
export const OPEN_DETAIL_DIALOG = 'OPEN_DETAIL_DIALOG'
export const CLOSE_DETAIL_DIALOG = 'CLOSE_DETAIL_DIALOG'
export const UPDATE_MANUALS = 'UPDATE_MANUALS'
export const FETCHING_MANUALS = 'FETCHING_MANUALS'
export const FETCHING_MANUALS_SUCCESS = 'FETCHING_MANUALS_SUCCESS'
export const FETCHING_MANUALS_FAILURE = 'FETCHING_MANUALS_FAILURE'
