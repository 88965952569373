/* eslint-disable react/no-array-index-key */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react'
import strings from '../../../../res/strings'
import Alert from '../../../../ui/alert/Alert.web'
import Table from '../../../../ui/table/Table.web'
import ResellerType from '../../../../types/ResellerType'
import './AllResellers.styles.web.scss'

type AllResellersPropsType = {
  resellers: [],
  isLoading: boolean,
  error: {
    status: number,
  },
  selectReseller: (index: any, row: any) => void,
  selectedRow: ResellerType,
  clickEnabled: boolean,
}

type AllResellersStateType = {
  searchFilterString: string,
  countryFilterString: string,
}
export default class AllResellers extends Component<AllResellersPropsType, AllResellersStateType> {
  constructor(props: any) {
    super(props)
    this.state = {
      searchFilterString: '',
      countryFilterString: strings.all,
    }
  }

  getfilteredResellers() {
    if (!this.props.resellers) {
      return undefined
    }

    const countryFilterString =
      this.state.countryFilterString !== strings.all
        ? this.state.countryFilterString.toLowerCase()
        : ''

    let filteredResellers = this.props.resellers.filter(
      reseller =>
        reseller.name?.toLowerCase().includes(this.state.searchFilterString.toLowerCase()) ||
        reseller.address?.toLowerCase().includes(this.state.searchFilterString.toLowerCase()) ||
        reseller.city?.toLowerCase().includes(this.state.searchFilterString.toLowerCase())
    )

    if (countryFilterString) {
      filteredResellers = filteredResellers.filter(reseller =>
        reseller.country?.toLowerCase().includes(countryFilterString)
      )
    }

    return filteredResellers
  }

  getPickerItems() {
    if (!this.props.resellers) {
      return null
    }

    const countries = [
      strings.all,
      ...new Set(this.props.resellers.map(reseller => reseller.country)),
    ].filter(Boolean)
    return countries.map((reseller, index) => (
      <option key={index} value={reseller}>
        {reseller}
      </option>
    ))
  }

  render() {
    const columns = {
      name: strings.name,
      address: strings.address,
      city: strings.city,
      country: strings.country,
      phoneNumber: strings.phone,
      homepageUrl: strings.webpage,
      hasTrailerService: strings.caravanService,
      hasRVService: strings.rvService,
      hasGeneralService: strings.workshop,
    }

    return (
      <div className="allresellers__container">
        <Alert />
        <div className="allresellers__search-header">
          <select
            value={this.state.countryFilterString}
            onChange={e => this.setState({ countryFilterString: e.target.value })}
            className="allresellers__select"
          >
            {this.getPickerItems()}
          </select>
          <input
            className="allresellers__search-input"
            placeholder={strings.search}
            type="text"
            value={this.state.searchFilterString}
            onChange={e => this.setState({ searchFilterString: e.target.value })}
          />
        </div>
        <Table
          columns={columns}
          rows={this.getfilteredResellers()}
          handleClick={(index, row) => this.props.selectReseller(row)}
          isLoading={this.props.isLoading}
          selectedRow={this.props.selectedRow}
          clickEnabled={this.props.clickEnabled}
        />
      </div>
    )
  }
}
