import React, { Component } from 'react'
import Select from 'react-select'

import './MultiSelect.styles.web.scss'

type MultiSelectPropsType = {
  options: MultiSelectItem[],
  value: MultiSelectItem[],
  placeholder: string,
  onChange: (value: MultiSelectItem[]) => void,
}

type MultiSelectItem = {
  value: string,
  label: string,
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid rgba(0, 0, 0, 0.87)',
    minHeight: '57px',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.87)',
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: 'black',
    marginLeft: '6px',
  }),
  input: (provided, state) => ({
    ...provided,
    marginLeft: '6px',
  }),
}

export default (props: MultiSelectPropsType) => {
  return (
    <div className="kabe-multi-select-container">
      <Select
        options={props.options}
        isMulti
        closeMenuOnSelect={false}
        styles={customStyles}
        placeholder="Alla"
        onChange={props.onChange}
        value={props.value}
      />
      <span className="kabe-multi-select-placeholder">{props.placeholder}</span>
    </div>
  )
}
