/**
 * @Flow
 */
import React, { Component } from 'react'
import { Platform } from 'react-native'
import { connect } from 'react-redux'
import Inspections from './Inspections'
import { fetchInspections } from '../../../../redux/actions/InspectionsActions'
import { InspectionsType } from '../../../../types/InspectionsType'
import strings from '../../../../res/strings'
import Alert from '../../../../ui/alert'

type InspectionsPropTypes = {
  inspections: InspectionsType,
  isLoading: boolean,
  chassiNumber: string,
  error: {
    status: number,
    headers: {
      date: Date,
    },
  },
  user: any,
  fetchInspections: () => void,
  navigation: {
    navigate: () => void,
    addListener: () => void,
  },
}

let didFocusSubscription

class InspectionsContainer extends Component<InspectionsPropTypes> {
  componentWillMount() {
    const verified = this.props.user !== undefined
    if (this.props.chassiNumber && verified) {
      this.subscribeAndFetch()
    }
  }

  componentWillReceiveProps(nextProps) {
    this.handleErrors(nextProps)
    const { chassiNumber } = nextProps
    if (
      this.props.inspections === undefined &&
      chassiNumber !== '' &&
      !this.props.isLoading &&
      !this.props.error
    ) {
      this.props.fetchInspections(chassiNumber)
    }

    if (!this.props.user && nextProps.user && !didFocusSubscription) {
      this.subscribeAndFetch()
    }
  }

  componentWillUnmount() {
    if (Platform.OS !== 'web' && didFocusSubscription !== undefined) {
      didFocusSubscription.remove()
    }
  }

  subscribeAndFetch() {
    if (Platform.OS !== 'web') {
      didFocusSubscription = this.props.navigation.addListener('didFocus', payload => {
        this.props.fetchInspections(this.props.chassiNumber)
      })
    } else {
      this.props.fetchInspections(this.props.chassiNumber)
    }
  }

  static navigationOptions = {
    title: strings.inspections,
  }

  handleErrors(nextProps) {
    if (nextProps.error && this.props.error) {
      if (nextProps.error.headers.date === this.props.error.headers.date) {
        return
      }
    }
    if (nextProps.error) {
      switch (nextProps.error.status) {
        case 400:
        case 404:
        case 500:
          Alert.alert(`Error: ${nextProps.error.status}`, strings.errorCouldNotFetchInspections)
          break
        default:
          Alert.alert(`Error: ${nextProps.error.status}`, undefined)
          break
      }
    }
  }

  render() {
    return <Inspections inspections={this.props.inspections} isLoading={this.props.isLoading} />
  }
}

const mapStateToProps = state => ({
  inspections: state.inspections.inspections,
  isLoading: state.inspections.isLoading,
  error: state.inspections.error,
  chassiNumber: state.vehicle.vehicle.chassiNumber,
  user: state.user.user,
})

export default connect(
  mapStateToProps,
  { fetchInspections }
)(InspectionsContainer)
