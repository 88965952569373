import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  initManual,
  getManual,
  updateManual,
  deleteManual,
} from '../../../../redux/actions/ManualActions'
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../../../ui/modal/Modal.web'
import Dropdown from '../../../../ui/dropdown/Dropdown.web'

import './DetailManualsModal.styles.web.scss'

type DetailManualsModalPropsType = {
  isOpen: boolean,
  isLoading: boolean,
  id: string,
  vehicleTypes: [],
  years: [],
  manual: undefined,
  hasBeenDeleted: false,
  hasBeenUpdated: false,
  onClose: () => void,
  onDelete: () => void,
  onUpdate: () => void,
  initManual: () => void,
  getManual: () => void,
  updateManual: (id: string, vehicleTypeId: string) => void,
  deleteManual: (id: string) => void,
}

type DetailManualsModalStateType = {
  vehicleTypeId: string,
  year: string,
  name: string,
  path: string,
}

class DetailManualsModal extends Component<
  DetailManualsModalPropsType,
  DetailManualsModalStateType
> {
  constructor(props) {
    super(props)
    this.state = {
      vehicleTypeId: '',
      year: '',
      name: '',
      path: '',
    }
  }

  componentDidMount() {
    this.props.initManual()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.manual) {
      const vehicleType = this.props.vehicleTypes.find(o => o.id === nextProps.manual.vehicleTypeId)
      this.setState({
        vehicleTypeId: vehicleType ? vehicleType.id : '',
        year: nextProps.manual.year,
        name: nextProps.manual.name,
        path: nextProps.manual.path,
      })
    }

    if (nextProps.id && this.props.id !== nextProps.id) {
      this.props.getManual(nextProps.id)
    }

    if (nextProps.hasBeenDeleted) {
      this.setState({ vehicleTypeId: '', name: '', path: '', year: '' })
      this.props.onDelete()
    }

    if (nextProps.hasBeenUpdated) {
      this.setState({ vehicleTypeId: '', name: '', path: '', year: '' })
      this.props.onUpdate()
    }
  }

  resetAndClose = () => {
    this.setState({ vehicleTypeId: '', year: '' })
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.updateManual(this.props.id, this.state)
  }

  onDelete = () => {
    this.props.deleteManual(this.props.id)
  }

  onVehicleTypeChange = vehicleTypeId => {
    this.setState({ vehicleTypeId })
  }

  onYearChange = year => {
    this.setState({ year })
  }

  render() {
    return (
      <Modal handleClose={this.props.onClose} show={this.props.isOpen}>
        <ModalHeader text="MANUAL" onClose={this.resetAndClose} />
        <ModalContent>
          <Dropdown
            placeholder="Modell"
            items={this.props.vehicleTypes}
            onChange={this.onVehicleTypeChange}
            value={this.state.vehicleTypeId}
          />
          <Dropdown
            placeholder="Årsmodell"
            items={this.props.years}
            onChange={this.onYearChange}
            value={this.state.year}
          />
          <a href={this.state.path} target="_blank" rel="noopener noreferrer">
            {this.state.name}
          </a>
        </ModalContent>
        <ModalFooter
          onSubmit={this.onSubmit}
          onSubmitText="SPARA"
          onCancel={this.onDelete}
          onCancelText="TA BORT"
          isLoading={this.props.isLoading}
        />
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.manuals.isLoading,
  vehicleTypes: state.manuals.vehicleTypes,
  years: state.manuals.years,
  manual: state.manuals.manual,
  error: state.manuals.error,
  hasBeenDeleted: state.manuals.hasBeenDeleted,
  hasBeenUpdated: state.manuals.hasBeenUpdated,
})

export default connect(
  mapStateToProps,
  { initManual, getManual, updateManual, deleteManual }
)(DetailManualsModal)
