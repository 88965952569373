import React, { Component } from 'react'
import { connect } from 'react-redux'
import DropZone from '../../../../ui/dropzone/DropZone.web'
import { initManual, uploadManual } from '../../../../redux/actions/ManualActions'
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../../../ui/modal/Modal.web'
import Dropdown from '../../../../ui/dropdown/Dropdown.web'

import './UploadManualsModal.styles.web.scss'

type UploadManualsModalPropsType = {
  isOpen: boolean,
  shouldClose: boolean,
  isLoading: boolean,
  vehicleTypes: [],
  years: [],
  onClose: () => void,
  initManual: () => void,
  uploadManual: (data: any) => void,
}

type UploadManualsModalStateType = {
  vehicleTypeId: string,
  year: string,
  files: File[],
  file: File,
}

class UploadManualsModal extends Component<
  UploadManualsModalPropsType,
  UploadManualsModalStateType
> {
  constructor(props) {
    super(props)
    this.state = {
      vehicleTypeId: '',
      year: '',
      files: [],
    }
  }

  componentDidMount() {
    this.props.initManual()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.shouldClose) {
      this.setState({ modelId: '', files: [] })
      this.props.onClose()
    }
  }

  resetAndClose = () => {
    this.setState({ modelId: '', files: [] })
    this.props.onClose()
  }

  onSubmit = () => {
    if (this.state.files && this.state.files.length > 0) {
      this.props.uploadManual(this.state)
    }
  }

  onVehicleTypeChange = vehicleTypeId => {
    this.setState({ vehicleTypeId })
  }

  onYearChange = year => {
    this.setState({ year })
  }

  onFileDrop = (files: File[]) => {
    this.setState(prevState => {
      const updatedFiles = prevState.files.concat(files)
      files.forEach(file => this.readFile(file))
      return { files: updatedFiles }
    })
  }

  onFileDelete = (file: File) => {
    this.setState(prevState => {
      const files = prevState.files.filter(o => o !== file)
      return { files }
    })
  }

  readFile = (file: File) => {
    const reader = new FileReader()

    reader.onload = () => {}

    reader.readAsDataURL(file)
  }

  render() {
    return (
      <Modal handleClose={this.props.onClose} show={this.props.isOpen}>
        <ModalHeader text="LADDA UPP MANUALER" onClose={this.resetAndClose} />
        <ModalContent>
          <Dropdown
            placeholder="Modell"
            items={this.props.vehicleTypes}
            onChange={this.onVehicleTypeChange}
            value={this.state.vehicleType}
          />
          <Dropdown
            placeholder="Årsmodell"
            items={this.props.years}
            onChange={this.onYearChange}
            value={this.state.year}
          />
          <DropZone
            files={this.state.files}
            onFileDrop={this.onFileDrop}
            onDelete={this.onFileDelete}
          />
        </ModalContent>
        <ModalFooter
          onSubmit={this.onSubmit}
          onSubmitText="LADDA UPP"
          isLoading={this.props.isLoading}
        />
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  vehicleTypes: state.manuals.vehicleTypes,
  years: state.manuals.years,
  manuals: state.manuals.manuals,
  error: state.manuals.error,
  shouldClose: state.manuals.shouldClose,
})

export default connect(
  mapStateToProps,
  { initManual, uploadManual }
)(UploadManualsModal)
