/* eslint-disable no-console */

import axios from 'axios'
import NetworkHandler from '../../utility/networkhandler/NetworkHandler'
import getToken, { baseUrl } from '../../appconfig'
import store from '../store'
import { OwnerType } from '../../types/OwnerType'
import {
  FETCHING_OWNER,
  FETCHING_OWNER_SUCCESS,
  FETCHING_OWNER_FAILURE,
  UPDATING_OWNER,
  UPDATING_OWNER_SUCCESS,
  UPDATING_OWNER_FAILURE,
} from '../types/OwnerTypes'

export const fetchOwner = () => dispatch => {
  dispatch(getOwner())

  NetworkHandler.get('/api/Owner')
    .then(response => {
      const owner = response ? response.data : store.getState().owner.owner
      dispatch(getOwnerSuccess(owner))
    })
    .catch(error => {
      console.log(`OwnerActions.js, Error: ${error.response?.status}`)
      dispatch(getOwnerFailure(error.response))
    })
}

export const updateOwner = (owner: OwnerType) => dispatch => {
  dispatch(getUpdateOwner())

  getToken().then(token => {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }

    axios
      .put(`${baseUrl}/api/Owner`, owner, { headers })
      .then(response => {
        dispatch(getUpdateOwnerSuccess(response.data))
      })
      .catch(error => {
        console.log(`OwnerActions.js, Error: ${error.response?.status}`)
        dispatch(getUpdateOwnerFailure(error.response))
      })
  })
}

function getOwner() {
  return {
    type: FETCHING_OWNER,
  }
}

function getOwnerSuccess(data: OwnerType) {
  return {
    type: FETCHING_OWNER_SUCCESS,
    data,
  }
}

function getOwnerFailure(error) {
  return {
    type: FETCHING_OWNER_FAILURE,
    payload: error,
  }
}

function getUpdateOwner() {
  return {
    type: UPDATING_OWNER,
  }
}

function getUpdateOwnerSuccess(data: OwnerType) {
  return {
    type: UPDATING_OWNER_SUCCESS,
    data,
  }
}

function getUpdateOwnerFailure(error) {
  return {
    type: UPDATING_OWNER_FAILURE,
    payload: error,
  }
}
