import React, { Component } from 'react'
import { connect } from 'react-redux'
import StandardButton from '../../../ui/standardbutton/StandardButton.web'
import Search from '../../../ui/search/Search.web'
import UploadManualsModal from './uploadmanualsmodal/UploadManualsModal.web'
import DetailManualsModal from './detailmanualsmodal/DetailManualsModal.web'
import {
  getManuals,
  openDialog,
  closeDialog,
  openDetailDialog,
  closeDetailDialog,
  updateManuals,
} from '../../../redux/actions/ManualActions'
import './Manuals.styles.web.scss'

type ManualsPropsType = {
  manuals: [],
  dialogOpen: false,
  detailDialogOpen: false,
  getManuals: () => void,
  openDialog: () => void,
  closeDialog: () => void,
  openDetailDialog: () => void,
  closeDetailDialog: () => void,
}

type ManualsStateType = {
  detailId: undefined,
  manuals: [],
  search: '',
}

class Manuals extends Component<ManualsPropsType, ManualsStateType> {
  constructor(props) {
    super(props)
    this.state = {
      detailId: undefined,
      search: '',
    }
  }

  componentDidMount() {
    this.props.getManuals()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(prevState => ({
      manuals: nextProps.manuals.filter(
        o =>
          o.name.toLowerCase().includes(prevState.search) ||
          o.vehicleType.toLowerCase().includes(prevState.search)
      ),
    }))
  }

  onModalOpen = () => {
    this.props.openDialog()
  }

  onModalClose = () => {
    this.props.closeDialog()
  }

  onDetailModalOpen = (detailId: string) => {
    this.setState({ detailId })
    this.props.openDetailDialog()
  }

  onDetailModalClose = () => {
    this.props.closeDetailDialog()
  }

  onDelete = () => {
    this.props.closeDetailDialog()
    this.setState({ detailId: undefined })
  }

  onUpdate = manual => {
    this.props.closeDetailDialog()
    this.setState({ detailId: undefined })
  }

  onSearch = searchText => {
    const text = searchText.toLowerCase()
    this.setState({
      search: text,
      manuals: this.props.manuals.filter(
        o =>
          o.name.toLowerCase().includes(text) ||
          o.vehicleType.toLowerCase().includes(text) ||
          o.year.toLowerCase().includes(text)
      ),
    })
  }

  render() {
    return (
      <div>
        <div className="manuals-container">
          <div className="manuals-button-container">
            <Search onChange={this.onSearch} />
            <StandardButton text="LADDA UPP" onClick={this.onModalOpen} />
          </div>
          <table className="manuals-table">
            <thead>
              <tr>
                <th>Namn</th>
                <th>Fordonstyp</th>
                <th>Årsmodell</th>
              </tr>
            </thead>
            <tbody>
              {this.state.manuals &&
                this.state.manuals.map(manual => {
                  return (
                    <tr key={manual.id} onClick={() => this.onDetailModalOpen(manual.id)}>
                      <td>{manual.name}</td>
                      <td>{manual.vehicleType}</td>
                      <td>{manual.year}</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
        <UploadManualsModal isOpen={this.props.dialogOpen} onClose={this.onModalClose} />
        <DetailManualsModal
          isOpen={this.props.detailDialogOpen}
          onClose={this.onDetailModalClose}
          onDelete={this.onDelete}
          onUpdate={this.onUpdate}
          id={this.state.detailId}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  manuals: state.manuals.manuals,
  isLoading: state.manuals.isLoading,
  error: state.manuals.error,
  dialogOpen: state.manuals.dialogOpen,
  detailDialogOpen: state.manuals.detailDialogOpen,
})

export default connect(
  mapStateToProps,
  { getManuals, openDialog, closeDialog, openDetailDialog, closeDetailDialog, updateManuals }
)(Manuals)
