/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/**
 * @Flow
 */
import React from 'react'
import { connect } from 'react-redux'
import logo from '../../res/images/kabe-logo.png'
import { toggleMenu } from '../../redux/actions/MenuActions'
import './Header.styles.web.scss'

export class Header extends React.Component {
  getHamburgerMenu() {
    return (
      <div
        className={this.props.menuOpen ? 'header__menu-button active' : 'header__menu-button'}
        onClick={() => this.props.toggleMenu(!this.props.menuOpen)}
        role="button"
      >
        <div className="menu-button__bar1" />
        <div className="menu-button__bar2" />
        <div className="menu-button__bar3" />
      </div>
    )
  }

  render() {
    return (
      <div className="header" data-test="header">
        {this.getHamburgerMenu()}

        <div className="header__logo-container">
          <img className="header__logo" src={logo} alt="logo" />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  menuOpen: state.menu.open,
})

export default connect(
  mapStateToProps,
  { toggleMenu }
)(Header)
