/**
 * @Flow
 */

import React from 'react'
import pdfIcon from '../../../../res/images/ic_pdf.svg'
import './Manuals.styles.web.scss'

export default (props: any) => {
  const manuallist = () => {
    const manuals = props.manuals ? props.manuals : []
    return manuals.map(manual => {
      return (
        <div className="manuals__item-container" key={manual.id}>
          <img className="manuals__img" src={pdfIcon} alt="pdf_icon" />
          <a className="manuals__link" href={manual.path} target="_blank" rel="noopener noreferrer">
            {manual.name}
          </a>
        </div>
      )
    })
  }
  return (
    <div className="manuals__container">
      <div className="manuals__list-container">{manuallist()}</div>
    </div>
  )
}
