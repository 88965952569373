import React, { Component } from 'react'
import { connect } from 'react-redux'
import StandardButton from '../../../ui/standardbutton/StandardButton.web'
import Search from '../../../ui/search/Search.web'
import CreateMessageModal from './createmessagemodal/CreateMessageModal.web'
import DetailMessageModal from './detailmessagemodal/DetailMessageModal.web'
import EmailIcon from '../../../res/images/message_email.svg'
import SmsIcon from '../../../res/images/message_sms.svg'
import MobileIcon from '../../../res/images/mobile-alt-solid.svg'
import {
  getMessages,
  openDialog,
  closeDialog,
  openDetailDialog,
  closeDetailDialog,
} from '../../../redux/actions/MessageActions'
import './Messages.styles.web.scss'

type MessagesPropsType = {
  messages: [],
  dialogOpen: false,
  detailDialogOpen: false,
  getMessages: () => void,
  openDialog: () => void,
  closeDialog: () => void,
  openDetailDialog: () => void,
  closeDetailDialog: () => void,
}

type MessagesStateType = {
  messages: [],
  detailId: string,
  search: string,
}

class Messages extends Component<MessagesPropsType, MessagesStateType> {
  constructor(props) {
    super(props)

    this.state = {
      messages: [],
      detailId: undefined,
      search: '',
    }
  }

  componentDidMount = () => {
    this.props.getMessages()
  }

  componentWillReceiveProps = nextProps => {
    this.setState(prevState => ({
      messages: this.search(nextProps.messages, prevState.search),
    }))
  }

  onModalClose = () => {
    this.props.closeDialog()
  }

  onModalOpen = () => {
    this.props.openDialog()
  }

  onDetailModalOpen = detailId => {
    this.setState({ detailId })
    this.props.openDetailDialog()
  }

  onDetailModalClose = () => {
    this.setState({ detailId: undefined })
    this.props.closeDetailDialog()
  }

  onUpdate = () => {
    this.setState({ detailId: undefined })
    this.props.closeDetailDialog()
  }

  onDelete = () => {
    this.setState({ detailId: undefined })
    this.props.closeDetailDialog()
  }

  onSearch = (text: string) => {
    this.setState({
      search: text,
      messages: this.search(this.props.messages, text),
    })
  }

  search = (messages, searchText: string) => {
    if (!messages) {
      return undefined
    }

    const text = searchText.toLowerCase()
    return messages.filter(
      o =>
        o.title.toLowerCase().includes(text) ||
        o.category.toLowerCase().includes(text) ||
        o.time.toLowerCase().includes(text)
    )
  }

  render() {
    return (
      <div>
        <div className="messages-container">
          <div className="messages-button-container">
            <Search onChange={this.onSearch} />
            <StandardButton text="NYTT UTSKICK" onClick={this.onModalOpen} />
          </div>
          <table className="messages-table">
            <thead>
              <tr>
                <th>Titel</th>
                <th>Kategori</th>
                <th>Tidpunkt</th>
                <th />
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {this.state.messages &&
                this.state.messages.map(message => {
                  return (
                    <tr key={message.id} onClick={() => this.onDetailModalOpen(message.id)}>
                      <td>{message.title}</td>
                      <td>{message.category}</td>
                      <td>{message.time}</td>
                      <td>
                        {message.sendByApp ? (
                          <img className="messages-table-icon" src={MobileIcon} alt="app" />
                        ) : (
                          undefined
                        )}
                      </td>
                      <td>
                        {message.sendByEmail ? (
                          <img className="messages-table-icon" src={EmailIcon} alt="email" />
                        ) : (
                          undefined
                        )}
                      </td>
                      <td>
                        {message.sendBySms ? (
                          <img className="messages-table-icon" src={SmsIcon} alt="sms" />
                        ) : (
                          undefined
                        )}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
        <CreateMessageModal isOpen={this.props.dialogOpen} onClose={this.onModalClose} />
        <DetailMessageModal
          isOpen={this.props.detailDialogOpen}
          onClose={this.onDetailModalClose}
          id={this.state.detailId}
          onDelete={this.onDelete}
          onUpdate={this.onUpdate}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  messages: state.message.messages,
  dialogOpen: state.message.dialogOpen,
  detailDialogOpen: state.message.detailDialogOpen,
  error: state.message.error,
})

export default connect(
  mapStateToProps,
  { getMessages, openDialog, closeDialog, openDetailDialog, closeDetailDialog }
)(Messages)
