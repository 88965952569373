/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 * @Flow
 */

import React from 'react'
import Input from '../../../../ui/input/Input.web'
import strings from '../../../../res/strings'
import Alert from '../../../../ui/alert/Alert.web'
import Loader from '../../../../ui/loader/Loader.web'
import CustomButtonWeb from '../../../../ui/custombutton/CustomButton.web'
import './About.styles.web.scss'

export default (props: any) => {
  const { vehicle } = props
  return (
    <>
      <div
        className={props.isLoading ? 'vehicledata__container --loading' : 'vehicledata__container'}
      >
        <Alert />
        <Loader />
        <Input
          label={strings.registrationNumber}
          name="registrationNumber"
          className="vehicle__input"
          style={{ marginLeft: 'auto' }}
          value={vehicle.registrationNumber || ''}
          readOnly
        />
        <Input
          label={strings.chassiNumber}
          name="chassiNumber"
          className="vehicle__input"
          value={vehicle.chassiNumber || ''}
          readOnly
        />
        <Input
          label={strings.product}
          name="product"
          className="vehicle__input"
          style={{ marginLeft: 'auto' }}
          value={vehicle.product || ''}
          readOnly
        />
        <Input
          label={strings.smdRemoteActivatedDate}
          name="smdRemoteActivatedDate"
          className="vehicle__input"
          value={vehicle.smdRemoteActivatedDate || ''}
          readOnly
        />
        <Input
          label={strings.smdRemoteActiveUntilDate}
          name="smdRemoteActiveUntilDate"
          className="vehicle__input"
          style={{ marginLeft: 'auto' }}
          value={vehicle.smdRemoteActiveUntilDate || ''}
          readOnly
        />
        <Input
          label={strings.warrantyStartDate}
          name="warrantyStartDate"
          className="vehicle__input"
          value={vehicle.warrantyStartDate || ''}
          readOnly
        />
        <Input
          label={strings.acquisitionDate}
          name="acquisitionDate"
          className="vehicle__input"
          style={{ marginLeft: 'auto' }}
          value={vehicle.acquisitionDate || ''}
          readOnly
        />
        <div />
        <label className="vehicle__checkbox">
          <input
            name="receiveMessages"
            type="checkbox"
            checked={vehicle.receiveMessages}
            onChange={props.handleInputChange}
          />
          {strings.receiveMessages}
        </label>
        <div />
        <p className="vehicle__communication-way-label">{strings.communicationWay}</p>
        <div />
        <div className="vehicle__communication-ways">
          <label>
            <input
              name="communicateByEmail"
              type="checkbox"
              checked={vehicle.communicateByEmail}
              onChange={props.handleInputChange}
            />
            {strings.communicateByEmail}
          </label>
          <label>
            <input
              name="communicateBySMS"
              type="checkbox"
              style={{ marginLeft: '46px' }}
              checked={vehicle.communicateBySMS}
              onChange={props.handleInputChange}
            />
            {strings.communicateBySMS}
          </label>
        </div>
      </div>
      <CustomButtonWeb
        customClass="vehicle__about--remove-vehicle-button"
        text={strings.removeVehicle}
        onClick={() => {
          const answer = confirm(strings.verifyRemoveVehicle)
          if (answer === true) {
            props.removeVehicle()
          }
        }}
        isLoading={props.removeIsLoading}
      />
    </>
  )
}
