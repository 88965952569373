import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

import {
  INIT_MANUAL,
  INIT_MANUAL_SUCCESS,
  INIT_MANUAL_FAILURE,
  UPLOAD_MANUAL,
  UPLOAD_MANUAL_SUCCESS,
  UPLOAD_MANUAL_FAILURE,
  GET_ALL_MANUALS,
  GET_ALL_MANUALS_SUCCESS,
  GET_ALL_MANUALS_FAILURE,
  GET_MANUAL,
  GET_MANUAL_SUCCESS,
  GET_MANUAL_FAILURE,
  OPEN_DIALOG,
  CLOSE_DIALOG,
  DELETE_MANUAL,
  DELETE_MANUAL_SUCCESS,
  DELETE_MANUAL_FAILURE,
  UPDATE_MANUAL,
  UPDATE_MANUAL_SUCCESS,
  UPDATE_MANUAL_FAILURE,
  OPEN_DETAIL_DIALOG,
  CLOSE_DETAIL_DIALOG,
  FETCHING_MANUALS,
  FETCHING_MANUALS_SUCCESS,
  FETCHING_MANUALS_FAILURE,
  UPDATE_MANUALS,
} from '../types/ManualTypes'

const initialState = {
  isLoading: false,
  error: undefined,
  manuals: [],
  manual: undefined,
  vehicleTypes: [],
  years: [],
  shouldClose: false,
  dialogOpen: false,
  detailDialogOpen: false,
  hasBeenDeleted: false,
  hasBeenUpdated: false,
}

const ManualReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_MANUAL:
      return {
        ...state,
        isLoading: true,
      }

    case INIT_MANUAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vehicleTypes: action.data.vehicleTypes,
        years: action.data.years,
      }

    case INIT_MANUAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case UPLOAD_MANUAL:
      return {
        ...state,
        isLoading: true,
      }

    case UPLOAD_MANUAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        manuals: action.data,
        shouldClose: true,
      }

    case UPLOAD_MANUAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case GET_ALL_MANUALS:
      return {
        ...state,
        isLoading: true,
      }

    case GET_ALL_MANUALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        manuals: action.data,
      }

    case GET_ALL_MANUALS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case GET_MANUAL:
      return {
        ...state,
        isLoading: true,
      }

    case GET_MANUAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        manual: action.data,
      }

    case GET_MANUAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case DELETE_MANUAL:
      return {
        ...state,
        isLoading: true,
      }

    case DELETE_MANUAL_SUCCESS: {
      const { manuals } = state
      const index = manuals.findIndex(o => o.id === action.data)
      if (index > -1) {
        state.manuals.splice(index, 1)
      }

      return {
        ...state,
        isLoading: false,
        hasBeenDeleted: true,
        manuals,
      }
    }

    case UPDATE_MANUAL:
      return {
        ...state,
        isLoading: true,
      }

    case UPDATE_MANUAL_SUCCESS: {
      const { manuals } = state
      const index = manuals.findIndex(o => o.id === action.data.id)
      if (index > -1) {
        state.manuals.splice(index, 1, action.data)
      }

      return {
        ...state,
        isLoading: false,
        hasBeenUpdated: true,
        manuals,
      }
    }

    case UPDATE_MANUAL_FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case FETCHING_MANUALS:
      return {
        ...state,
        isLoading: true,
      }

    case FETCHING_MANUALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        manuals: action.payload,
      }

    case DELETE_MANUAL_FAILURE:
    case FETCHING_MANUALS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case OPEN_DIALOG:
      return {
        ...state,
        dialogOpen: true,
        shouldClose: false,
      }

    case CLOSE_DIALOG:
      return {
        ...state,
        dialogOpen: false,
        shouldClose: false,
      }

    case OPEN_DETAIL_DIALOG:
      return {
        ...state,
        detailDialogOpen: true,
        shouldCloseDetail: false,
      }

    case CLOSE_DETAIL_DIALOG:
      return {
        ...state,
        detailDialogOpen: false,
        shouldCloseDetail: false,
        hasBeenDeleted: false,
        hasBeenUpdated: false,
      }

    case UPDATE_MANUALS:
      return {
        ...state,
      }

    default:
      return state
  }
}

const persistConfig = {
  key: 'manuals',
  storage,
  blacklist: ['isLoading', 'error'],
  stateReconciler: hardSet,
}

export default persistReducer(persistConfig, ManualReducer)
