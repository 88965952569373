/* eslint-disable no-console */

import axios from 'axios'
import getToken, { baseUrl } from '../../appconfig'
import {
  FETCHING_MESSAGES,
  FETCHING_MESSAGES_FAILURE,
  FETCHING_MESSAGES_SUCCESS,
  FETCHING_MESSAGE_ITEM,
  FETCHING_MESSAGE_ITEM_FAILURE,
  FETCHING_MESSAGE_ITEM_SUCCESS,
  MARK_MESSAGE_AS_READ,
  MARK_MESSAGE_AS_READ_FAILURE,
  MARK_MESSAGE_AS_READ_SUCCESS,
} from '../types/UserMessageTypes'
import NetworkHandler from '../../utility/networkhandler/NetworkHandler'

export const fetchMessages = () => dispatch => {
  dispatch(getMessages())

  NetworkHandler.get('/api/usermessage')
    .then(response => {
      dispatch(getMessagesSuccess(response.data))
    })
    .catch(error => {
      console.log(`UserMessageActions.js, Error: ${error.response?.status}`)
      dispatch(getMessagesFailure(error.response))
    })
}

export const fetchMessageItem = (id: string) => dispatch => {
  dispatch(getMessageItem())

  NetworkHandler.get(`/api/usermessage/${id}`)
    .then(response => {
      dispatch(getMessageItemSuccess(response.data))
    })
    .catch(error => {
      console.log(`UserMessageActions.js, Error: ${error.response?.status}`)
      dispatch(getMessageItemFailure(error.response))
    })
}

export const markMessagesAsRead = (id: string) => dispatch => {
  dispatch(getMarkMessageAsRead())

  getToken().then(token => {
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    axios
      .post(`${baseUrl}/api/usermessage/${id}`, {}, { headers })
      .then(response => {
        dispatch(getMarkMessageAsReadSuccess(response.data))
      })
      .catch(error => {
        console.log(`UserMessageActions.js, Error: ${error.response?.status}`)
        dispatch(getMarkMessageAsReadFailure(error.response))
      })
  })
}

function getMarkMessageAsRead() {
  return {
    type: MARK_MESSAGE_AS_READ,
  }
}

function getMarkMessageAsReadSuccess() {
  return {
    type: MARK_MESSAGE_AS_READ_SUCCESS,
  }
}

function getMarkMessageAsReadFailure(error) {
  return {
    type: MARK_MESSAGE_AS_READ_FAILURE,
    payload: error,
  }
}

function getMessageItem() {
  return {
    type: FETCHING_MESSAGE_ITEM,
  }
}

function getMessageItemSuccess(data) {
  return {
    type: FETCHING_MESSAGE_ITEM_SUCCESS,
    data,
  }
}

function getMessageItemFailure(error) {
  return {
    type: FETCHING_MESSAGE_ITEM_FAILURE,
    payload: error,
  }
}

function getMessages() {
  return {
    type: FETCHING_MESSAGES,
  }
}

function getMessagesSuccess(data) {
  return {
    type: FETCHING_MESSAGES_SUCCESS,
    data,
  }
}

function getMessagesFailure(error) {
  return {
    type: FETCHING_MESSAGES_FAILURE,
    payload: error,
  }
}
