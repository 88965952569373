import React from 'react'
import { connect } from 'react-redux'
import Tabs from '../../../ui/tabs/Tabs.web'
import About from './about'
import Manuals from './manuals'
import Inspections from './inspections'
import NoVehicleComponent from './novehicle'
import './Vehicle.styles.web.scss'

const Vehicle = (props: any) => {
  const verified = props.user !== undefined
  if (!verified) {
    return <NoVehicleComponent />
  }

  return (
    <div className="vehicle__container">
      <Tabs>
        <div text="OM">
          <About />
        </div>
        <div text="MANUALER">
          <Manuals />
        </div>
        <div text="KONTROLLER">
          <Inspections />
        </div>
      </Tabs>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user.user,
})

export default connect(mapStateToProps)(Vehicle)
