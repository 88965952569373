import React from 'react'
import { withRouter } from 'react-router-dom'
import rvImage from '../../res/images/rv1.png'
import smartphoneCaravan from '../../res/images/smartphone_caravan_desktop.png'
import './HeaderImageContainer.styles.web.scss'

const HeaderImageContainer = (props: any) => {
  const image = () => {
    switch (props.image) {
      case 'rv':
        return rvImage
      case 'smartphonecaravan':
        return smartphoneCaravan
      default:
        break
    }
    return null
  }
  return (
    <div className="headerimagecontainer-container">
      <img className="headerimagecontainer-image" src={image()} alt="headerimagecontainer" />
    </div>
  )
}

export default withRouter(HeaderImageContainer)
