/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux'
import { Platform } from 'react-native'
import thunk from 'redux-thunk'
import { persistStore } from 'redux-persist'
import rootReducer from './reducers/RootReducer'

const middleware = [thunk]

const reduxExtension = () => {
  if (Platform.OS === 'web') {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  }

  return undefined
}

const composeEnhancers = reduxExtension() || compose

const initialState = {}

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
)

const persistor = persistStore(store)

export { store as default, persistor }
