import React, { Component } from 'react'
import './ConfirmationAlert.styles.web.scss'
import StandardButton from '../standardbutton/StandardButton.web'

type ConfirmationAlertPropsType = {
  onYes: () => void,
  onNo: () => void,
  message: string,
}

export default class ConfirmationAlert extends Component<ConfirmationAlertPropsType> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="confirmation-alert-container">
        <div className="confirmation-alert">
          <p>{this.props.message}</p>
          <div>
            <StandardButton text="OK" onClick={this.props.onYes} />
            <StandardButton text="Avbryt" onClick={this.props.onNo} secondary="true" />
          </div>
        </div>
      </div>
    )
  }
}
