/* eslint-disable no-console */

import { TOGGLE_MENU } from '../types/MenuTypes'

export const toggleMenu = (value: boolean) => dispatch => {
  dispatch(getToggleToken(value))
}

export function getToggleToken(value) {
  return {
    type: TOGGLE_MENU,
    payload: value,
  }
}
