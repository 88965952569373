import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchNews } from '../../../../redux/actions/NewsActions'
import { fetchUser } from '../../../../redux/actions/UserActions'
import News from './News'
import strings from '../../../../res/strings'
import Alert from '../../../../ui/alert'

type RootPropTypes = {
  fetchNews: () => void,
  fetchUser: () => void,
  news: [],
  isLoading: boolean,
  error: {
    headers: {
      date: Date,
    },
  },
  navigation: {
    navigate: () => void,
  },
  history: {},
}

export class NewsContainer extends Component<RootPropTypes> {
  componentWillMount() {
    this.props.fetchUser()
    this.props.fetchNews()
  }

  componentWillReceiveProps(nextProps) {
    this.handleErrors(nextProps)
  }

  onRefresh() {
    this.props.fetchUser()
    this.props.fetchNews()
  }

  handleErrors(nextProps) {
    if (nextProps.error && this.props.error) {
      if (nextProps.error.headers.date === this.props.error.headers.date) {
        return
      }
    }
    if (nextProps.error) {
      switch (nextProps.error.status) {
        case 400:
        case 404:
        case 500:
          Alert.alert(`Error: ${nextProps.error.status}`, strings.errorCouldNotFetchNews)
          break
        default:
          Alert.alert(`Error: ${nextProps.error.status}`, undefined)
          break
      }
    }
  }

  render() {
    return (
      <News
        data-test="NewsContainerComponent"
        isLoading={this.props.isLoading}
        news={this.props.news}
        navigation={this.props.navigation}
        onRefresh={() => this.onRefresh()}
      />
    )
  }
}

const mapStateToProps = state => ({
  news: state.news.news,
  isLoading: state.news.isLoading,
  error: state.news.error,
})

export default connect(
  mapStateToProps,
  { fetchNews, fetchUser }
)(NewsContainer)
