import React from 'react'
import Input from '../../../../ui/input/Input.web'
import CustomButton from '../../../../ui/custombutton/CustomButton.web'
import strings from '../../../../res/strings'
import './OwnerData.styles.web.scss'

export default (props: any) => {
  const { owner, edit } = props

  return (
    <div className="ownerdata__container">
      <Input
        label={strings.firstname}
        name="firstName"
        className="owner__input"
        style={{ marginLeft: 'auto' }}
        value={owner?.firstName}
        disabled={!edit}
        onChange={e => props.onInputChange(e)}
      />
      <Input
        label={strings.lastname}
        name="lastName"
        className="owner__input"
        value={owner?.lastName}
        disabled={!edit}
        onChange={e => props.onInputChange(e)}
      />
      <Input
        label={strings.address}
        name="address"
        className="owner__input"
        style={{ marginLeft: 'auto' }}
        value={owner?.address}
        disabled={!edit}
        onChange={e => props.onInputChange(e)}
      />
      <Input
        label={strings.nr}
        name="address2"
        className="owner__input"
        value={owner?.address2}
        disabled={!edit}
        onChange={e => props.onInputChange(e)}
      />
      <Input
        label={strings.zipcode}
        name="zipCode"
        className="owner__input"
        style={{ marginLeft: 'auto' }}
        value={owner?.zipCode}
        disabled={!edit}
        onChange={e => props.onInputChange(e)}
      />
      <Input
        label={strings.city}
        name="city"
        className="owner__input"
        value={owner?.city}
        disabled={!edit}
        onChange={e => props.onInputChange(e)}
      />
      <Input
        label={strings.country}
        name="country"
        className="owner__input"
        style={{ marginLeft: 'auto' }}
        value={owner?.country}
        disabled={!edit}
        onChange={e => props.onInputChange(e)}
      />
      <Input
        label={strings.language}
        name="language"
        className="owner__input"
        value={owner?.language}
        disabled={!edit}
        onChange={e => props.onInputChange(e)}
      />
      <Input
        label={strings.emailaddress}
        name="email"
        className="owner__input"
        style={{ marginLeft: 'auto' }}
        value={owner?.email}
        disabled={!edit}
        onChange={e => props.onInputChange(e)}
      />
      <Input
        label={strings.cellphone}
        name="mobile"
        className="owner__input"
        value={owner?.mobile}
        disabled={!edit}
        onChange={e => props.onInputChange(e)}
      />
      <div />
      {edit ? (
        <CustomButton text={strings.save} onClick={() => props.saveForm()} />
      ) : (
        <CustomButton text={strings.edit} onClick={() => props.editForm()} />
      )}
    </div>
  )
}
