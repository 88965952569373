/**
 * @Flow
 */
import React from 'react'
import Loader from '../loader/Loader.web'
import './CustomButton.styles.web.scss'

type CustomButtonPropsType = {
  onClick: () => void,
  isLoading: boolean,
  disabled: boolean,
  text: string,
  customClass: any,
}

export default (props: CustomButtonPropsType) => {
  const text = props.text ? props.text.toUpperCase() : ' '
  return (
    <button
      className={`custom-button ${props.customClass}`}
      style={{ opacity: props.disabled ? 0.3 : 1, cursor: props.disabled ? 'auto' : 'pointer' }}
      type="submit"
      onClick={props.onClick}
      disabled={props.isLoading || props.disabled}
    >
      {props.isLoading ? <Loader /> : text}
    </button>
  )
}
