/**
 * @Flow
 */

import React from 'react'
import { withRouter } from 'react-router-dom'
import { routes } from '../../../../routes'
import readMessageIcon from '../../../../res/images/ic_message_read.svg'
import unreadMessageIcon from '../../../../res/images/ic_message_unread.svg'
import LoaderWeb from '../../../../ui/loader/Loader.web'
import './Messages.styles.web.scss'

export const Messages = (props: any) => {
  const messageslist = () => {
    const { messages } = props

    return messages?.map(item => {
      return (
        <div className="messages__item-container" key={item.id}>
          <div className="message__content-container">
            <img
              className="message__image"
              style={item.read ? { marginRight: '42px' } : undefined}
              src={item.read ? readMessageIcon : unreadMessageIcon}
              alt="message-img"
            />

            <p className="message__header">{item.header}</p>
          </div>
          <button
            className="message__button"
            type="button"
            // eslint-disable-next-line react/prop-types
            onClick={() => props.history.push(`${routes.messageView}/${item.id}`)}
          >
            LÄS
          </button>
        </div>
      )
    })
  }
  return (
    <div data-test="MessagesWebComponent" className="messages__container">
      {props.isLoading ? (
        <LoaderWeb />
      ) : (
        <div className="messages__list-container">{messageslist()}</div>
      )}
    </div>
  )
}

export default withRouter(Messages)
