import React from 'react'

import './Checkbox.styles.web.scss'

type CheckboxPropsType = {
  checked: boolean,
  onChange: () => void,
}

export default (props: CheckboxPropsType) => {
  const classes = props.checked ? 'kabe-checkbox checked' : 'kabe-checkbox'

  return (
    <input type="checkbox" checked={props.checked} onChange={props.onChange} className={classes} />
  )
}
