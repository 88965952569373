/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */

import { VehicleType } from '../../types/VehicleType'
import store from '../store'
import NetworkHandler from '../../utility/networkhandler/NetworkHandler'
import {
  FETCHING_INSPECTIONS,
  FETCHING_INSPECTIONS_SUCCESS,
  FETCHING_INSPECTIONS_FAILURE,
} from '../types/InspectionsTypes'

export const fetchInspections = (chassiNumer: string) => dispatch => {
  dispatch(getFetchInspections())
  NetworkHandler.get(`/api/Chassi/${chassiNumer}`)
    .then(response => {
      const inspections = response ? response.data : store.getState().inspections.inspections
      dispatch(getFetchInspectionsSuccess(inspections))
    })
    .catch(error => {
      console.log(`VehicleActions.js, Error: ${error.response?.status}`)
      switch (error.response?.status) {
        default:
          dispatch(getFetchInspectionsFailure(error.response))
          break
      }
    })
}

function getFetchInspections() {
  return {
    type: FETCHING_INSPECTIONS,
  }
}

function getFetchInspectionsSuccess(data: VehicleType) {
  return {
    type: FETCHING_INSPECTIONS_SUCCESS,
    data,
  }
}

function getFetchInspectionsFailure(error) {
  return {
    type: FETCHING_INSPECTIONS_FAILURE,
    payload: error,
  }
}
