import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import {
  CONNECTING_VEHICLE,
  CONNECTING_VEHICLE_SUCCESS,
  CONNECTING_VEHICLE_FAILURE,
  VERIFYING_VEHICLE,
  VERIFYING_VEHICLE_SUCCESS,
  VERIFYING_VEHICLE_FAILURE,
  FETCHING_VEHICLE,
  FETCHING_VEHICLE_SUCCESS,
  FETCHING_VEHICLE_FAILURE,
  UPDATING_VEHICLE,
  UPDATING_VEHICLE_SUCCESS,
  UPDATING_VEHICLE_FAILURE,
  RESET_CODE_SENT,
  REMOVING_VEHICLE,
  REMOVING_VEHICLE_FAILURE,
  REMOVING_VEHICLE_SUCCESS,
  SET_VEHICLE_REMOVED,
} from '../types/VehicleTypes'

const initialState = {
  isLoading: false,
  removeIsLoading: false,
  error: undefined,
  connectError: undefined,
  removeError: undefined,
  verifyError: undefined,
  verified: false,
  codeSent: false,
  email: '',
  chassiNumber: undefined,
  vehicle: {
    registrationNumber: '',
    chassiNumber: '',
    product: '',
    smdRemoteActivatedDate: '',
    smdRemoteActiveUntilDate: '',
    smdActivationCode: '',
    warrantyStartDate: '',
    acquisitionDate: '',
    receiveMessages: false,
    communicateByEmail: false,
    communicateBySMS: false,
  },
}

const VehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOVING_VEHICLE:
      return {
        ...state,
        removeIsLoading: true,
        removeError: false,
      }
    case REMOVING_VEHICLE_FAILURE:
      return {
        ...state,
        removeIsLoading: false,
        removeError: action.payload,
      }
    case REMOVING_VEHICLE_SUCCESS:
      return {
        ...state,
        removeIsLoading: false,
        verified: false,
        vehicleRemoved: true,
        codeSent: false,
        email: '',
      }
    case SET_VEHICLE_REMOVED:
      return {
        ...state,
        vehicleRemoved: action.payload,
      }
    case CONNECTING_VEHICLE_SUCCESS:
      return {
        ...state,
        connectIsLoading: false,
        connectError: undefined,
        codeSent: true,
        email: action.data,
      }

    case VERIFYING_VEHICLE_SUCCESS:
      return {
        ...state,
        verifyIsLoading: false,
        verifyError: undefined,
        verified: true,
        error: undefined,
      }

    case CONNECTING_VEHICLE:
      return {
        ...state,
        connectIsLoading: true,
        chassiNumber: action.payload,
        connectError: undefined,
      }

    case VERIFYING_VEHICLE:
      return {
        ...state,
        verifyIsLoading: true,
        verifyError: undefined,
      }

    case VERIFYING_VEHICLE_FAILURE:
      return {
        ...state,
        verifyIsLoading: false,
        verifyError: action.payload,
      }

    case CONNECTING_VEHICLE_FAILURE:
      return {
        ...state,
        connectIsLoading: false,
        connectError: action.payload,
      }

    case FETCHING_VEHICLE:
      return {
        ...state,
        isLoading: true,
      }

    case FETCHING_VEHICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vehicle: action.data,
        error: undefined,
      }

    case FETCHING_VEHICLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case UPDATING_VEHICLE:
      if (action.updatedParameter) {
        return {
          ...state,
          [`${action.updatedParameter}IsLoading`]: true,
        }
      }
      return {
        ...state,
        isLoading: true,
      }

    case UPDATING_VEHICLE_SUCCESS:
      if (action.updatedParameter) {
        return {
          ...state,
          [`${action.updatedParameter}IsLoading`]: false,
          vehicle: action.data,
          error: undefined,
        }
      }
      return {
        ...state,
        isLoading: false,
        vehicle: action.data,
        error: undefined,
      }

    case UPDATING_VEHICLE_FAILURE:
      if (action.updatedParameter) {
        return {
          ...state,
          [`${action.updatedParameter}IsLoading`]: false,
          error: action.payload,
        }
      }
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case RESET_CODE_SENT:
      return {
        ...state,
        codeSent: false,
      }

    default:
      return state
  }
}

const persistConfig = {
  key: 'vehicle',
  storage,
  whitelist: ['vehicle', 'chassiNumber', 'email'],
}

export default persistReducer(persistConfig, VehicleReducer)
