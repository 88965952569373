/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @Flow
 */
/**
 * @Flow
 */
import React from 'react'
import strings from '../../../../res/strings'
import Alert from '../../../../ui/alert/Alert.web'
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../../../ui/modal/Modal.web'

import './LinkVehicle.styles.web.scss'

export default (props: any) => (
  <div className="linkvehicle__container">
    <Modal handleClose={props.onClose} show customClass="linkvehicle__modal-main">
      <React.Fragment>
        <ModalHeader>
          <p className="modal__custom-header">{strings.addChassiNumber.toUpperCase()}</p>
        </ModalHeader>
        <ModalContent>
          <Alert />

          <div className="linkvehicle__input-container">
            <p className="linkvehicle__input-text">{strings.fillInChassiNumber}</p>
            <input
              className="linkvehicle__input"
              onChange={event => props.setChassiNumber(event.target.value)}
              onKeyPress={e => {
                if (props.chassiNumber.length >= 17 && e.key === 'Enter') {
                  props.connectVehicle()
                }
              }}
              value={props.chassiNumber}
            />
          </div>
        </ModalContent>
        <ModalFooter
          isLoading={props.isLoading}
          onCancel={() => props.goBack()}
          onCancelText={strings.cancel.toUpperCase()}
          onSubmit={() => props.connectVehicle()}
          onSubmitText={strings.sendCode.toUpperCase()}
          submitDisabled={props.chassiNumber.length < 17}
        />
      </React.Fragment>
    </Modal>
  </div>
)
