import React, { Component } from 'react'
import { Platform } from 'react-native'
import { connect } from 'react-redux'
import NoVehicle from './NoVehicle'
import { fetchUser } from '../../../../redux/actions/UserActions'
import { setVehicleRemoved } from '../../../../redux/actions/VehicleActions'
import { routes } from '../../../../routes'

type NoVehicleContainerPropTypes = {
  fetchUser: () => void,
  setVehicleRemoved: () => void,
  navigation: {
    addListener: () => void,
    push: () => void,
    navigate: () => void,
  },
  user: any,
  history: any,
  isLoading: boolean,
  vehicleRemoved: boolean,
  removeIsLoading: boolean,
}

let latestUpdate
class NoVehicleContainer extends Component<NoVehicleContainerPropTypes> {
  componentWillMount() {
    const now = new Date()

    if (!latestUpdate) {
      latestUpdate = new Date()
      latestUpdate.setSeconds(latestUpdate.getSeconds() - 1)
    }

    if (
      !this.props.isLoading &&
      now >= latestUpdate &&
      !this.props.vehicleRemoved &&
      !this.props.removeIsLoading
    ) {
      this.props.fetchUser()
      latestUpdate = new Date()
      latestUpdate.setSeconds(latestUpdate.getSeconds() + 10)
    }

    if (this.props.vehicleRemoved || this.props.removeIsLoading) {
      this.props.setVehicleRemoved(false)
    }
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.user !== undefined && !this.props.vehicleRemoved && !this.props.removeIsLoading) {
      this.navigate()
    }
  }

  navigate() {
    if (Platform.OS === 'web') {
      this.props.history.push(routes.vehicle)
    } else {
      this.props.navigation.navigate(routes.vehicleTabNavigator)
    }
  }

  render() {
    return <NoVehicle navigation={this.props.navigation} isLoading={this.props.isLoading} />
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  isLoading: state.user.isLoading,
  removeIsLoading: state.vehicle.removeIsLoading,
  vehicleRemoved: state.vehicle.vehicleRemoved,
})

export default connect(
  mapStateToProps,
  { fetchUser, setVehicleRemoved }
)(NoVehicleContainer)
