/**
 * @Flow
 */
import React, { Component } from 'react'
import { Platform } from 'react-native'
import { connect } from 'react-redux'
import { fetchManuals } from '../../../../redux/actions/ManualActions'
import strings from '../../../../res/strings'
import Alert from '../../../../ui/alert/Alert'
import Manuals from './Manuals'
import ManualType from '../../../../types/ManualType'

type ManualsPropsType = {
  error: {
    status: number,
    headers: {
      date: Date,
    },
  },
  isLoading: boolean,
  manuals: [ManualType],
  fetchManuals: () => void,
  user: any,
  navigation: {
    navigate: () => void,
    addListener: () => void,
  },
}

let didFocusSubscription

class ManualsContainer extends Component<ManualsPropsType> {
  componentDidMount() {
    const verified = this.props.user !== undefined
    if (verified) {
      this.subscribeAndFetch()
    }
  }

  componentWillReceiveProps(nextProps) {
    this.handleErrors(nextProps)
    if (!this.props.user && nextProps.user && !didFocusSubscription) {
      this.subscribeAndFetch()
    }
  }

  componentWillUnmount() {
    if (Platform.OS !== 'web' && didFocusSubscription !== undefined) {
      didFocusSubscription.remove()
    }
  }

  subscribeAndFetch() {
    if (Platform.OS !== 'web') {
      didFocusSubscription = this.props.navigation.addListener('didFocus', payload => {
        this.props.fetchManuals()
      })
    } else {
      this.props.fetchManuals()
    }
  }

  static navigationOptions = {
    title: strings.manuals,
  }

  handleErrors(nextProps) {
    if (nextProps.error && this.props.error) {
      if (nextProps.error.headers.date === this.props.error.headers.date) {
        return
      }
    }
    if (nextProps.error) {
      switch (nextProps.error.status) {
        default:
          Alert.alert(`Error: ${nextProps.error.status}`, strings.errorCouldNotFetchManuals)
          break
      }
    }
  }

  render() {
    return (
      <Manuals
        manuals={this.props.manuals}
        navigation={this.props.navigation}
        isLoading={this.props.isLoading}
      />
    )
  }
}

const mapStateToProps = state => ({
  manuals: state.manuals.manuals,
  isLoading: state.manuals.isLoading,
  error: state.manuals.error,
  user: state.user.user,
})

export default connect(
  mapStateToProps,
  { fetchManuals }
)(ManualsContainer)
