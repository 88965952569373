/**
 * @Flow
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchNews, fetchNewsArticle } from '../../../../../redux/actions/NewsActions'
import { routes } from '../../../../../routes'
import './NewsArticle.styles.web.scss'
import LoaderWeb from '../../../../../ui/loader/Loader.web'
import CustomButtonWeb from '../../../../../ui/custombutton/CustomButton.web'

type NewsActionsPropTypes = {
  newsArticle: any,
  news: [],
  match: any,
  fetchNews: () => void,
  fetchNewsArticle: () => void,
  history: {
    push: () => void,
  },
}

class NewsArticle extends Component<NewsActionsPropTypes> {
  constructor(props: any) {
    super(props)
    this.imageRef = React.createRef()

    const { id } = this.props.match.params
    this.props.fetchNewsArticle(id, true)
    this.props.fetchNews()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.props.fetchNewsArticle(nextProps.match.params.id)
      if (this.imageRef) {
        this.imageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }

  static getButton(newsItem) {
    if (!newsItem.linkText) {
      return undefined
    }
    return (
      <CustomButtonWeb text={newsItem.linkText} onClick={() => window.open(newsItem.linkUrl)} />
    )
  }

  moreNews() {
    const newsList = this.props.news.filter(newsItem => newsItem.id !== this.props.match.params.id)
    return newsList.map(newsItem => {
      return (
        <div className="newsarticle__more-news-item-container" key={newsItem.id}>
          <img className="newsarticle__more-news-image" src={newsItem.imageUrl} alt="news-img" />
          <div className="newsarticle__more-news-content-container">
            <div className="newsarticle__more-news-text-container">
              <p className="newsarticle__more-news-header">{newsItem.header}</p>
              <p className="newsarticle__more-news-created">
                {newsItem.created.substring(0, 10).replace('T', ' ')}
              </p>
              <p className="newsarticle__more-news-text">{newsItem.text}</p>
            </div>
            <button
              className="newsarticle__more-news-button"
              type="button"
              onClick={() => this.props.history.push(`${routes.newsArticle}/${newsItem.id}`)}
            >
              LÄS MER
            </button>
          </div>
        </div>
      )
    })
  }

  render() {
    const { newsArticle } = this.props

    return (
      <div className="newsarticle__container">
        {newsArticle === undefined ? (
          <LoaderWeb />
        ) : (
          <div>
            <div className="newsarticle__image-container">
              <img
                className="newsarticle__image"
                src={newsArticle.imageUrl}
                alt="news img"
                ref={this.imageRef}
              />
            </div>
            <div className="newsarticle__text-container">
              <p className="newsarticle__header">{newsArticle.header}</p>
              <p className="newsarticle__created">
                {newsArticle.created?.substring(0, 10).replace('T', ' ')}
              </p>
              <div className="newsarticle__separator" />
              <p className="newsarticle__text">{newsArticle.text}</p>
              {NewsArticle.getButton(newsArticle)}
            </div>
            <div className="newsarticle__morenews-header-container">
              <p className="newsarticle__morenews-header">Fler Nyheter</p>
              <div className="newsarticle__separator" />
            </div>
            <div className="newsarticle__morenews-container">{this.moreNews()}</div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  news: state.news.news,
  newsArticle: state.news.newsArticle,
})

export default connect(
  mapStateToProps,
  { fetchNews, fetchNewsArticle }
)(NewsArticle)
