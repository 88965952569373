import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import {
  FETCHING_OWNER,
  FETCHING_OWNER_SUCCESS,
  FETCHING_OWNER_FAILURE,
  UPDATING_OWNER,
  UPDATING_OWNER_SUCCESS,
  UPDATING_OWNER_FAILURE,
} from '../types/OwnerTypes'

const initialState = {
  owner: undefined,
  isLoading: false,
  error: undefined,
}

const OwnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_OWNER:
      return {
        ...state,
        isLoading: true,
      }

    case FETCHING_OWNER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        owner: action.data,
      }

    case FETCHING_OWNER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case UPDATING_OWNER:
      return {
        ...state,
        isLoading: true,
      }

    case UPDATING_OWNER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        owner: action.data,
      }

    case UPDATING_OWNER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

const persistConfig = {
  key: 'owner',
  storage,
  blacklist: ['isLoading', 'error'],
}

export default persistReducer(persistConfig, OwnerReducer)
