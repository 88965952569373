import React from 'react'
import { connect } from 'react-redux'
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../../../../ui/modal/Modal.web'
import strings from '../../../../../res/strings'
import AllResellers from '../../allresellers'

const ResellerModalWeb = (props: any) => (
  <Modal handleClose={props.onClose} show={props.isOpen} customClass="myreseller__modal-main">
    <React.Fragment>
      <ModalHeader>
        <p className="modal__custom-header">VÄLJ ÅTERFÖRSÄLJARE</p>
        <p>
          Välj din återförsäljare ur listan. Denna kommer bli din kontakt vid frågor gällande ditt
          fordon.
        </p>
      </ModalHeader>
      <ModalContent>
        <AllResellers
          handleClick={props.handleClick}
          selectedRow={props.selectedRow}
          clickEnabled
        />
      </ModalContent>
      <ModalFooter
        onCancel={() => props.cancel()}
        onCancelText={strings.cancel.toUpperCase()}
        onSubmit={() => props.submit()}
        onSubmitText={strings.saveReseller.toUpperCase()}
        submitDisabled={props.selectedRow === undefined}
      />
    </React.Fragment>
  </Modal>
)

const mapStateToProps = state => ({
  selectedRow: state.reseller.selectedRow,
})

export default connect(mapStateToProps)(ResellerModalWeb)
