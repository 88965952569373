/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import axios from 'axios'
import getToken, { baseUrl } from '../../appconfig'
import {
  FETCHING_USER,
  FETCHING_USER_SUCCESS,
  FETCHING_USER_UNVERIFIED,
  FETCHING_USER_FAILURE,
  SAVE_CREDENTIALS,
  RESET_CREDENTIALS,
} from '../types/UserTypes'

export const fetchUser = () => dispatch => {
  dispatch(getUser())
  getToken().then(token => {
    const authHeader = `Bearer ${token}`

    axios
      .get(`${baseUrl}/api/Owner`, {
        headers: { Authorization: authHeader },
      })
      .then(response => {
        dispatch(getUserSuccess(response.data))
      })
      .catch(error => {
        console.log(`UserActions.js, Error: ${error.response?.status}`)
        switch (error.response?.status) {
          case 403:
            dispatch(getUserUnverified())
            break
          default:
            dispatch(getUserFailure(error.response))
            break
        }
      })
  })
}

export const saveCredentials = (credentials: {}) => dispatch => {
  dispatch(getSaveCredentials(credentials))
}

export const resetCredentials = () => dispatch => {
  dispatch(getResetCredentials())
}

function getUser() {
  return {
    type: FETCHING_USER,
  }
}

function getUserSuccess(data) {
  return {
    type: FETCHING_USER_SUCCESS,
    data,
  }
}

function getUserUnverified() {
  return {
    type: FETCHING_USER_UNVERIFIED,
  }
}

function getUserFailure(error) {
  return {
    type: FETCHING_USER_FAILURE,
    payload: error,
  }
}

export function getSaveCredentials(credentials) {
  return {
    type: SAVE_CREDENTIALS,
    payload: credentials,
  }
}

export function getResetCredentials() {
  return {
    type: RESET_CREDENTIALS,
  }
}
