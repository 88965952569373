import React from 'react'
import Loader from '../loader/Loader.web'

import './StandardButton.styles.web.scss'

type StandardButtonPropsType = {
  onClick: () => void,
  isLoading: boolean,
  disabled: boolean,
  text: string,
  secondary?: boolean,
}

export default function StandardButton(props: StandardButtonPropsType) {
  const classes = props.secondary ? 'kabe-button cancel' : 'kabe-button'

  return (
    <button
      className={classes}
      type="button"
      onClick={props.onClick}
      disabled={props.isLoading || props.disabled}
      style={{ opacity: props.disabled ? 0.3 : 1, cursor: props.disabled ? 'auto' : 'pointer' }}
    >
      {props.isLoading ? <Loader /> : props.text}
    </button>
  )
}

StandardButton.defaultProps = { secondary: false }
