/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @Flow
 */
import React, { Component } from 'react'
import { Platform } from 'react-native'
import { connect } from 'react-redux'
import { connectVehicle, resetCodeSent } from '../../../../redux/actions/VehicleActions'
import strings from '../../../../res/strings'
import Alert from '../../../../ui/alert/Alert'
import LinkVehicle from './LinkVehicle'
import { routes } from '../../../../routes'

type VerifyVehiclePropsType = {
  connectVehicle: () => void,
  resetCodeSent: () => void,
  codeSent: boolean,
  error: {
    status: number,
    headers: {
      date: Date,
    },
  },
  isLoading: boolean,
  navigation: {
    navigate: () => void,
    goBack: () => void,
    setParams: () => void,
  },
  history: {
    push: () => void,
    goBack: () => void,
  },
}

class LinkVehicleContainer extends Component<VerifyVehiclePropsType> {
  constructor(props) {
    super(props)
    this.state = {
      chassiNumber: '',
    }
  }

  componentDidMount() {
    if (Platform.OS !== 'web') {
      this.props.navigation.setParams({ goBack: () => this.goBack() })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.codeSent === true) {
      this.navigate()
    }

    this.handleErrors(nextProps)
  }

  setChassiNumber(chassiNumber: string) {
    this.setState({ chassiNumber })
  }

  navigate() {
    this.props.resetCodeSent()
    if (Platform.OS === 'web') {
      this.props.history.push(routes.verifyvehicle)
    } else {
      this.props.navigation.navigate(routes.verifyVehicle)
    }
  }

  goBack() {
    this.props.resetCodeSent()
    if (Platform.OS === 'web') {
      this.props.history.goBack()
    } else {
      this.props.navigation.goBack()
    }
  }

  handleErrors(nextProps) {
    if (nextProps.error && this.props.error) {
      if (nextProps.error.headers.date === this.props.error.headers.date) {
        return
      }
    }
    if (nextProps.error) {
      switch (nextProps.error.status) {
        case 409:
          Alert.alert(`Error: ${nextProps.error.status}`, strings.errorAlreadyLinked)
          break
        case 404:
          Alert.alert(`Error: ${nextProps.error.status}`, strings.errorNoVehicleFound)
          break
        case 500:
          Alert.alert(`Error: ${nextProps.error.status}`, strings.errorCouldNotSendCode)
          break
        default:
          Alert.alert(`${nextProps.error.status}`, undefined)
          break
      }
    }
  }

  connectVehicle() {
    this.props.connectVehicle(this.state.chassiNumber)
  }

  render() {
    return (
      <LinkVehicle
        goBack={() => this.goBack()}
        setChassiNumber={chassiNumber => this.setChassiNumber(chassiNumber)}
        connectVehicle={() => this.connectVehicle()}
        chassiNumber={this.state.chassiNumber}
        isLoading={this.props.isLoading}
      />
    )
  }
}

const mapStateToProps = state => ({
  codeSent: state.vehicle.codeSent,
  isLoading: state.vehicle.connectIsLoading,
  error: state.vehicle.connectError,
})

export default connect(
  mapStateToProps,
  { connectVehicle, resetCodeSent }
)(LinkVehicleContainer)
