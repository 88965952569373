/**
 * @Flow
 */
import React, { Component } from 'react'
import { Platform } from 'react-native'
import { connect } from 'react-redux'
import About from './About'
import {
  fetchVehicle,
  updateVehicle,
  removeVehicle,
} from '../../../../redux/actions/VehicleActions'
import { fetchUser } from '../../../../redux/actions/UserActions'
import { VehicleType } from '../../../../types/VehicleType'
import strings from '../../../../res/strings'
import Alert from '../../../../ui/alert'
import { routes } from '../../../../routes'

type AboutVehiclePropTypes = {
  vehicle: VehicleType,
  isLoading: boolean,
  error: {
    status: number,
    headers: {
      date: Date,
    },
  },
  user: any,
  fetchVehicle: () => void,
  updateVehicle: () => void,
  fetchUser: () => void,
  removeVehicle: () => void,
  navigation: {
    navigate: () => void,
    addListener: () => void,
  },
  history: any,
  communicateByEmailIsLoading: boolean,
  receiveMessagesIsLoading: boolean,
  communicateBySMSIsLoading: boolean,
  removeIsLoading: boolean,
}

let didFocusSubscription

class AboutVehicleContainer extends Component<AboutVehiclePropTypes> {
  componentWillMount() {
    const verified = this.props.user !== undefined
    if (verified) {
      this.subscribeAndFetch()
    }
  }

  componentWillReceiveProps(nextProps) {
    this.handleErrors(nextProps)
    if (nextProps.user === undefined) {
      this.navigate()
    }
    if (!this.props.user && nextProps.user && !didFocusSubscription) {
      this.subscribeAndFetch()
    }
  }

  componentWillUnmount() {
    if (Platform.OS !== 'web' && didFocusSubscription !== undefined) {
      didFocusSubscription.remove()
    }
  }

  subscribeAndFetch() {
    if (Platform.OS !== 'web') {
      didFocusSubscription = this.props.navigation.addListener('didFocus', payload => {
        this.props.fetchUser()
        this.props.fetchVehicle()
      })
    } else {
      this.props.fetchUser()
      this.props.fetchVehicle()
    }
  }

  static navigationOptions = {
    title: strings.about,
  }

  navigate() {
    if (Platform.OS === 'web') {
      if (this.props.history) {
        this.props.history.push(routes.vehicle)
      }
    } else {
      this.props.navigation.navigate(routes.linkVehicleNavigator)
    }
  }

  handleErrors(nextProps) {
    if (nextProps.error && this.props.error) {
      if (nextProps.error.headers.date === this.props.error.headers.date) {
        return
      }
    }
    if (nextProps.error) {
      switch (nextProps.error.status) {
        case 400:
        case 401:
          Alert.alert(`Error: ${nextProps.error.status}`, strings.errorNotVerified)
          break
        case 404:
        case 500:
          Alert.alert(`Error: ${nextProps.error.status}`, strings.errorCouldNotFetchVehicle)
          break
        default:
          Alert.alert(`${nextProps.error.status}`, undefined)
          break
      }
    }
  }

  handleCheckBoxChange(name, value) {
    const newVehicle = {
      ...this.props.vehicle,
      [name]: value,
    }

    this.props.updateVehicle(newVehicle, name)
  }

  handleInputChange(event) {
    const { target } = event

    const value = target.type === 'checkbox' ? target.checked : target.value
    const { name } = target

    const newVehicle = {
      ...this.props.vehicle,
      [name]: value,
    }

    this.props.updateVehicle(newVehicle)
  }

  removeVehicle() {
    const { chassiNumber } = this.props.vehicle
    this.props.removeVehicle(chassiNumber)
    this.navigate()
  }

  render() {
    return (
      <About
        vehicle={this.props.vehicle}
        isLoading={this.props.isLoading}
        communicateByEmailIsLoading={this.props.communicateByEmailIsLoading}
        receiveMessagesIsLoading={this.props.receiveMessagesIsLoading}
        communicateBySMSIsLoading={this.props.communicateBySMSIsLoading}
        handleInputChange={e => this.handleInputChange(e)}
        handleCheckBoxChange={(name, value) => this.handleCheckBoxChange(name, value)}
        removeVehicle={() => this.removeVehicle()}
        removeIsLoading={this.props.removeIsLoading}
      />
    )
  }
}

const mapStateToProps = state => ({
  vehicle: state.vehicle.vehicle,
  isLoading: state.vehicle.isLoading,
  communicateByEmailIsLoading: state.vehicle.communicateByEmailIsLoading,
  receiveMessagesIsLoading: state.vehicle.receiveMessagesIsLoading,
  communicateBySMSIsLoading: state.vehicle.communicateBySMSIsLoading,
  error: state.vehicle.error,
  user: state.user.user,
  removeIsLoading: state.vehicle.removeIsLoading,
})

export default connect(
  mapStateToProps,
  { fetchVehicle, updateVehicle, fetchUser, removeVehicle }
)(AboutVehicleContainer)
