/* eslint-disable no-unused-expressions */

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import authentication from 'react-azure-adb2c'
import { runWithAdal } from 'react-adal'
import authContext from './adalConfig'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { CLIENT_ID, ErrorBoundary } from './appconfig/appconfig'
import './index.styles.web.scss'

authentication.initialize({
  instance: 'https://mykabe.b2clogin.com/',
  tenant: 'mykabe.onmicrosoft.com',
  signInPolicy: 'B2C_1_signup',
  resetPolicy: 'B2C_1_password_reset',
  applicationId: CLIENT_ID,
  cacheLocation: 'sessionStorage',
  scopes: ['https://mykabe.onmicrosoft.com/api/user_impersonation'],
  redirectUri: window.location.origin,
  postLogoutRedirectUri: window.location.origin,
  validateAuthority: false,
})

if (window.location.pathname.startsWith('/admin')) {
  runWithAdal(
    authContext,
    () =>
      ReactDOM.render(<App />, document.getElementById('root')) || document.createElement('div'),
    false
  )
} else {
  authentication.run(() => {
    ReactDOM.render(<App />, document.getElementById('root')) || document.createElement('div')
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
