/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @Flow
 */
import React, { Component } from 'react'
import { Platform } from 'react-native'
import { connect } from 'react-redux'
import {
  verifyVehicle,
  resetCodeSent,
  fetchVehicle,
} from '../../../../redux/actions/VehicleActions'
import { fetchUser } from '../../../../redux/actions/UserActions'
import strings from '../../../../res/strings'
import { routes } from '../../../../routes'
import Alert from '../../../../ui/alert/Alert'
import VerifyVehicle from './VerifyVehicle'

type VerifyVehiclePropsType = {
  verifyVehicle: () => void,
  fetchUser: () => void,
  isLoading: () => void,
  resetCodeSent: () => void,
  fetchVehicle: () => void,
  history: {
    push: () => void,
  },
  navigation: {
    navigate: () => void,
    push: () => void,
    goBack: () => void,
    setParams: () => void,
  },
  verified: boolean,
  user: {},
  chassiNumber: string,
  email: string,
  error: {
    headers: {
      date: Date,
    },
  },
}

class VerifyVehicleContainer extends Component<VerifyVehiclePropsType> {
  constructor(props) {
    super(props)
    this.state = {
      verificationCode: '',
    }
  }

  componentDidMount() {
    if (Platform.OS !== 'web') {
      this.props.navigation.setParams({ goBack: () => this.goBack() })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== undefined) {
      this.props.fetchVehicle()
      this.navigate()
      return
    }

    if (nextProps.verified === true) {
      this.props.fetchUser()
    }

    this.handleErrors(nextProps)
  }

  setVerificationCode(verificationCode: string) {
    this.setState({ verificationCode })
  }

  goBack() {
    this.props.resetCodeSent()
    if (Platform.OS === 'web') {
      this.props.history.push(routes.vehicle)
    } else {
      this.props.navigation.navigate(routes.noVehicle)
    }
  }

  navigate() {
    if (Platform.OS === 'web') {
      this.props.history.push(routes.vehicle)
    } else {
      this.props.navigation.navigate(routes.vehicleTabNavigator)
    }
  }

  handleErrors(nextProps) {
    if (nextProps.error && this.props.error) {
      if (nextProps.error.headers.date === this.props.error.headers.date) {
        return
      }
    }
    if (nextProps.error) {
      switch (nextProps.error.status) {
        case 400:
        case 404:
          Alert.alert(`Error: ${nextProps.error.status}`, strings.errorCouldNotVerifyCheckCode)
          break
        case 500:
          Alert.alert(`Error: ${nextProps.error.status}`, strings.errorCouldNotVerifyVehicle)
          break
        default:
          Alert.alert(`${nextProps.error.status}`, undefined)
          break
      }
    }
  }

  verifyVehicle() {
    this.props.verifyVehicle(this.props.chassiNumber, this.state.verificationCode)
  }

  render() {
    return (
      <VerifyVehicle
        goBack={() => this.goBack()}
        verifyVehicle={() => this.verifyVehicle()}
        setVerificationCode={verificationCode => this.setVerificationCode(verificationCode)}
        email={this.props.email}
        verificationCode={this.state.verificationCode}
        isLoading={this.props.isLoading}
      />
    )
  }
}

const mapStateToProps = state => ({
  verified: state.vehicle.verified,
  chassiNumber: state.vehicle.chassiNumber,
  user: state.user.user,
  error: state.vehicle.verifyError,
  email: state.vehicle.email,
  isLoading: state.vehicle.verifyIsLoading,
})

export default connect(
  mapStateToProps,
  { verifyVehicle, fetchUser, resetCodeSent, fetchVehicle }
)(VerifyVehicleContainer)
