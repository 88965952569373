import React, { Component } from 'react'
import SearchIcon from '../../res/images/ic_search.svg'
import './Search.styles.web.scss'

type SearchPropsType = {
  onChange: (text: string) => void,
}

export default class Search extends Component<SearchPropsType> {
  onChange = event => {
    this.props.onChange(event.target.value)
  }

  render() {
    return (
      <div className="search-container">
        <input type="text" placeholder="Sök" onChange={this.onChange} className="search-input" />
        <img src={SearchIcon} alt="search-icon" className="search-icon" />
      </div>
    )
  }
}
