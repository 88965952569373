import { AuthenticationContext } from 'react-adal'
import { CLIENT_ID_ADMIN } from './appconfig/appconfig'

const adalConfig = {
  tenant: 'kabeab.onmicrosoft.com',
  clientId: CLIENT_ID_ADMIN,
  redirectUri: `${window.location.origin}/admin/dashboard/messages`,
  endpoints: {},
  postLogoutRedirectUri: `${window.location.origin}/admin/dashboard/messages`,
  cacheLocation: 'sessionStorage',
}

const authContext = new AuthenticationContext(adalConfig)

export default authContext
