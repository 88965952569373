import React, { Component } from 'react'
import Checkbox from '../../../ui/checkbox/Checkbox.web'
import { MessageCategory } from '../../utilities/MessageCategory.ts'

type IsDirectMessagePropsType = {
  isDirectMessage: boolean,
  onChange: () => void,
  category: String,
}

export default class IsDirectMessage extends Component<IsDirectMessagePropsType> {
  shouldShowIsDirectCheckbox = () => {
    return (
      this.props.category === MessageCategory.Message ||
      this.props.category === MessageCategory.News
    )
  }

  render() {
    if (this.shouldShowIsDirectCheckbox()) {
      return (
        <label className="checkbox-label">
          <Checkbox checked={this.props.isDirectMessage} onChange={this.props.onChange} />
          <p>Skicka direkt</p>
        </label>
      )
    }

    return null
  }
}
