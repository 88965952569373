/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react'
import Loader from '../loader/Loader.web'
import CheckIcon from '../../res/images/ic_check.svg'
import LinkIcon from '../../res/images/ic_link.svg'
import PhoneIcon from '../../res/images/ic_phone.svg'
import colors from '../../res/style/colors'
import CaravanServiceIcon from '../../res/images/ic_caravan_service.png'
import MotorhomeServiceIcon from '../../res/images/ic_motorhome_service.png'
import WorkshopIcon from '../../res/images/ic_workshop.png'
import './Table.styles.web.scss'
import { TableType } from './TableType.ts'
import { ResellerRow } from './ResellerRow.ts'

export default class Table extends Component<TableType> {
  static getColumnValue(colVal, colIndex) {
    if (typeof colVal === 'boolean') {
      if (colVal === true) {
        if (colIndex === ResellerRow.CaravanService) {
          return (
            <div className="service-icon-container">
              <img className="service-icon-img" src={CaravanServiceIcon} alt="Husvagnsservice" />
            </div>
          )
        }

        if (colIndex === ResellerRow.MotorhomeService) {
          return (
            <div className="service-icon-container">
              <img className="service-icon-img" src={MotorhomeServiceIcon} alt="Husbilsservice" />
            </div>
          )
        }

        if (colIndex === ResellerRow.Workshop) {
          return (
            <div className="service-icon-container">
              <img className="service-icon-img" src={WorkshopIcon} alt="Verkstad" />
            </div>
          )
        }

        return (
          <div className="check-icon-container">
            <img className="check-icon" src={CheckIcon} alt="check" />
          </div>
        )
      }
      return ''
    }

    if (typeof colVal === 'string' && colVal.length > 0) {
      const columnString = colVal.trimStart().trimEnd()

      // Reseller
      if (colIndex === ResellerRow.Url) {
        if (this.isUrl(columnString)) {
          return (
            <div className="link-icon-container">
              <a
                className="link-icon"
                href={columnString}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="link-icon-img" src={LinkIcon} alt="link" />
              </a>
              <a
                className="link-text"
                href={columnString}
                target="_blank"
                rel="noopener noreferrer"
              >
                {columnString}
              </a>
            </div>
          )
        }

        return ''
      }

      // Phone number
      if (colIndex === ResellerRow.Phone) {
        const phoneUrl = `tel://${columnString}`
        return (
          <div className="link-icon-container">
            <a className="link-icon" href={phoneUrl} target="_blank" rel="noopener noreferrer">
              <img className="phone-icon-img" src={PhoneIcon} alt="link" />
            </a>
            <a className="link-text" href={columnString} target="_blank" rel="noopener noreferrer">
              {columnString}
            </a>
          </div>
        )
      }
    }

    return colVal
  }

  static isUrl = value => {
    return value.includes('http://') || value.includes('https://') || value.includes('www')
  }

  head() {
    const columns = Object.values(this.props.columns).map((colName, colIndex) => {
      return <th key={colName + colIndex}>{colName}</th>
    })
    return <tr>{columns}</tr>
  }

  rows() {
    if (this.props.rows === undefined) {
      return (
        <tr>
          <td>No data</td>
        </tr>
      )
    }
    return this.props.rows.map((row, rowIndex) => {
      const values = Object.keys(this.props.columns).map((colName, colIndex) => {
        return (
          <td key={colName + colIndex} data-label={this.props.columns[colName]}>
            {Table.getColumnValue(row[colName], colIndex)}
          </td>
        )
      })
      return (
        <tr
          onClick={() => this.props.clickEnabled && this.props.handleClick(rowIndex, row)}
          key={row.name + rowIndex}
          style={{
            backgroundColor:
              this.props.clickEnabled && row.id === this.props.selectedRow?.id && colors.lightred,
          }}
        >
          {values}
        </tr>
      )
    })
  }

  render() {
    return (
      <div className={this.props.isLoading ? 'table-container --loading' : 'table-container'}>
        <table className="responsive-table">
          <thead>{this.head()}</thead>
          <tbody>{!this.props.isLoading && this.rows()}</tbody>
        </table>
        <Loader />
      </div>
    )
  }
}
