/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 * @Flow
 */

import React from 'react'
import Input from '../../../../ui/input/Input.web'
import strings from '../../../../res/strings'
import Alert from '../../../../ui/alert/Alert.web'
import Loader from '../../../../ui/loader/Loader.web'
import './Inspections.styles.web.scss'

export default (props: any) => {
  const { inspections } = props

  return (
    <div
      className={
        props.isLoading ? 'inspectionsdata__container --loading' : 'inspectionsdata__container'
      }
    >
      <Alert />
      <Loader />
      <Input
        label={strings.lastTServiceDate}
        name="lastTServiceDate"
        className="inspections__input"
        style={{ marginLeft: 'auto' }}
        value={inspections?.nextTServiceDate || ''}
        readOnly
      />
      <Input
        label={strings.nextTServiceDate}
        name="nextTServiceDate"
        className="inspections__input"
        value={inspections?.nextTServiceDateTo || ''}
        readOnly
      />
      <Input
        label={strings.lastYServiceDate}
        name="lastYServiceDate"
        className="inspections__input"
        style={{ marginLeft: 'auto' }}
        value={inspections?.nextYServiceDate || ''}
        readOnly
      />
      <Input
        label={strings.nextYServiceDate}
        name="nextYServiceDate"
        className="inspections__input"
        value={inspections?.nextYServiceDateTo || ''}
        readOnly
      />
    </div>
  )
}
