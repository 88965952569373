/* eslint-disable no-console */

import axios from 'axios'
import { baseUrl, getAdminToken } from '../../appconfig'
import TimeUnit from '../../admin/utilities/TimeUnit.web'
import {
  INIT_MESSAGE,
  INIT_MESSAGE_SUCCESS,
  INIT_MESSAGE_FAILURE,
  CREATE_MESSAGE,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_FAILURE,
  GET_MESSAGE,
  GET_MESSAGE_SUCCESS,
  GET_MESSAGE_FAILURE,
  GET_MESSAGES,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILURE,
  UPDATE_MESSAGE,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_FAILURE,
  DELETE_MESSAGE,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAILURE,
  GET_JOURNEY,
  GET_JOURNEY_SUCCESS,
  GET_JOURNEY_FAILURE,
  UPDATE_JOURNEY,
  UPDATE_JOURNEY_SUCCESS,
  UPDATE_JOURNEY_FAILURE,
  DELETE_JOURNEY_MESSAGE,
  DELETE_JOURNEY_MESSAGE_SUCCESS,
  DELETE_JOURNEY_MESSAGE_FAILURE,
  OPEN_DIALOG,
  OPEN_DETAIL_DIALOG,
  CLOSE_DIALOG,
  CLOSE_DETAIL_DIALOG,
  VALIDATE_MESSAGE_SUCCESS,
  VALIDATE_MESSAGE_FAILURE,
} from '../types/MessageTypes'

export const initMessage = () => dispatch => {
  dispatch(getInitMessage())

  getAdminToken().then(token => {
    const authHeader = `Bearer ${token}`

    axios
      .get(`${baseUrl}/api/message/init`, {
        headers: { Authorization: authHeader },
      })
      .then(response => {
        dispatch(getInitMessageSuccess(response.data))
      })
      .catch(error => {
        dispatch(getInitMessageFailure(error.response))
        console.log(`MessageActions.js, Error: ${error.response?.status}`)
      })
  })
}

export const getMessage = id => dispatch => {
  dispatch(getExistingMessage())

  getAdminToken().then(token => {
    const authHeader = `Bearer ${token}`

    axios
      .get(`${baseUrl}/api/message/${id}`, {
        headers: { Authorization: authHeader },
      })
      .then(response => {
        dispatch(getExistingMessageSuccess(response.data))
      })
      .catch(error => {
        dispatch(getExistingMessageFailure(error.response))
        console.log(`MessageActions.js, Error: ${error.response?.status}`)
      })
  })
}

export const createMessage = state => dispatch => {
  dispatch(getCreateMessage())

  getAdminToken().then(token => {
    const authHeader = `Bearer ${token}`

    const form = new FormData()
    form.append('title', state.title)
    form.append('description', state.description)
    form.append('categoryId', state.categoryId)
    form.append('campaignId', state.campaignId)
    form.append('text', state.text)
    form.append('value', state.value)
    form.append('yearId', state.yearId)
    form.append('timeUnitId', state.timeUnitId)
    form.append('eventId', state.eventId)
    form.append('sendByEmail', state.sendByEmail)
    form.append('sendByApp', state.sendByApp)
    form.append('sendBySMS', state.sendBySMS)
    form.append('countryId', state.countryId ?? '')
    form.append('resellerId', state.resellerId ?? '')
    form.append('typeId', state.typeId)
    form.append('regDateFrom', state.regDateFrom)
    form.append('regDateTo', state.regDateTo)
    form.append('hours', state.hours)
    form.append('minutes', state.minutes)
    form.append('newsText', state.newsText)
    form.append('linkUrl', state.linkUrl)
    form.append('linkText', state.linkText)
    form.append('isTest', state.isTestMessage)
    form.append('testRecipients', state.testRecipients)
    form.append('isDirectMessage', state.isDirectMessage)
    form.append('brandId', state.brandId)
    form.append('modelId', state.modelId)
    form.append('year', state.year)

    state.files.forEach(file => form.append('files', file))

    axios
      .post(`${baseUrl}/api/message`, form, {
        headers: { Authorization: authHeader, 'content-type': 'multipart/form-data' },
      })
      .then(response => {
        dispatch(getCreateMessageSuccess(response.data))
      })
      .catch(error => {
        dispatch(getCreateMessageFailure(error.response))
        console.log(`MessageActions.js, Error: ${error.response?.status}`)
      })
  })
}

export const getMessages = () => dispatch => {
  dispatch(getAllMessages())

  getAdminToken().then(token => {
    const authHeader = `Bearer ${token}`

    axios
      .get(`${baseUrl}/api/message`, {
        headers: { Authorization: authHeader },
      })
      .then(response => {
        dispatch(getAllMessagesSuccess(response.data))
      })
      .catch(error => {
        dispatch(getAllMessagesFailure(error.response))
        console.log(`MessageActions.js, Error: ${error.response?.status}`)
      })
  })
}

export const updateMessage = state => dispatch => {
  dispatch(getUpdateMessage())

  getAdminToken().then(token => {
    const authHeader = `Bearer ${token}`

    const form = new FormData()
    form.append('id', state.id)
    form.append('title', state.title)
    form.append('description', state.description)
    form.append('categoryId', state.categoryId ?? '')
    form.append('campaignId', state.campaignId ?? '')
    form.append('text', state.text)
    form.append('value', state.value)
    form.append('yearId', state.yearId)
    form.append('timeUnitId', state.timeUnitId)
    form.append('eventId', state.eventId)
    form.append('sendByEmail', state.sendByEmail)
    form.append('sendByApp', state.sendByApp)
    form.append('sendBySMS', state.sendBySMS)
    form.append('countryId', state.countryId ?? '')
    form.append('resellerId', state.resellerId ?? '')
    form.append('typeId', state.typeId)
    form.append('regDateFrom', state.regDateFrom)
    form.append('regDateTo', state.regDateTo)
    form.append('hours', state.hours)
    form.append('minutes', state.minutes)
    form.append('newsText', state.newsText)
    form.append('linkUrl', state.linkUrl)
    form.append('linkText', state.linkText)
    form.append('isTest', state.isTestMessage)
    form.append('testRecipients', state.testRecipients)
    form.append('isDirectMessage', state.isDirectMessage)
    form.append('brandId', state.brandId)
    form.append('modelId', state.modelId)
    form.append('year', state.year)

    state.files.forEach(file => form.append('files', file))

    axios
      .put(`${baseUrl}/api/message`, form, {
        headers: { Authorization: authHeader, 'content-type': 'multipart/form-data' },
      })
      .then(response => {
        dispatch(getUpdateMessageSuccess(response.data))
      })
      .catch(error => {
        dispatch(getUpdateMessageFailure(error.response))
        console.log(`MessageActions.js, Error: ${error.response?.status}`)
      })
  })
}

export const deleteMessage = id => dispatch => {
  dispatch(getDeleteMessage())

  getAdminToken().then(token => {
    const authHeader = `Bearer ${token}`

    axios
      .delete(`${baseUrl}/api/message/${id}`, { headers: { Authorization: authHeader } })
      .then(response => {
        dispatch(getDeleteMessageSuccess(id))
      })
      .catch(error => {
        dispatch(getDeleteMessageFailure(error.response))
        console.log(`MessageActions.js, Error: ${error.response?.status}`)
      })
  })
}

export const getJourney = () => dispatch => {
  dispatch(getGetJourney())

  getAdminToken().then(token => {
    const authHeader = `Bearer ${token}`

    axios
      .get(`${baseUrl}/api/message/journey`, { headers: { Authorization: authHeader } })
      .then(response => {
        dispatch(getGetJourneySuccess(response.data))
      })
      .catch(error => {
        dispatch(getGetJourneyFailure(error.response))
        console.log(`MessageActions.js, Error: ${error.response?.status}`)
      })
  })
}

export const updateJourney = state => dispatch => {
  dispatch(getUpdateJourney())

  getAdminToken().then(token => {
    const authHeader = `Bearer ${token}`

    const data = {
      id: state.id,
      title: state.title,
      description: state.description,
      categoryId: state.categoryId,
      campaignId: state.campaignId,
      text: state.text,
      value: state.value,
      timeUnitId: state.timeUnitId,
      eventId: state.eventId,
      sendByEmail: state.sendByEmail,
      sendByApp: state.sendByApp,
      sendBySMS: state.sendBySMS,
      countryId: state.countryId,
      resellerId: state.resellerId,
      typeId: state.typeId,
      regDateFrom: state.regDateFrom,
      regDateTo: state.regDateTo,
      brandId: state.brandId,
      modelId: state.modelId,
      year: state.year,
      newsText: state.newsText,
      linkUrl: state.linkUrl,
      linkText: state.linkText,
      isDirectMessage: state.isDirectMessage,
      isTest: state.isTestMessage,
      testRecipients: state.testRecipients,
    }

    axios
      .put(`${baseUrl}/api/message/journey`, data, { headers: { Authorization: authHeader } })
      .then(response => {
        dispatch(getUpdateJourneySuccess(response.data, data.id))
      })
      .catch(error => {
        dispatch(getUpdateJourneyFailure(error.response))
        console.log(`MessageActions.js, Error: ${error.response?.status}`)
      })
  })
}

export const deactivateJourneyMessage = id => dispatch => {
  dispatch(getDeleteJourneyMessage())

  getAdminToken().then(token => {
    const authHeader = `Bearer ${token}`

    axios
      .delete(`${baseUrl}/api/message/journey/${id}`, { headers: { Authorization: authHeader } })
      .then(response => {
        dispatch(getDeleteJourneyMessageSuccess(response.data))
      })
      .catch(error => {
        dispatch(getDeleteJourneyMessageFailure(error.response))
        console.log(`MessageActions.js, Error: ${error.response?.status}`)
      })
  })
}

export const validateMessage = (state, completion) => dispatch => {
  let errors = {}
  if (state.step === 0) {
    errors = validateMessageStep1(state)
  } else {
    errors = validateMessageStep2(state)
  }

  const result = Object.entries(errors).filter(entry => entry[1].length > 0)

  if (result.length === 0) {
    dispatch(validateMessageSuccess(errors))
    completion()
    return
  }

  dispatch(validateMessageFailure(errors))
}

function validateMessageStep1(state) {
  const errors = {
    titleError: '',
    dateError: '',
  }

  if (state.title.length === 0) {
    errors.titleError = '* Titel saknas.'
  }

  if (!state.isDirectMessage) {
    errors.dateError = validateDateAndTime(state)
  }

  return errors
}

function validateDateAndTime(state) {
  const now = new Date()

  if (state.value.length === 0 || state.hours.length === 0 || state.minutes.length === 0) {
    return '* Värde(n) saknas.'
  }

  if (!isValidHours(state.hours)) {
    return 'Timmar har felaktigt format (hh)'
  }

  if (!isValidMinutes(state.minutes)) {
    return 'Minuter har felaktigt format (mm)'
  }

  if (TimeUnit.isMonth(state.timeUnitId)) {
    if (!isValidDays(state.value)) {
      return 'Felaktig dag'
    }

    const selectedDate = new Date(
      state.yearId,
      TimeUnit.toMonth(state.timeUnitId),
      state.value,
      state.hours,
      state.minutes
    )

    if (selectedDate < now) {
      return '* Datumet har passerat.'
    }
  }

  return ''
}

function validateMessageStep2(state) {
  const errors = {
    regDateFromError: '',
    regDateToError: '',
    testRecipientsError: '',
  }

  const regDateFrom = state.regDateFrom ?? ''
  const regDateTo = state.regDateTo ?? ''

  if (state.isTestMessage) {
    if (!isValidTestRecipients(state.testRecipients)) {
      errors.testRecipientsError = '* Felaktiga testmottagare'
      return errors
    }
  }

  const dateFromIsValid = isValidYearMonthDay(regDateFrom)
  if (regDateFrom.length > 0 && !dateFromIsValid) {
    errors.regDateFromError = '* Felaktigt format (yyyy-mm-dd)'
  }

  const dateToIsValid = isValidYearMonthDay(regDateTo)
  if (regDateTo.length > 0 && !dateToIsValid) {
    errors.regDateToError = '* Felaktigt format (yyyy-mm-dd)'
  }

  if (dateFromIsValid && dateToIsValid) {
    const from = new Date(regDateFrom)
    const to = new Date(regDateTo)

    if (from > to) {
      errors.regDateFromError = '* Felaktigt intervall'
    }
  }
  return errors
}

const isValidTestRecipients = recipients => {
  const regexp = new RegExp(
    '^([\\w+-.%]+@[\\w-.]+\\.[A-Za-z]+)(, ?[\\w+-.%]+@[\\w-.]+\\.[A-Za-z]+)*$'
  )
  return regexp.test(recipients)
}

const isValidHours = hours => {
  const regexp = new RegExp('^[0-2][0-9]')

  return regexp.test(hours) && hours < 24
}

const isValidMinutes = minutes => {
  const regexp = new RegExp('^[0-5][0-9]')

  return regexp.test(minutes) && minutes < 60
}

const isValidDays = days => {
  return days > 0 && days <= 31
}

const isValidYearMonthDay = date => {
  const regexp = new RegExp('([12]\\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))')

  return regexp.test(date)
}

function validateMessageSuccess(errors) {
  return {
    type: VALIDATE_MESSAGE_SUCCESS,
    errors,
  }
}

function validateMessageFailure(errors) {
  return {
    type: VALIDATE_MESSAGE_FAILURE,
    errors,
  }
}

export const openDialog = () => dispatch => {
  dispatch(getOpenDialog())
}

export const closeDialog = () => dispatch => {
  dispatch(getCloseDialog())
}

export const openDetailDialog = () => dispatch => {
  dispatch(getOpenDetailDialog())
}

export const closeDetailDialog = () => dispatch => {
  dispatch(getCloseDetailDialog())
}

function getInitMessage() {
  return {
    type: INIT_MESSAGE,
  }
}

function getInitMessageSuccess(data) {
  return {
    type: INIT_MESSAGE_SUCCESS,
    data,
  }
}

function getInitMessageFailure(error) {
  return {
    type: INIT_MESSAGE_FAILURE,
    payload: error,
  }
}

function getExistingMessage() {
  return {
    type: GET_MESSAGE,
  }
}

function getExistingMessageSuccess(data) {
  return {
    type: GET_MESSAGE_SUCCESS,
    data,
  }
}

function getExistingMessageFailure(error) {
  return {
    type: GET_MESSAGE_FAILURE,
    payload: error,
  }
}

function getCreateMessage() {
  return {
    type: CREATE_MESSAGE,
  }
}

function getCreateMessageSuccess(data) {
  return {
    type: CREATE_MESSAGE_SUCCESS,
    data,
  }
}

function getCreateMessageFailure(error) {
  return {
    type: CREATE_MESSAGE_FAILURE,
    payload: error,
  }
}

function getAllMessages() {
  return {
    type: GET_MESSAGES,
  }
}

function getAllMessagesSuccess(data) {
  return {
    type: GET_MESSAGES_SUCCESS,
    data,
  }
}

function getAllMessagesFailure(error) {
  return {
    type: GET_MESSAGES_FAILURE,
    payload: error,
  }
}

function getUpdateMessage() {
  return {
    type: UPDATE_MESSAGE,
  }
}

function getUpdateMessageSuccess(data) {
  return {
    type: UPDATE_MESSAGE_SUCCESS,
    data,
  }
}

function getUpdateMessageFailure(error) {
  return {
    type: UPDATE_MESSAGE_FAILURE,
    payload: error,
  }
}

function getDeleteMessage() {
  return {
    type: DELETE_MESSAGE,
  }
}

function getDeleteMessageSuccess(id) {
  return {
    type: DELETE_MESSAGE_SUCCESS,
    data: id,
  }
}

function getDeleteMessageFailure(error) {
  return {
    type: DELETE_MESSAGE_FAILURE,
    payload: error,
  }
}

function getGetJourney() {
  return {
    type: GET_JOURNEY,
  }
}

function getGetJourneySuccess(data) {
  return {
    type: GET_JOURNEY_SUCCESS,
    data,
  }
}

function getGetJourneyFailure(error) {
  return {
    type: GET_JOURNEY_FAILURE,
    payload: error,
  }
}

function getUpdateJourney() {
  return {
    type: UPDATE_JOURNEY,
  }
}

function getUpdateJourneySuccess(dto, id) {
  const data = dto
  data.updateId = id

  return {
    type: UPDATE_JOURNEY_SUCCESS,
    data,
  }
}

function getUpdateJourneyFailure(error) {
  return {
    type: UPDATE_JOURNEY_FAILURE,
    payload: error,
  }
}

function getDeleteJourneyMessage() {
  return {
    type: DELETE_JOURNEY_MESSAGE,
  }
}

function getDeleteJourneyMessageSuccess(data) {
  return {
    type: DELETE_JOURNEY_MESSAGE_SUCCESS,
    data,
  }
}

function getDeleteJourneyMessageFailure(error) {
  return {
    type: DELETE_JOURNEY_MESSAGE_FAILURE,
    payload: error,
  }
}

function getOpenDialog() {
  return {
    type: OPEN_DIALOG,
  }
}

function getCloseDialog() {
  return {
    type: CLOSE_DIALOG,
  }
}

function getOpenDetailDialog() {
  return {
    type: OPEN_DETAIL_DIALOG,
  }
}

function getCloseDetailDialog() {
  return {
    type: CLOSE_DETAIL_DIALOG,
  }
}
