/* eslint-disable no-console */

import axios from 'axios'
import getToken, { baseUrl } from '../../appconfig'
import NetworkHandler from '../../utility/networkhandler/NetworkHandler'
import { VehicleType } from '../../types/VehicleType'
import store from '../store'
import {
  CONNECTING_VEHICLE,
  CONNECTING_VEHICLE_SUCCESS,
  CONNECTING_VEHICLE_FAILURE,
  VERIFYING_VEHICLE,
  VERIFYING_VEHICLE_SUCCESS,
  VERIFYING_VEHICLE_FAILURE,
  FETCHING_VEHICLE,
  FETCHING_VEHICLE_SUCCESS,
  FETCHING_VEHICLE_FAILURE,
  UPDATING_VEHICLE,
  UPDATING_VEHICLE_SUCCESS,
  UPDATING_VEHICLE_FAILURE,
  RESET_CODE_SENT,
  REMOVING_VEHICLE,
  REMOVING_VEHICLE_SUCCESS,
  REMOVING_VEHICLE_FAILURE,
  SET_VEHICLE_REMOVED,
} from '../types/VehicleTypes'

export const connectVehicle = (chassiNumber: string) => dispatch => {
  dispatch(connectingVehicle(chassiNumber))

  getToken().then(token => {
    const authHeader = `Bearer ${token}`

    axios
      .get(`${baseUrl}/api/chassi/${chassiNumber}/connect`, {
        headers: { Authorization: authHeader },
      })
      .then(response => {
        dispatch(connectingVehicleSuccess(response.data))
      })
      .catch(error => {
        console.log(`VehicleActions.js, Error: ${error.response?.status}`)
        dispatch(connectingVehicleFailure(error.response))
      })
  })
}

export const removeVehicle = (chassiNumber: string) => dispatch => {
  dispatch(getRemoveVehicle())

  getToken().then(token => {
    const authHeader = `Bearer ${token}`

    axios
      .delete(`${baseUrl}/api/Chassi/${chassiNumber}/connect`, {
        headers: { Authorization: authHeader },
      })
      .then(response => {
        const inspections = response ? response.data : store.getState().inspections.inspections
        dispatch(getRemoveVehicleSuccess(inspections))
      })
      .catch(error => {
        console.log(`VehicleActions.js, Error: ${error.response?.status}`)
        dispatch(getRemoveVehicleFailure(error.response))
      })
  })
}

export const verifyVehicle = (chassiNumber: string, verificationCode: string) => dispatch => {
  dispatch(verifyingVehicle())

  getToken().then(token => {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }

    axios
      .post(`${baseUrl}/api/chassi/${chassiNumber}/connect`, verificationCode, { headers })
      .then(response => {
        dispatch(verifyVehicleSuccess())
      })
      .catch(error => {
        console.log(`VehicleActions.js, Error: ${error.response?.status}`)
        dispatch(verifyVehicleFailure(error.response))
      })
  })
}

export const fetchVehicle = () => dispatch => {
  dispatch(getVehicle())

  NetworkHandler.get('/api/Vehicle')
    .then(response => {
      const vehicle = response ? response.data : store.getState().vehicle.vehicle
      dispatch(getVehicleSuccess(vehicle))
    })
    .catch(error => {
      console.log(`VehicleActions.js, Error: ${error.response?.status}`)
      dispatch(getVehicleFailure(error.response))
    })
}

export const updateVehicle = (vehicle: VehicleType, updatedParameter?: string) => dispatch => {
  dispatch(getUpdateVehicle(updatedParameter))

  getToken().then(token => {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }

    axios
      .put(`${baseUrl}/api/Vehicle`, vehicle, { headers })
      .then(response => {
        dispatch(getUpdateVehicleSuccess(response.data, updatedParameter))
      })
      .catch(error => {
        console.log(`VehicleActions.js, Error: ${error.response?.status}`)
        dispatch(getUpdateVehicleFailure(error.response, updatedParameter))
      })
  })
}

export const resetCodeSent = () => dispatch => {
  dispatch(getResetCodeSent())
}

export const setVehicleRemoved = vehicleRemoved => dispatch => {
  dispatch(getVehicleRemoved(vehicleRemoved))
}

function getResetCodeSent() {
  return {
    type: RESET_CODE_SENT,
  }
}

function getVehicleRemoved(vehicleRemoved) {
  return {
    type: SET_VEHICLE_REMOVED,
    payload: vehicleRemoved,
  }
}

function getVehicle() {
  return {
    type: FETCHING_VEHICLE,
  }
}

function getVehicleSuccess(data: VehicleType) {
  return {
    type: FETCHING_VEHICLE_SUCCESS,
    data,
  }
}

function getVehicleFailure(error) {
  return {
    type: FETCHING_VEHICLE_FAILURE,
    payload: error,
  }
}

function connectingVehicle(chassiNumber: string) {
  return {
    type: CONNECTING_VEHICLE,
    payload: chassiNumber,
  }
}

function connectingVehicleSuccess(data) {
  return {
    type: CONNECTING_VEHICLE_SUCCESS,
    data,
  }
}

function connectingVehicleFailure(error) {
  return {
    type: CONNECTING_VEHICLE_FAILURE,
    payload: error,
  }
}

function verifyingVehicle() {
  return {
    type: VERIFYING_VEHICLE,
  }
}

function verifyVehicleSuccess() {
  return {
    type: VERIFYING_VEHICLE_SUCCESS,
  }
}

function verifyVehicleFailure(error) {
  return {
    type: VERIFYING_VEHICLE_FAILURE,
    payload: error,
  }
}

function getUpdateVehicle(updatedParameter) {
  return {
    type: UPDATING_VEHICLE,
    updatedParameter,
  }
}

function getUpdateVehicleSuccess(data: VehicleType, updatedParameter) {
  return {
    type: UPDATING_VEHICLE_SUCCESS,
    data,
    updatedParameter,
  }
}

function getUpdateVehicleFailure(error, updatedParameter) {
  return {
    type: UPDATING_VEHICLE_FAILURE,
    payload: error,
    updatedParameter,
  }
}

function getRemoveVehicle() {
  return {
    type: REMOVING_VEHICLE,
  }
}

function getRemoveVehicleSuccess() {
  return {
    type: REMOVING_VEHICLE_SUCCESS,
  }
}

function getRemoveVehicleFailure(error) {
  return {
    type: REMOVING_VEHICLE_FAILURE,
    payload: error,
  }
}
