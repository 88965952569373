/**
 * @Flow
 */

import React from 'react'
import { View, StyleSheet, Text, Platform } from 'react-native'
import colors from '../../res/style/colors'

export default (props: any) => (
  <View style={styles.badgeIconView}>
    <Text style={styles.badge}>{props.number}</Text>
  </View>
)

const styles = StyleSheet.create({
  badgeIconView: {
    backgroundColor: colors.red,
    height: 22,
    width: 22,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  badge: {
    color: 'white',
    fontWeight: 'bold',
    flex: 1,
    textAlign: 'center',
    marginTop: Platform.OS === 'ios' ? 2 : 0,
  },
})
