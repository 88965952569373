/**
 * @Flow
 */

import React, { Component } from 'react'
import ResellerType from '../../../../types/ResellerType'
import CustomButton from '../../../../ui/custombutton/CustomButton.web'
import Loader from '../../../../ui/loader/Loader.web'
import Input from '../../../../ui/input/Input.web'
import strings from '../../../../res/strings'
import Alert from '../../../../ui/alert/Alert.web'
import ResellerModalWeb from './resellermodal/ResellerModal.web'
import './MyReseller.styles.web.scss'

export default (props: any) => {
  const { reseller, isLoading, loadModal, modalOpen } = props

  return (
    <div className={isLoading ? 'resellerdata__container --loading' : 'resellerdata__container'}>
      <Alert />
      <Loader />

      <div style={{ display: reseller ? 'block' : 'none' }}>
        <Input
          label={strings.yourReseller}
          name="yourReseller"
          className="reseller__input"
          value={reseller?.name || ''}
          iconName="ic_link"
          iconHref={reseller?.homepageUrl}
          readOnly
        />
        <Input
          label={strings.address}
          name="address"
          className="reseller__input"
          value={reseller?.address || ''}
          readOnly
        />
        <Input
          label={strings.telephoneNumber}
          name="telephoneNumber"
          className="reseller__input"
          value={reseller?.phoneNumber || ''}
          iconName="ic_phone"
          iconHref={`tel://${reseller?.phoneNumber}`}
          readOnly
        />
      </div>

      {!reseller && <p className="resellerdata__no-reseller">Du har ingen vald återförsäljare.</p>}

      {loadModal && (
        <ResellerModalWeb
          isOpen={modalOpen}
          submit={() => props.submit()}
          cancel={() => props.closeModal()}
        />
      )}

      <CustomButton
        text={reseller ? strings.changeReseller.toUpperCase() : strings.choseReseller.toUpperCase()}
        onClick={() => props.openModal()}
      />
    </div>
  )
}
