/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react'
import './Input.styles.web.scss'

export default (props: any) => (
  <div className="input__container" style={props.style}>
    <p className={props.disabled ? 'input__label' : 'input__label input__label--editable'}>
      {props.label}
    </p>
    <input
      className={props.disabled ? 'input__input' : 'input__input input__label--editable'}
      name={props.name}
      onChange={props.onChange}
      value={props.value}
      disabled={props.disabled}
      readOnly={props.readOnly}
    />
    {props.iconName && (
      <a
        className="input__link-icon"
        href={props.iconHref}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="input__link-icon-img"
          src={require(`../../res/images/${props.iconName}.svg`)}
          alt="input-icon"
        />
      </a>
    )}
  </div>
)
