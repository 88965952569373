import React, { Component } from 'react'
import { BrowserRouter, Route, Redirect, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import authentication from 'react-azure-adb2c'
import { getRole } from '../redux/actions/RoleActions'
import HeaderWeb from '../ui/header/Header.web'
import SideMenu from '../ui/sidemenu/SideMenu.web'
import Feed from '../client/features/feed/Feed.web'
import Owner from '../client/features/owner'
import Resellers from '../client/features/resellers/Resellers.web'
import Vehicle from '../client/features/vehicle/Vehicle.web'
import LinkVehicle from '../client/features/vehicle/linkvehicle'
import VerifyVehicle from '../client/features/vehicle/verifyvehicle'
import AccountSettings from '../client/features/more/morelist/accountsettings'
import Dashboard from '../admin/features/dashboard/Dashboard.web'
import Messages from '../admin/features/messages/Messages.web'
import Journey from '../admin/features/journey/Journey.web'
import Manuals from '../admin/features/manuals/Manuals.web'
import Root from '../client/features/root'
import HeaderImageContainer from '../ui/headerimagecontainer/HeaderImageContainer.web'
import NewsArticle from '../client/features/feed/news/newsarticle/NewsArticle.web'
import MessageView from '../client/features/feed/messages/messageview/MessageView.web'
import ChangePassword from '../client/features/more/morelist/accountsettings/changepassword/ChangePassword.web'
import CustomButtonWeb from '../ui/custombutton/CustomButton.web'
import strings from '../res/strings'
import './routes.styles.web.scss'

export const routes = {
  start: '/start',
  mypage: '/mypage',
  vehicle: '/vehicle',
  retailers: '/retailers',
  settings: '/settings',
  linkvehicle: '/linkvehicle',
  verifyvehicle: '/verifyvehicle',
  dashboard: '/admin/dashboard',
  messages: '/admin/dashboard/messages',
  journey: '/admin/dashboard/journey',
  manuals: '/admin/dashboard/manuals',
  newsArticle: '/newsarticle',
  messageView: '/messageview',
  changepassword: '/changepassword',
}

type RouterPropsType = {
  user: any,
  role: any,
  getRole: () => void,
}

class Router extends Component<RouterPropsType> {
  constructor(props: any) {
    super(props)
    this.contentRef = React.createRef()
  }

  componentWillMount() {
    window.addEventListener('wheel', this.handleScroll, true)
    if (window.location.pathname.startsWith('/admin')) {
      this.props.getRole()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', this.handleScroll)
  }

  handleScroll = event => {
    const scale = event.deltaY / 1.5
    this.contentRef.current.scrollTop += scale
  }

  render() {
    const HeaderImage = withRouter(routerProps => {
      const path = routerProps.location.pathname
      if (
        path.startsWith(routes.dashboard) ||
        path === routes.start ||
        path.startsWith(routes.newsArticle) ||
        path.startsWith(routes.messageView)
      ) {
        return <div />
      }
      if (
        (path === routes.vehicle && !this.props.user) ||
        path === routes.linkvehicle ||
        path === routes.verifyvehicle
      ) {
        return <HeaderImageContainer image="smartphonecaravan" />
      }
      return <HeaderImageContainer image="rv" />
    })

    const BackButton = withRouter(routerProps => {
      const path = routerProps.location.pathname

      if (path.startsWith(routes.newsArticle) || path.startsWith(routes.messageView)) {
        return (
          <CustomButtonWeb
            customClass="router__button"
            text={strings.back}
            onClick={() =>
              path.startsWith(routes.messageView)
                ? routerProps.history.push(routes.start, { state: { messages: true } })
                : routerProps.history.push(routes.start)
            }
          />
        )
      }
      return null
    })

    return (
      <BrowserRouter>
        <HeaderWeb />
        <div className="router-container">
          <SideMenu role={this.props.role} />
          <div className="router__flex-container">
            <div className="router__button-container">
              <BackButton />
            </div>
            <div className="content-container" ref={this.contentRef}>
              <HeaderImage />
              <Switch>
                <Route path="/" exact component={Root} />

                <Route path="/admin" exact>
                  <Redirect to={routes.messages} />
                </Route>

                <Route path={routes.start} exact component={Feed} />

                <Route path={routes.mypage} exact component={Owner} />

                <Route path={routes.vehicle} exact component={Vehicle} />

                <Route path={routes.linkvehicle} exact component={LinkVehicle} />

                <Route path={routes.verifyvehicle} exact component={VerifyVehicle} />

                <Route path={routes.retailers} exact component={Resellers} />

                <Route path={routes.settings} exact component={AccountSettings} />

                <Route path={routes.dashboard} exact component={Dashboard} />

                <Route path={routes.messages} exact component={Messages} />

                <Route path={routes.journey} exact component={Journey} />

                <Route path={routes.manuals} exact component={Manuals} />

                <Route
                  path={routes.changepassword}
                  exact
                  render={changePasswordProps => {
                    const { state } = changePasswordProps.location
                    if (state && state.changePassword) {
                      return React.createElement(authentication.required(ChangePassword, true))
                    }
                    return <ChangePassword />
                  }}
                />

                <Route path={`${routes.newsArticle}/:id`} exact component={NewsArticle} />
                <Route path={`${routes.messageView}/:id`} exact component={MessageView} />
              </Switch>
            </div>
          </div>
        </div>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  role: state.role.role,
})

export default connect(
  mapStateToProps,
  { getRole }
)(Router)
