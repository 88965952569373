import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import {
  FETCHING_NEWS,
  FETCHING_NEWS_SUCCESS,
  FETCHING_NEWS_FAILURE,
  FETCHING_NEWS_ARTICLE,
  FETCHING_NEWS_ARTICLE_FAILURE,
  FETCHING_NEWS_ARTICLE_SUCCESS,
  RESET_NEWS_ARTICLE,
  SAVE_TOKEN,
} from '../types/NewsTypes'

const initialState = {
  news: [],
  newsArticle: {},
  isLoading: true,
  error: undefined,
  token: '',
}

const NewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_NEWS:
      return {
        ...state,
        isLoading: true,
      }

    case FETCHING_NEWS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        news: action.data,
      }

    case FETCHING_NEWS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case FETCHING_NEWS_ARTICLE:
      return {
        ...state,
        isLoading: true,
      }

    case FETCHING_NEWS_ARTICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        newsArticle: action.data,
      }

    case FETCHING_NEWS_ARTICLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case RESET_NEWS_ARTICLE:
      return {
        ...state,
        newsArticle: undefined,
      }

    case SAVE_TOKEN:
      return {
        ...state,
        token: action.payload,
      }

    default:
      return state
  }
}

const persistConfig = {
  key: 'news',
  storage,
  whitelist: ['news', 'newsArticle'],
}

export default persistReducer(persistConfig, NewsReducer)
