import {
  INIT_MESSAGE,
  INIT_MESSAGE_SUCCESS,
  INIT_MESSAGE_FAILURE,
  CREATE_MESSAGE,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_FAILURE,
  GET_MESSAGE,
  GET_MESSAGE_SUCCESS,
  GET_MESSAGE_FAILURE,
  GET_MESSAGES,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILURE,
  UPDATE_MESSAGE,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_FAILURE,
  DELETE_MESSAGE,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAILURE,
  GET_JOURNEY,
  GET_JOURNEY_SUCCESS,
  GET_JOURNEY_FAILURE,
  UPDATE_JOURNEY,
  UPDATE_JOURNEY_SUCCESS,
  UPDATE_JOURNEY_FAILURE,
  DELETE_JOURNEY_MESSAGE,
  DELETE_JOURNEY_MESSAGE_SUCCESS,
  DELETE_JOURNEY_MESSAGE_FAILURE,
  OPEN_DIALOG,
  CLOSE_DIALOG,
  OPEN_DETAIL_DIALOG,
  CLOSE_DETAIL_DIALOG,
  VALIDATE_MESSAGE_SUCCESS,
  VALIDATE_MESSAGE_FAILURE,
} from '../types/MessageTypes'

const initialState = {
  messageItem: {},
  messages: [],
  fetchMessagesLoading: false,
  fetchMessagesError: undefined,
  isLoading: false,
  error: undefined,
  campaigns: [],
  categories: [],
  timeUnits: [],
  events: [],
  countries: [],
  resellers: [],
  brands: [],
  types: [],
  journey: [],
  dialogOpen: false,
  detailDialogOpen: false,
  shouldClose: false,
  shouldDetailClose: false,
  message: undefined,
  hasBeenUpdated: false,
  hasBeenDeleted: false,
  validationErrors: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case INIT_MESSAGE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }

    case INIT_MESSAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        campaigns: action.data.campaigns,
        categories: action.data.categories,
        timeUnits: action.data.timeUnits,
        events: action.data.events,
        countries: action.data.countries,
        resellers: action.data.resellers,
        brands: action.data.brands,
        types: action.data.types,
        deliveryYears: action.data.deliveryYears,
      }

    case INIT_MESSAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case VALIDATE_MESSAGE_SUCCESS:
      return {
        ...state,
        validationErrors: action.errors,
        success: true,
      }

    case VALIDATE_MESSAGE_FAILURE:
      return {
        ...state,
        validationErrors: action.errors,
        success: false,
      }

    case CREATE_MESSAGE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }

    case CREATE_MESSAGE_SUCCESS: {
      const { messages } = state
      const message = action.data

      let index = -1
      if (message.eventId === 1) {
        let value
        if (message.categoryId === 10) {
          value = 365 + message.value * (message.timeUnitId === 1 ? 1 : 30)
          index = messages.findIndex(
            o => o.eventId === 1 && 365 + o.value * (o.timeUnitId === 1 ? 1 : 30) <= value
          )
        } else {
          value = message.categoryId * 365 + message.value * (message.timeUnitId === 1 ? 1 : 30)
          index = messages.findIndex(
            o =>
              o.eventId === 1 &&
              o.categoryId * 365 + o.value * (o.timeUnitId === 1 ? 1 : 30) <= value
          )
        }
      } else {
        index = messages.findIndex(
          o =>
            o.eventId === 100 &&
            (o.time < message.time ||
              (o.time === message.time &&
                (o.hours < message.hours ||
                  (o.hours <= message.hours && o.minutes < message.minutes))))
        )

        if (index === -1) {
          index = messages.length
        }
      }

      if (index === -1) {
        messages.splice(0, 0, message)
      } else {
        messages.splice(index, 0, message)
      }

      return {
        ...state,
        isLoading: false,
        messages,
        shouldClose: true,
      }
    }

    case CREATE_MESSAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case GET_MESSAGE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }

    case GET_MESSAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.data,
      }

    case GET_MESSAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case GET_MESSAGES:
      return {
        ...state,
        isLoading: true,
        error: null,
      }

    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        messages: action.data,
      }

    case GET_MESSAGES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case UPDATE_MESSAGE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }

    case UPDATE_MESSAGE_SUCCESS: {
      const { messages } = state
      const index = messages.findIndex(o => o.id === action.data.id)
      if (index > -1) {
        state.messages.splice(index, 1, action.data)
      }

      return {
        ...state,
        isLoading: false,
        messages,
        hasBeenUpdated: true,
      }
    }

    case UPDATE_MESSAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case DELETE_MESSAGE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }

    case DELETE_MESSAGE_SUCCESS: {
      const { messages } = state
      const index = messages.findIndex(o => o.id === action.data)
      if (index > -1) {
        state.messages.splice(index, 1)
      }

      return {
        ...state,
        isLoading: false,
        messages,
        hasBeenDeleted: true,
      }
    }

    case DELETE_MESSAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case GET_JOURNEY:
      return {
        ...state,
        isLoading: true,
        error: null,
      }

    case GET_JOURNEY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        journey: action.data,
      }

    case GET_JOURNEY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case UPDATE_JOURNEY:
      return {
        ...state,
        isLoading: true,
        error: null,
      }

    case UPDATE_JOURNEY_SUCCESS: {
      const { journey } = state

      journey.periods.forEach((o, i) => {
        o.messages.forEach((p, index) => {
          if (journey.periods[i].messages[index].id === action.data.updateId) {
            journey.periods[i].messages[index] = action.data
          }
        })
      })

      return {
        ...state,
        isLoading: false,
        journey,
        hasBeenUpdated: true,
      }
    }

    case UPDATE_JOURNEY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case DELETE_JOURNEY_MESSAGE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }

    case DELETE_JOURNEY_MESSAGE_SUCCESS: {
      const { journey } = state

      journey.periods.forEach((o, i) => {
        o.messages.forEach((p, index) => {
          if (journey.periods[i].messages[index].id === action.data.id) {
            journey.periods[i].messages[index].active = action.data.active
          }
        })
      })

      return {
        ...state,
        isLoading: false,
        journey,
      }
    }

    case DELETE_JOURNEY_MESSAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case OPEN_DIALOG:
      return {
        ...state,
        shouldClose: false,
        dialogOpen: true,
      }

    case CLOSE_DIALOG:
      return {
        ...state,
        validationErrors: {},
        shouldClose: false,
        dialogOpen: false,
      }

    case OPEN_DETAIL_DIALOG:
      return {
        ...state,
        shouldDetailClose: false,
        detailDialogOpen: true,
      }

    case CLOSE_DETAIL_DIALOG:
      return {
        ...state,
        message: null,
        shouldDetailClose: false,
        detailDialogOpen: false,
        hasBeenDeleted: false,
        hasBeenUpdated: false,
        validationErrors: {},
      }

    default:
      return state
  }
}
