import { GET_ROLE, GET_ROLE_SUCCESS, GET_ROLE_FAILURE } from '../types/RoleTypes'

const initialState = {
  role: undefined,
  error: undefined,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ROLE:
      return {
        ...state,
      }

    case GET_ROLE_SUCCESS:
      return {
        ...state,
        role: action.data,
      }

    case GET_ROLE_FAILURE:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}
