export const CONNECTING_VEHICLE = 'CONNECTING_VEHICLE'
export const CONNECTING_VEHICLE_SUCCESS = 'CONNECTING_VEHICLE_SUCCESS'
export const CONNECTING_VEHICLE_FAILURE = 'CONNECTING_VEHICLE_FAILURE'

export const VERIFYING_VEHICLE = 'VERIFYING_VEHICLE'
export const VERIFYING_VEHICLE_SUCCESS = 'VERIFYING_VEHICLE_SUCCESS'
export const VERIFYING_VEHICLE_FAILURE = 'VERIFYING_VEHICLE_FAILURE'

export const FETCHING_VEHICLE = 'FETCHING_VEHICLE'
export const FETCHING_VEHICLE_SUCCESS = 'FETCHING_VEHICLE_SUCCESS'
export const FETCHING_VEHICLE_FAILURE = 'FETCHING_VEHICLE_FAILURE'

export const UPDATING_VEHICLE = 'UPDATING_VEHICLE'
export const UPDATING_VEHICLE_SUCCESS = 'UPDATING_VEHICLE_SUCCESS'
export const UPDATING_VEHICLE_FAILURE = 'UPDATING_VEHICLE_FAILURE'

export const RESET_CODE_SENT = 'RESET_CODE_SENT'

export const REMOVING_VEHICLE = 'REMOVING_VEHICLE'
export const REMOVING_VEHICLE_SUCCESS = 'REMOVING_VEHICLE_SUCCESS'
export const REMOVING_VEHICLE_FAILURE = 'REMOVING_VEHICLE_FAILURE'

export const SET_VEHICLE_REMOVED = 'SET_VEHICLE_REMOVED'
